import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2d88def4 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _47c7832a = () => interopDefault(import('../pages/bao-tri.vue' /* webpackChunkName: "pages/bao-tri" */))
const _a2d14ebc = () => interopDefault(import('../pages/daily-event.vue' /* webpackChunkName: "pages/daily-event" */))
const _8caa4a34 = () => interopDefault(import('../pages/game.vue' /* webpackChunkName: "pages/game" */))
const _4a2b3654 = () => interopDefault(import('../pages/goal-scorer.vue' /* webpackChunkName: "pages/goal-scorer" */))
const _66878198 = () => interopDefault(import('../pages/iframe.vue' /* webpackChunkName: "pages/iframe" */))
const _2f0a3f0f = () => interopDefault(import('../pages/keno.vue' /* webpackChunkName: "pages/keno" */))
const _1676f346 = () => interopDefault(import('../pages/ksport.vue' /* webpackChunkName: "pages/ksport" */))
const _397a6655 = () => interopDefault(import('../pages/livecasino.vue' /* webpackChunkName: "pages/livecasino" */))
const _1e406677 = () => interopDefault(import('../pages/lo-de-sieu-toc.vue' /* webpackChunkName: "pages/lo-de-sieu-toc" */))
const _d3674990 = () => interopDefault(import('../pages/lode.vue' /* webpackChunkName: "pages/lode" */))
const _47ab68b6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _673da801 = () => interopDefault(import('../pages/match.vue' /* webpackChunkName: "pages/match" */))
const _283509a9 = () => interopDefault(import('../pages/quay-so.vue' /* webpackChunkName: "pages/quay-so" */))
const _39cbd0a7 = () => interopDefault(import('../pages/quay-so-sexy.vue' /* webpackChunkName: "pages/quay-so-sexy" */))
const _c76c21d2 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _4fcb29cd = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _01a760c6 = () => interopDefault(import('../pages/seagames.vue' /* webpackChunkName: "pages/seagames" */))
const _3b4ada1c = () => interopDefault(import('../pages/slot.vue' /* webpackChunkName: "pages/slot" */))
const _b051af1c = () => interopDefault(import('../pages/the-thao.vue' /* webpackChunkName: "pages/the-thao" */))
const _6e03e619 = () => interopDefault(import('../pages/the-thao-ao.vue' /* webpackChunkName: "pages/the-thao-ao" */))
const _d87d274a = () => interopDefault(import('../pages/the-thao-chau-a/index.vue' /* webpackChunkName: "pages/the-thao-chau-a/index" */))
const _3da0a34d = () => interopDefault(import('../pages/the-thao-chau-au.vue' /* webpackChunkName: "pages/the-thao-chau-au" */))
const _e689bd72 = () => interopDefault(import('../pages/the-thao-so.vue' /* webpackChunkName: "pages/the-thao-so" */))
const _be6d3ae6 = () => interopDefault(import('../pages/tin-tuc/index.vue' /* webpackChunkName: "pages/tin-tuc/index" */))
const _353fe00f = () => interopDefault(import('../pages/tro-choi.vue' /* webpackChunkName: "pages/tro-choi" */))
const _f1f3b666 = () => interopDefault(import('../pages/tro-choi-lo-de.vue' /* webpackChunkName: "pages/tro-choi-lo-de" */))
const _79ca688a = () => interopDefault(import('../pages/tro-choi-so.vue' /* webpackChunkName: "pages/tro-choi-so" */))
const _133be63f = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _6cac8182 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _5b0d7964 = () => interopDefault(import('../pages/user/deposit.vue' /* webpackChunkName: "pages/user/deposit" */))
const _1123b378 = () => interopDefault(import('../pages/user/history.vue' /* webpackChunkName: "pages/user/history" */))
const _266f4248 = () => interopDefault(import('../pages/user/information.vue' /* webpackChunkName: "pages/user/information" */))
const _61d35aec = () => interopDefault(import('../pages/user/withdraw.vue' /* webpackChunkName: "pages/user/withdraw" */))
const _515c1960 = () => interopDefault(import('../pages/worldcup.vue' /* webpackChunkName: "pages/worldcup" */))
const _114c49fd = () => interopDefault(import('../pages/the-thao-chau-a/tran-dau.vue' /* webpackChunkName: "pages/the-thao-chau-a/tran-dau" */))
const _847fbf94 = () => interopDefault(import('../pages/the-loai/_slug.vue' /* webpackChunkName: "pages/the-loai/_slug" */))
const _c1da7d76 = () => interopDefault(import('../pages/tin-tuc/_slug.vue' /* webpackChunkName: "pages/tin-tuc/_slug" */))
const _1282a88e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6be7a39b = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _2d88def4,
    name: "404"
  }, {
    path: "/bao-tri",
    component: _47c7832a,
    name: "bao-tri"
  }, {
    path: "/daily-event",
    component: _a2d14ebc,
    name: "daily-event"
  }, {
    path: "/game",
    component: _8caa4a34,
    name: "game"
  }, {
    path: "/goal-scorer",
    component: _4a2b3654,
    name: "goal-scorer"
  }, {
    path: "/iframe",
    component: _66878198,
    name: "iframe"
  }, {
    path: "/keno",
    component: _2f0a3f0f,
    name: "keno"
  }, {
    path: "/ksport",
    component: _1676f346,
    name: "ksport"
  }, {
    path: "/livecasino",
    component: _397a6655,
    name: "livecasino"
  }, {
    path: "/lo-de-sieu-toc",
    component: _1e406677,
    name: "lo-de-sieu-toc"
  }, {
    path: "/lode",
    component: _d3674990,
    name: "lode"
  }, {
    path: "/login",
    component: _47ab68b6,
    name: "login"
  }, {
    path: "/match",
    component: _673da801,
    name: "match"
  }, {
    path: "/quay-so",
    component: _283509a9,
    name: "quay-so"
  }, {
    path: "/quay-so-sexy",
    component: _39cbd0a7,
    name: "quay-so-sexy"
  }, {
    path: "/register",
    component: _c76c21d2,
    name: "register"
  }, {
    path: "/reset-password",
    component: _4fcb29cd,
    name: "reset-password"
  }, {
    path: "/seagames",
    component: _01a760c6,
    name: "seagames"
  }, {
    path: "/slot",
    component: _3b4ada1c,
    name: "slot"
  }, {
    path: "/the-thao",
    component: _b051af1c,
    name: "the-thao"
  }, {
    path: "/the-thao-ao",
    component: _6e03e619,
    name: "the-thao-ao"
  }, {
    path: "/the-thao-chau-a",
    component: _d87d274a,
    name: "the-thao-chau-a"
  }, {
    path: "/the-thao-chau-au",
    component: _3da0a34d,
    name: "the-thao-chau-au"
  }, {
    path: "/the-thao-so",
    component: _e689bd72,
    name: "the-thao-so"
  }, {
    path: "/tin-tuc",
    component: _be6d3ae6,
    name: "tin-tuc"
  }, {
    path: "/tro-choi",
    component: _353fe00f,
    name: "tro-choi"
  }, {
    path: "/tro-choi-lo-de",
    component: _f1f3b666,
    name: "tro-choi-lo-de"
  }, {
    path: "/tro-choi-so",
    component: _79ca688a,
    name: "tro-choi-so"
  }, {
    path: "/user",
    component: _133be63f,
    children: [{
      path: "",
      component: _6cac8182,
      name: "user"
    }, {
      path: "deposit",
      component: _5b0d7964,
      name: "user-deposit"
    }, {
      path: "history",
      component: _1123b378,
      name: "user-history"
    }, {
      path: "information",
      component: _266f4248,
      name: "user-information"
    }, {
      path: "withdraw",
      component: _61d35aec,
      name: "user-withdraw"
    }]
  }, {
    path: "/worldcup",
    component: _515c1960,
    name: "worldcup"
  }, {
    path: "/the-thao-chau-a/tran-dau",
    component: _114c49fd,
    name: "the-thao-chau-a-tran-dau"
  }, {
    path: "/the-loai/:slug?",
    component: _847fbf94,
    name: "the-loai-slug"
  }, {
    path: "/tin-tuc/:slug?",
    component: _c1da7d76,
    name: "tin-tuc-slug"
  }, {
    path: "/",
    component: _1282a88e,
    name: "index"
  }, {
    path: "/*",
    component: _6be7a39b,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
