<template>
  <b-modal
      id="historyPredictionModal"
      dialog-class="modal-dialog-event"
      :content-class="['modal-wheel modal-wheel--history-prediction modal--full-max-height', {'holiday-custom fireworks': $isEnableThemeHoliday()}]"
      hide-footer
      hide-header
      ok-only
      no-stacking
      lazy
      @show="beforeInit"
      @hidden="beforeHidden"
  >
    <div class="history-prediction-wrapper">
      <div class="history-prediction">
        <div class="modal-wrapper">
          <div class="modal-close" @click="$bvModal.hide('historyPredictionModal')">
            <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/mobile/icon-close.svg`" alt="Close"/>
          </div>
          <b-tabs>
            <b-tab :title="$device.isMobile ? 'LỊCH SỬ' : 'LỊCH SỬ NHẬN THƯỞNG'" active class="tab-rules">
              <div>
                <div v-if="totalReward > 0 " class="history__inner">
                  <div class="history__inner__contentbg">
                    <d-img v-if="$isEnableThemeHoliday()" class="history__inner-thumb" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/dialog/box-500-no-text.png`" alt="500 triệu"/>
                    <d-img v-else class="history__inner-thumb" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/dialog/box-500.png`" alt="500 triệu"/>
                    <div class="history__inner-content">
                      <h3 class="history__title">Bạn có: <span>{{ totalReward }}</span> hộp quà đặc biệt, mở ngay nào !</h3>
                      <p v-html="eventWheelInfo.textNoteHisory"></p>
                    </div>
                    <button class="button button-history mt-3 pointer" @click="openReward()">Mở ngay</button>
                  </div>
                </div>
                <div v-else class="history__inner">
                  <div class="history__inner__contentbg">
                    <d-img v-if="$isEnableThemeHoliday()" class="history__inner-thumb" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/dialog/box-500-no-text.png`" alt="500 triệu"/>
                    <d-img v-else class="history__inner-thumb" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/dialog/box-500.png`" alt="500 triệu"/>
                    <div class="history__inner-content">
                      <h3 class="history__title">hộp quà đặc biệt</h3>
                      <p v-html="eventWheelInfo.textNoteHisory"></p>
                    </div>
                    <p class="mt-4 "> </p>
                  </div>
                </div>
                <div class="history__table">
                  <div class="history__table-title">
                    <h3>lịch sử Nhận thưởng</h3>
                  </div>
                  <b-table
                      striped
                      hover
                      :items="winnerBetHistory"
                      :fields="fields"
                      :busy="isLoading"
                      :show-empty="winnerBetHistory.length === 0 && !isLoading"
                  >
                    <template #empty="scope">
                      <div class="empty-data">
                        <div class="empty-data__inner">
                          <d-img class="empty-data__icon" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/empty-data.svg`" alt="Empty"/>
                          <p class="empty-data__text">Chưa có dữ liệu</p>
                        </div>
                      </div>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2 loading">
                        <b-spinner class="align-middle" variant="light"></b-spinner>
                      </div>
                    </template>
                  </b-table>
                  <div class="history__pagination">
                    <pagination
                            v-if="total > showPerPage && winnerBetHistory.length !== 0"
                            :total="total"
                            :page="currentPage"
                            :totalPage="totalPages"
                            :handlerPage="loadPage"
                    ></pagination>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import dayjs from 'dayjs';
import { eventWheelInfo } from '@/resources/event-wheel';
import Pagination from '~/components/pagination.vue';
import Button from '../../../button';

export default {
    components: {
        Button,
        Pagination
    },
    data() {
        return {
            eventWheelInfo,
            fields: [
                {
                    key: 'time',
                    label: 'Thời gian',
                    formatter(value) {
                        return dayjs(value).format('DD/MM/YYYY');
                    }
                },
                {
                    key: 'note',
                    label: 'Giải thưởng'
                },
                {
                    key: 'process',
                    label: 'trạng thái',
                    formatter: value => (value ? 'Đã xác nhận' : 'Chờ duyệt')
                }
            ],
            winnerBetHistory: [],
            showPerPage: 6,
            currentPage: 1,
            total: 0,
            isLoading: true,
            numberSpecial: 0,
            totalReward: 0
        };
    },
    watch: {
        isLoading(val) {
            if (!val) {
                setTimeout(() => {
                    document.body.classList.add('modal-open');
                }, 200);
            }
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.total / this.showPerPage);
        }
    },
    methods: {
        async getWinnerBet(page = 1) {
            try {
                this.isLoading = true;
                this.currentPage = page;
                const { status, data } = await this.$eventWheelDefaultApi.d_userHistory({ limit: this.showPerPage, page });
                if (status === 'OK') {
                    this.total = data.totalItems;
                    this.winnerBetHistory = data.items.map(record => {
                        record.note = eventWheelInfo.rewardMapping[record.note].name;
                        return record;
                    });
                }
                this.isLoading = false;
            } catch (e) {
                // // todo: fake
                // const { status, data } = await new Promise(resolve => setTimeout(resolve({
                //     status: 'OK',
                //     data: {
                //         total: 10,
                //         winners: [
                //             {
                //                 time: 'xxxx',
                //                 note: 'giải thưởng xxx',
                //                 process: 'trạng thái'
                //             },
                //             {
                //                 time: 'yyyy',
                //                 note: 'giải thưởng yyy',
                //                 process: 'trạng thái'
                //             }
                //         ]
                //     }
                // }), 1000));
                // if (status === 'OK') {
                //     this.total = data.total;
                //     this.winnerBetHistory = data.winners;
                // }
                // this.isLoading = false;
                // // todo: uncomment
                // console.error(e);
                // this.isLoading = false;
                // this.winnerBetHistory = [];
            }
        },
        loadPage(page) {
            this.getWinnerBet(page);
        },
        beforeInit() {
            this.$nextTick(async () => {
                await this.countSpecial();
                await this.getWinnerBet();
            });
        },
        beforeHidden() {
            this.isLoading = true;
            this.winnerBetHistory = [];
        },
        async countSpecial() {
            try {
                const { status, data } = await this.$eventWheelDefaultApi.d_countSpecial(this.$store.state.user.username);
                if (status === 'OK') {
                    this.totalReward = data.total;
                }
            } catch (e) {
                // // todo: fake
                // this.totalReward = 10;
                console.error(e);
            }
        },
        openReward() {
            this.$bvModal.hide('historyPredictionModal');
            this.$bvModal.show('predictedTeamModal');
        }
    }
};
</script>
