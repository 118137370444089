<template>
  <b-modal id="updateDomainModal" centered hide-footer>
    <template v-slot:modal-title>
      <div class="update-domain">
        <div class="update-domain__body">
          <div class="update-domain__icon">
            <img src="/assets/img/icon/warning.svg" alt="Icon Warning" />
          </div>
          <h1 class="update-domain__title">ThÔNG BÁO ĐỔI ĐỊA CHỈ WEBSITE</h1>
          <div class="update-domain__desc">
            "Thông báo chuyển địa chỉ truy cập website  sang đường link mới
            <a :href="updateDomain.url" target="_blank" class="update-domain__desc update-domain__desc--bold">
              {{ updateDomain.url }}
            </a> Xin quý khách lưu ý"
          </div>
        </div>
      </div>
    </template>
    <div class="contact">
      <div class="contact__list">
        <div class="contact__item" v-for="(item, index) in contactList" :key="index">
          <div class="contact__left">
            <img :src="item.srcImg" alt="Phone">
          </div>
          <div class="contact__right">
            <p class="contact__name">{{ item.name }}</p>
            <p class="contact__name contact__name--white-color">{{ item.phone | fomatPhone}}</p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
    data() {
        return {
            contactList: [
                // { name: 'HOTLINE', phone: this.$t('contact.phone'), srcImg: '/assets/img/icon/icon-phone.svg' },
                // { name: 'ZALO', phone: this.$t('contact.zalo'), srcImg: '/assets/img/icon/icon-zalo.svg' },
                { name: 'VIBER', phone: this.$t('contact.viber'), srcImg: '/assets/img/icon/icon-viber.svg' }
            ]
        };
    },
    computed: {
        updateDomain() {
            const domainUpdateFromdate = process.env.DOMAIN_UPDATE_FROMDATE || '';
            const domainUpdateUrl = process.env.DOMAIN_UPDATE_URL || '';
            return {
                url: domainUpdateUrl,
                date: domainUpdateFromdate
            };
        }
    }
};
</script>
