<template>
    <b-modal
            id="DailyRulesConditionModal"
            dialog-class="modal-dialog-event daily"
            :content-class="['modal-wheel modal-wheel--rules-condition modal--full-max-height modal-wheel--daily']"
            hide-footer
            hide-header
            ok-only
            no-stacking
    >
        <div class="modal-wrapper">
            <div class="modal-close" @click="$bvModal.hide('DailyRulesConditionModal')">
                <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/mobile/icon-close.svg`" alt="Close"/>
            </div>
            <b-tabs>
                <b-tab title="THỂ LỆ CHƯƠNG TRÌNH" active class="tab-rules">
                    <div class="rules-wrapper" >
                        <div class="rules">
                            <div v-if="mutateTermList.bannerModal" class="rules__bg text-center">
                                <d-img v-if="$device.isMobile" :src="mutateTermList.bannerModalMB" alt="banner"/>
                                <d-img v-else :src="mutateTermList.bannerModal" alt="banner"/>
                            </div>
                            <div v-if="$device.isMobile" class="seagame" role="tablist">
                                <div class="tab-rule">
                                    <div v-for="(item, inxd) in mutateTermList.termList" :key="inxd" class="tab-rule__item">
                                        <div class="icon">
                                            <img :src="item.icon" loading="lazy" alt="icon"/>
                                        </div>
                                        <ul class="content">
                                            <li v-for="(i, inx) in item.content" :key="inx" v-html="i.text" />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div v-else v-for="(item, index) in mutateTermList.termList" :key="index" class="rules__inner">
                                <div class="rules__icon">
                                    <d-img :src="item.icon" alt="icon"/>
                                </div>
                                <ul class="rules__list">
                                    <li v-for="(i, inx) in item.content" :key="inx" class="rules__item" v-html="i.text">
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="modal-button">
                            <a
                                    @click="$bvModal.hide('DailyRulesConditionModal')"
                                    data-toggle="modal" href="javascript:void(0)"
                                    class="btn-login-monile button background-2 padding-2 hidden"
                            >
                                QUAY LẠI
                            </a>
                            <a
                                    @click="moveTo('/the-thao')"
                                    data-toggle="modal"
                                    href="javascript:void(0)"
                                    class="button show-desktop"
                            >
                                CƯỢC NGAY
                            </a>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
    </b-modal>
</template>
<script>
// eslint-disable-next-line import/no-unresolved

export default {
    components: {
    },
    computed: {
        mutateTermList() {
            return this.$store.state.event.daily.termList;
        }
    },
    methods: {
        moveTo(link) {
            this.$bvModal.hide('DailyRulesConditionModal');
            this.$openLink(link, {
                loginRequired: false,
                newTab: false
            });
        }
    }
};
</script>
