const apiVersion = `${process.env.EVENT_WORLD_CUP_API_PATH}/world-cup-wheel`;

export default ($axios) => ({
    roll(turn = 1) {
        return $axios.$post(`${apiVersion}/roll?t=${Math.random() * 10}`, { turn }, {
            withCredentials: true
        });
    },
    userTurn() {
        return $axios.$get(`${apiVersion}/user/turn`, {
            withCredentials: true,
            params: {
                t: Math.random() * 10
            }
        });
    },
    userHistory(payload) {
        return $axios.$get(`${apiVersion}/user/history`, {
            withCredentials: true,
            params: { ...payload, t: Math.random() * 8 }
        });
    },
    dailyWinner(limit = 10, offset = 0) {
        return $axios.$get(`${apiVersion}/history`, {
            params: {
                limit,
                offset,
                t: Math.random() * 10
            }
        });
    },
    getRewards() {
        return $axios.$get(`${apiVersion}/rewards`, {
            params: {
                t: Math.random() * 10
            }
        });
    },
    getTopMedal(limit = 10, offset = 0) {
        return $axios.$get(`${apiVersion}/top-medal`, {
            withCredentials: true,
            params: {
                limit,
                offset,
                t: Math.random() * 10
            }
        });
    }
});
