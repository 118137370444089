import { validationMixin } from 'vuelidate';
import {
    required, minLength, helpers
} from 'vuelidate/lib/validators';

const usernameValidation = helpers.regex('usernameValidation', /^[a-zA-Z0-9_]*$/);

export default {
    mixins: [validationMixin],
    data() {
        return {
            messageLoginFail: '',
            isModalVisible: false,
            formData: {
                username: '',
                password: ''
            },
            isShowPassword: false,
            isSubmit: false
        };
    },
    validations: {
        formData: {
            password: {
                minLength: minLength(6),
                required
            },
            username: {
                usernameValidation,
                required
            }
        }
    },
    methods: {
        async login() {
            try {
                this.$v.$touch();
                this.isSubmit = true;
                if (!this.$v.$invalid) {
                    const data = await this.$store.dispatch('login', { ...this.formData });
                    if (data && data.status === 'OK') {
                        this.isSubmit = false;
                        this.$bvModal.hide('loginModal');
                        this.$alert('Đăng nhập thành công !');
                        this.$v.$reset();
                        this.formData.username = '';
                        this.formData.password = '';
                        this.getWallet();
                        setTimeout(() => {
                            this.$Swal.close();
                        }, 1500);
                    } else if (data && data.status !== 'OK') {
                        this.isSubmit = false;
                        this.messageLoginFail = 'Tên đăng nhập hoặc mật khẩu không đúng';
                    } else {
                        this.isSubmit = false;
                        this.messageLoginFail = data.message;
                    }
                } else {
                    this.isSubmit = false;
                }
            } catch (e) {
                this.formError = e.message;
                this.isSubmit = false;
                this.$bvModal.hide('loginModal');
                this.$alert('Server đang bị lỗi! Vui lòng thử lại sau!');
            }
        },
        async getWallet() {
            try {
                const res = await this.$userApi.getWallet();
                if (res.status === 'OK') {
                    this.$store.dispatch('updateWallet', {
                        oldBalance: res.data.balance,
                        balance: res.data.balance,
                        oldBalanceSub: res.data.balanceSub,
                        balanceSub: res.data.balanceSub,
                        maxBalance: res.data.balanceAll
                    });
                }
            } catch (e) {
                this.formError = e.message;
            }
        },
        resetModal() {
            this.$v.$reset();
            this.formData.username = '';
            this.formData.password = '';
            this.messageLoginFail = '';
        }
    },
    mounted() {
        localStorage.setItem('showLoginModal', false);
    }
};
