/* eslint-disable import/extensions */
import heroBannerApi from './hero-banner';
import userApi from './user';
import urlApi from './url';
import depositApi from './deposit';
import withDrawApi from './withdraw';
import historyApi from './history';
import postApi from './post';
import casinoApi from './casino';
import gameApi from './game';
import hotmatchApi from './hotmatch';
import freeSpinApi from './freeSpin';
import topwinApi from './topwin';
import eventWheelDefaultApi from './event/event-wheel-default';
import seaGameApi from './event/event-seagame';
import worldCupWheelApi from './event/world-cup-wheel';
import worldCupGoalApi from './event/world-cup-goal';

export default {
    heroBannerApi,
    userApi,
    urlApi,
    depositApi,
    withDrawApi,
    historyApi,
    postApi,
    casinoApi,
    gameApi,
    hotmatchApi,
    freeSpinApi,
    topwinApi,
    eventWheelDefaultApi,
    seaGameApi,
    worldCupWheelApi,
    worldCupGoalApi
};
