<template>
    <b-modal id="mergeWalletModal" content-class="modal--merge-wallet" hide-header hide-footer ok-only no-stacking>
        <div class="modal-close" @click="$bvModal.hide('mergeWalletModal')">
            <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/mobile/icon-close.svg`" alt="Close"/>
        </div>
        <div class="merge-wallet">
            <h1 class="modal-header">THÔNG BÁO</h1>
            <div class="merge-wallet__desc">
                Từ ngày 09/08/2022 hệ thống sẽ gộp ví chính, ví phụ làm một loại ví duy nhất. Người chơi có thể dùng ví này để trải nghiệm tất cả các trò chơi trên <span>OXBET.</span><br />
                Hệ thống sẽ tự động cộng tiền 2 loại ví trên lại làm một, người chơi không cần thao tác gì thêm.
            </div>
            <div class="merge-wallet__btn">
                <a class="button" @click="$bvModal.hide('mergeWalletModal')">XÁC NHẬN</a>
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    methods: {
        checkClick() {
            const enableMergeWallet = process.env.ENABLE_SHOW_MERGE_WALLET;
            if (enableMergeWallet === '1' && !localStorage.getItem('MERGED_WALLET_CLOSE')) {
                this.$nextTick(() => {
                    this.$bvModal.show('mergeWalletModal');
                });
            }
        }
    },
    mounted() {
        this.checkClick();
        this.$root.$on('bv::modal::hidden', (bvEvent) => {
            if (bvEvent.componentId === 'mergeWalletModal') {
                localStorage.setItem('MERGED_WALLET_CLOSE', true);
            }
        });
    }
};
</script>
