<template>
    <b-modal
            id="completeSeagameModal"
            dialog-class="modal-dialog-event"
            :content-class="['modal-seagame modal-seagame--complete']"
            hide-footer
            ok-only
            no-stacking
            centered
            @show="beforeShowCompleteSpin"
    >
        <div class="modal-wrapper">
            <div class="modal-inner">
                <div class="header-wrapper text-center">
                    <h3 class="header-wrapper__title desktop">
                        CHÚC MỪNG
                    </h3>
                    <h3 class="header-wrapper__sub">
                        BẠN ĐÃ HOÀN THÀNH {{ spinCountComplete }} LƯỢT QUAY
                    </h3>
                </div>
                <div class="modal-spin scrollbar">
                    <div class="modal-spin__list">
                        <div class="modal-spin__item" v-for="(spin, index) in reverseReward" :key="index">
                            <p v-if="$device.isMobileOrTablet" class="modal-spin__turn"># {{index + 1 }}</p>
                            <p v-else class="modal-spin__turn">Lượt quay {{index + 1 }}</p>
                            <div v-if="checkPrize(spin.name)==='+3 ĐIỂM'" class="modal-spin__result">
                                <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/item-gold.png`" alt="gold"/>
                                <p>{{checkPrize(spin.name)}}</p>
                            </div>
                            <div v-else-if="checkPrize(spin.name)==='+2 ĐIỂM'" class="modal-spin__result">
                                <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/item-silver.png`" alt="silver"/>
                                <p>{{checkPrize(spin.name)}}</p>
                            </div>
                            <div v-else-if="checkPrize(spin.name)==='+1 ĐIỂM'" class="modal-spin__result">
                                <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/item-copper.png`" alt="copper"/>
                                <p>{{checkPrize(spin.name)}}</p>
                            </div>
                            <div v-else-if="checkPrize(spin.name)==='HOÀN TRẢ'" class="modal-spin__result">
                                <p class="text-return">{{checkPrize(spin.name)}}</p>
                                <d-img class="coin" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/icon-refund.png`" alt="coin"/>
                            </div>
                            <div v-else-if="checkPrize(spin.name)==='Lucky'" class="modal-spin__result">
                                <p>{{checkPrize(spin.name)}}</p>
                            </div>
                            <div v-else class="modal-spin__result">
                                <p>{{checkPrize(spin.name)}}</p>
                                <d-img class="coin" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/coin.png`" alt="coin"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-result">
                    <d-img v-if="$device.isMobileOrTablet" class="title" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/title-prize-mb.png`" alt="title"/>
                    <d-img v-else class="title" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/title-prize.png`" alt="title"/>
                    <div class="modal-result__list">
                        <div class="modal-result__item">
                            <div class="top">
                                <d-img class="coin" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/coin.png`" alt="Icon money"/>
                            </div>
                            <span>{{ totalMoney | formatAmount(' ') }}</span>
                        </div>
                        <div class="modal-result__item">
                            <div class="top">
                                <span v-if="numberOfGold >= 1" class="symbol">X</span>
                                <span>{{ numberOfGold }}</span>
                                <d-img class="medal" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/item-gold.png`" alt="Icon spins"/>
                            </div>
                            <span><span v-if="numberOfGold > 0">+</span>{{ numberOfGold * 3 }} ĐIỂM</span>
                        </div>
                        <div class="modal-result__item">
                            <div class="top">
                                <span v-if="numberOfSilver >= 1" class="symbol">X</span>
                                <span>{{ numberOfSilver }}</span>
                                <d-img class="medal" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/item-silver.png`" alt="Icon spins"/>
                            </div>
                            <span><span v-if="numberOfSilver > 0">+</span>{{ numberOfSilver * 2 }} ĐIỂM</span>
                        </div>
                        <div class="modal-result__item">
                            <div class="top">
                                <span v-if="numberOfCopper >= 1" class="symbol">X</span>
                                <span>{{ numberOfCopper }}</span>
                                <d-img class="medal" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/item-copper.png`" alt="Icon spins"/>
                            </div>
                            <span><span v-if="numberOfCopper > 0">+</span>{{ numberOfCopper }} ĐIỂM</span>
                        </div>
                        <div class="modal-result__item">
                            <div class="top">
                                <span v-if="numberOfRefund >= 1" class="symbol">X</span>
                                <span>{{ numberOfRefund }}</span>
                                <d-img v-if="numberOfRefund > 0" class="medal" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/icon-refund.png`" alt="Icon spins"/>
                            </div>
                            <span class="space">HOÀN TRẢ</span>
                        </div>
                    </div>
                </div>
                <div class="modal-button">
                    <a
                            @click="openWinPrizeModal"
                            href="javascript:void(0)"
                            class="button show-desktop"
                    >
                        Nhận thưởng
                    </a>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import { eventSeagameInfo } from '@/resources/seagame/seagame';

export default {
    props: {
        listRewardSpin: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            spinTurnList: [],
            completeSpinList: [],
            eventSeagameInfo
        };
    },
    methods: {
        beforeShowCompleteSpin() {
            this.completeSpinList = this.listRewardSpin.map((spin) => ({
                code: spin,
                name: this.eventSeagameInfo.spinMap[spin].name,
                number: this.eventSeagameInfo.spinMap[spin].number
            }));
        },
        openWinPrizeModal() {
            this.$bvModal.hide('completeSeagameModal');
            this.$store.commit('mutate', {
                property: 'limitGold',
                with: this.numberOfGold
            });
            this.$store.commit('mutate', {
                property: 'limitSilver',
                with: this.numberOfSilver
            });
            this.$store.commit('mutate', {
                property: 'limitCopper',
                with: this.numberOfCopper
            });
            this.$store.commit('mutate', {
                property: 'limitRefund',
                with: this.numberOfRefund
            });
        },
        checkPrize(name) {
            switch (name) {
                case 'gold':
                    return '+3 ĐIỂM';
                case 'silver':
                    return '+2 ĐIỂM';
                case 'copper':
                    return '+1 ĐIỂM';
                case 'refund':
                    return 'HOÀN TRẢ';
                case 'lucky':
                    return 'Lucky';
                default:
                    return name;
            }
        }
    },
    computed: {
        prizesMoney() {
            const money = this.completeSpinList.reduce((acc, spin) => {
                if (spin.code !== 7 || spin.code !== 8 || spin.code !== 9 || spin.code !== 10 || spin.code !== 11) {
                    return acc + spin.number;
                }
                return 0;
            }, 0);
            return money * 1000;
        },
        totalMoney() {
            let money = 0;
            this.completeSpinList.forEach(item => {
                if (item.code !== 7 || item.code !== 8 || item.code !== 9 || item.code !== 10 || item.code !== 11) {
                    console.log(item.number);
                    money += item.number;
                }
            });
            return money * 1000;
        },
        numberOfGold() {
            const numberGold = this.completeSpinList.filter((spin) => spin.code === 10);
            return numberGold.length !== 0 ? numberGold.length : 0;
        },
        numberOfSilver() {
            const numberSilver = this.completeSpinList.filter((spin) => spin.code === 9);
            return numberSilver.length !== 0 ? numberSilver.length : 0;
        },
        numberOfCopper() {
            const numberCopper = this.completeSpinList.filter((spin) => spin.code === 8);
            return numberCopper.length !== 0 ? numberCopper.length : 0;
        },
        numberOfRefund() {
            const numberRefund = this.completeSpinList.filter((spin) => spin.code === 7);
            return numberRefund.length !== 0 ? numberRefund.length : 0;
        },
        reverseReward() {
            return this.completeSpinList.slice();
        },
        spinCountComplete() {
            return this.reverseReward.length;
        }
    }
};
</script>
