<template>
    <header id="header" class="header" :class="{'header__login': user !== null}">
        <img v-if="$isEnableThemeHoliday()" class="lanterns" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/home/lanterns-left.png`" alt="flower"/>
        <img v-if="$isEnableThemeHoliday()" class="lanterns right" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/home/lanterns-right.png`" alt="flower"/>
        <h1 class="header__hidden-h1">{{this.setSeo('title')}}</h1>
        <div class="container header-logo">
            <n-link to="/">
                <d-img v-if="$isEnableThemeNoel()" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/logo.gif`" alt="logo"/>
                <d-img v-else :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/logo.gif`" alt="logo"/>
                <img v-if="$isEnableThemeNoel()" class="tree" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/tree.png`" alt="tree"/>
                <img v-if="$isEnableThemeHoliday()" class="flower" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/home/flower-logo.png`" alt="flower"/>
            </n-link>
            <img class="before-logo" :src="`/assets/img/before-logo.svg?v=1`" alt="logo"/>
        </div>
        <div class="header__top">
            <div class="container flex-box just-between">
                <div class="header-left flex-box">
                    <!-- <div class="contact">
                        <a class="flex-box" :href="`tel:${this.$t('contact.phone')}`">
                            <div class="icon">
                                <img :src="`/assets/img/header/icon-phone.svg`" alt="phone"/>
                            </div>
                            <div class="text">
                                <span class=" color-primary">HOTLINE: </span>{{this.$t('contact.phone') | fomatPhone}}
                            </div>
                        </a>
                    </div> -->
                </div>
                <div v-if="user === null" class="header-right header-form flex-box just-between">
                    <form method="post" id="frm-quick-login" @submit.prevent="login" name="frm-quick-login" class="header-quick-login  flex-box just-between">
                        <div class="header-form-group">
                            <input v-model="formData.username" type="text" placeholder="Tên đăng nhập" class="input-custom" name="userName">
                        </div>
                        <div class="header-form-group">
                            <input v-model="formData.password" type="password" placeholder="Mật khẩu" class="input-custom" name="pwd" id="txt-password">
                        </div>
                        <d-button type="submit" class="button background-2 padding-2" :disabled="isSubmit" :loading="isSubmit">
                            {{$t('header.login')}}
                        </d-button>
                    </form>
                    <a v-if="user === null" @click="$bvModal.show('loginModal')" data-toggle="modal" href="javascript:void(0)" class="btn-login-monile button background-2 padding-2 hidden">
                        {{$t('header.login')}}
                    </a>
                    <a @click="$bvModal.show('registerModal')" data-toggle="modal" href="javascript:void(0)" class="button show-desktop">{{$t('header.register')}}</a>
                    <a @click="$bvModal.show('registerModal')" data-toggle="modal" href="javascript:void(0)" class="button hidden">{{$t('header.register')}}</a>
                </div>
                <div v-if="user !== null" class="header-right header-form flex-box just-between">
                    <n-link class="header-user" to="/user?tab=index">
                        <div class="header-user-group">
                            <div class="header-user-name">
                                <p class="name">{{user.fullname ? user.fullname : user.username}}</p>
                            </div>
                            <div class="header-user-amount">
                                <p class="amount">{{user.balance | formatAmount }}</p>
                            </div>
                        </div>
                        <div class="header-user-icon">
                            <img :src="`/assets/img/icon/icon-user.png`" alt="Icon User"/>
                        </div>
                    </n-link>
                    <n-link to="/user/deposit" class="button deposit">nạp tiền</n-link>
                    <a @click.prevent="logout" href="/" class="button logout">
                        <span>Đăng xuất</span>
                        <img :src="`/assets/img/icon/icon-logout.png`" alt="icon logout"/>
                    </a>
                </div>
            </div>
        </div>
        <header-menu :currentTab="currentTab" :user="user"/>
    </header>
</template>
<script>
import headerMenu from './header/menu.vue';

export default {
    components: {
        headerMenu
    },
    data() {
        return {
            formData: {
                username: '',
                password: ''
            },
            balance: 0,
            balanceSub: 0,
            errors: {},
            isModalVisible: false,
            isSubmit: false,
            swalCancel: null
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        currentTab() {
            return this.$store.getters.getCurrentTab;
        },
        wallet() {
            return this.$store.state.wallet;
        }
    },
    methods: {
        setSeo(tag) {
            return this.$te(`meta.${this.$getRoute(this.$route).name}`)
                ? this.$t(`meta.${this.$getRoute(this.$route).name}.${tag}`)
                : this.$t(`meta.all.${tag}`);
        },
        async logout() {
            try {
                await this.$store.dispatch('logout');
                this.resetForm();
            } catch (e) {
                this.$alertError('Server đang bị lỗi! Vui lòng thử lại sau!');
                this.formError = e.message;
            }
        },
        async login() {
            try {
                this.isSubmit = true;
                if (!this.formData.username || !this.formData.password) {
                    this.isSubmit = false;
                    this.swalCancel = this.$alertHtmlErrorLogin().then(async (result) => {
                        if (result.isDismissed) {
                            return;
                        }
                        if (result.isConfirmed) {
                            this.$Swal.close();
                            this.$bvModal.show('forgotPasswordModal');
                        }
                    });
                    return false;
                }
                const data = await this.$store.dispatch('login', { ...this.formData });
                if (data && data.status === 'OK') {
                    this.isSubmit = false;
                    this.getWallet();
                }
                if (data && data.status !== 'OK') {
                    this.isSubmit = false;
                    this.swalCancel = this.$alertHtmlErrorLogin().then(async (result) => {
                        if (result.isDismissed) {
                            return;
                        }
                        if (result.isConfirmed) {
                            this.$Swal.close();
                            this.$bvModal.show('forgotPasswordModal');
                        }
                    });
                    return false;
                }
            } catch (e) {
                this.formError = e.message;
                this.isSubmit = false;
                this.$alert('Server đang bị lỗi! Vui lòng thử lại sau!');
            }
        },
        resetForm() {
            this.formData.username = '';
            this.formData.password = '';
        },
        async getWallet() {
            try {
                const res = await this.$userApi.getWallet();
                if (res.status === 'OK') {
                    this.$store.dispatch('updateWallet', {
                        oldBalance: res.data.balance,
                        balance: res.data.balance,
                        oldBalanceSub: res.data.balanceSub,
                        balanceSub: res.data.balanceSub,
                        maxBalance: res.data.balanceAll
                    });
                }
            } catch (e) {
                this.formError = e.message;
            }
        },
        getThumbnail(thumbnail) {
            const { $store: { state } } = this;
            return `${state.mapDomain.D3_IMAGE_URL}/${thumbnail}`;
        }
    }
};

</script>
