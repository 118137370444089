const apiVersion = '/api/v1';

export default ($axios) => ({
    index() {
        return $axios.$get(`${apiVersion}/casino`);
    },
    getByCode(partnerCode, code) {
        return $axios.$get(`${apiVersion}/casinoUrl`, {
            params: {
                partnerCode,
                code
            },
            progress: false
        });
    },
    casinoSexy() {
        return $axios.$get(`${apiVersion}/casino/sexy`);
    }
});
