<template>
    <b-modal
            id="luckyEventModal"
            dialog-class="modal-dialog-event"
            :content-class="['modal-wheel modal-wheel--lucky', {'holiday-custom': $isEnableThemeHoliday()}]"
            hide-footer
            ok-only
            no-stacking
            centered
    >
        <div class="modal-wrapper">
            <div class="modal-inner text-center">
                <d-img class="modal-inner__img" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/dialog/goodluck.png`" alt="goodluck"/>
                <p class="modal-inner__content">NHANH TAY CƯỢC NGAY 2.000.000 VNĐ <br> NHẬN NGAY 1 LƯỢT QUAY</p>
                <div class="modal-inner__button">
                    <a
                            @click="moveTo('/the-thao-chau-a')"
                            data-toggle="modal"
                            href="javascript:void(0)"
                            class="button"
                    >
                        CƯỢC NGAY
                    </a>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    methods: {
        moveTo(link) {
            this.$bvModal.hide('luckyEventModal');
            this.$openLink(link, {
                loginRequired: false,
                newTab: false
            });
        }
    }
};
</script>
