export default ({
    store,
    app: {
        $axios,
        $alertHtmlLogin,
        $alertError,
        $trackingResponse
    },
    redirect
}) => {
    if (store.state.mapDomain.HOME_URL) {
        $axios.defaults.baseURL = store.state.mapDomain.HOME_URL;
    }

    $axios.onResponse((response) => {
        if (typeof $tracking === 'function') {
            $trackingResponse(response);
        }
    });

    $axios.onError((error) => {
        const code = parseInt(error.response && error.response.status, 10);
        if ((code === 502 || code === 400 || code === 318) && error.response.data.status === 'SHOW_MESSAGE') {
            $alertError(error.response.data.message);
        } else if (code === 504) {
            $alertError('Không thể kết nối máy chủ, Vui lòng thử lại sau!');
        } else if (code === 401) {
            store.commit('mutate', {
                property: 'user',
                with: null
            });
            // redirect('/');
            // close mini game
            if (store.state.isOpenMiniGame) {
                store.commit('mutate', {
                    property: 'isOpenMiniGame',
                    with: false
                });
            }
            // clear minigame token
            // if (localStorage) {
            //     localStorage.setItem('user_token', '');
            // }
            if (store.state.device === 'desktop') {
                // clear refresh interval
                if (store.state.refreshInterval !== null) {
                    clearInterval(store.state.refreshInterval);
                    store.commit('mutate', {
                        property: 'refreshInterval',
                        with: null
                    });
                }
                // clear iframe
                store.dispatch('clearIframe');
                // eslint-disable-next-line no-undef
                $alertHtmlLogin('Thông báo', 'Tài khoản của bạn đã được đăng nhập trên trình duyệt / thiết bị khác').then(async () => {
                    redirect('/');
                    window.location.reload();
                });
            } else {
                $alertHtmlLogin('Thông báo', 'Tài khoản của bạn đã được đăng nhập trên trình duyệt / thiết bị khác').then(async () => {
                    redirect('/');
                    window.location.reload();
                });
            }
        }
    });
};
