<template>
    <b-modal
            id="luckySeagameModal"
            dialog-class="modal-dialog-event"
            :content-class="['modal-seagame']"
            hide-footer
            ok-only
            no-stacking
            centered
    >
        <div class="modal-wrapper">
            <div class="modal-inner text-center">
                <d-img class="modal-inner__img img-goodluck" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/goodluck.png`" alt="goodluck"/>
                <div class="modal-inner__button">
                    <a
                            @click="$bvModal.hide('luckySeagameModal')"
                            data-toggle="modal"
                            href="javascript:void(0)"
                            class="button"
                    >
                        QUAY TIẾP
                    </a>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    methods: {
    }
};
</script>
