const apiVersion = '/api/v1';

export default ($axios) => ({
    getAthena(params) {
        if (params) {
            return $axios.$get(`${apiVersion}/athenaUrl`, {
                params,
                progress: false
            });
        }
        return $axios.$get(`${apiVersion}/athenaUrl`, {
            progress: false
        });
    },
    getAthenaMatch(params) {
        return $axios.$get(`${apiVersion}/athena/matchUrl`, {
            params,
            progress: false
        });
    },
    getAthenaVS() {
        return $axios.$get(`${apiVersion}/athenaVsUrl`, {
            progress: false
        });
    },
    getAthenaEsport() {
        return $axios.$get(`${apiVersion}/athena/esportsUrl`, {
            progress: false
        });
    },
    getKeno() {
        return $axios.$get(`${apiVersion}/kenoUrl`, {
            progress: false
        });
    },
    getNumberGame() {
        return $axios.$get(`${apiVersion}/numbergameUrl`, {
            progress: false
        });
    },
    getLode() {
        return $axios.$get(`${apiVersion}/lodeUrl`, {
            progress: false
        });
    },
    getLodeSieuToc() {
        return $axios.$get(`${apiVersion}/lodeUrl2`, {
            progress: false
        });
    },
    getSlotById(id) {
        return $axios.$get(`${apiVersion}/slotUrl`, {
            params: {
                gId: id
            },
            progress: false
        });
    },
    getSSport() {
        return $axios.$get(`${apiVersion}/ssportUrl`, {
            progress: false
        });
    },
    getKSportTPUrl() {
        return $axios.$get(`${apiVersion}/tp/ksportUrl`, {
            progress: false,
            withCredentials: true
        });
    },
    getMaintenance() {
        return $axios.$get(`${apiVersion}/athena/maintenance`);
    },
    getGameById(params) {
        return $axios.$get(`${apiVersion}/gameUrl`, {
            params,
            progress: false
        });
    },
    getLottery() {
        return $axios.$get(`${apiVersion}/lotteryUrl`, {
            progress: false
        });
    },
    getLotterySexy() {
        return $axios.$get(`${apiVersion}/lotterySexyUrl`, {
            progress: false
        });
    },
    getUrlByName(name, params) {
        const mapUrl = {
            'tro-choi-so': 'numbergameUrl',
            lode: 'lodeUrl',
            'quay-so': 'lotteryUrl',
            keno: 'kenoUrl',
            'the-thao-chau-au': 'ssportUrl',
            'the-thao-chau-a': 'athenaUrl',
            'the-thao-ao': 'athenaVsUrl',
            'the-thao-so': 'athena/esportsUrl',
            match: 'athena/matchUrl'
        };
        if (mapUrl[name]) {
            return $axios.$get(`${apiVersion}/${mapUrl[name]}`, {
                params,
                progress: false
            });
        }
        return {
            code: 200,
            status: 'ERROR',
            data: {
                url: ''
            }
        };
    }
});
