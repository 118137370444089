const apiVersion = '/api/v1';

export default ($axios) => ({
    index() {
        return $axios.$get(`${apiVersion}/indexGame`);
    },
    getPage(payload) {
        return $axios.$get(`${apiVersion}/game`, {
            params: payload
        });
    },
    getSlotGame(payload) {
        return $axios.$get(`${apiVersion}/game/ktek`, {
            params: {
                ...payload
            }
        });
    },
    getToken() {
        return $axios.$get(`${apiVersion}/ktekToken`, {
            progress: false
        });
    },
    async jackpot() {
        // return $axios.$get(`${apiVersion}/slot/jackpot`, {
        //     params: payload,
        //     progress: false
        // });
        const result = await $axios.$get(process.env.JACKPOT_URL_LINK, {
            params: {
                _: new Date().getTime()
            },
            progress: false
        });
        const jackpotData = {};
        if (result.status === 'OK') {
            const data = result.data[0];
            Object.keys(data).forEach((key) => {
                if (key.startsWith('kts') || key.startsWith('ktf')) {
                    const gId = key.replace('_', '');
                    let maxJackpot = 0;
                    Object.keys(data[key]).forEach((e) => {
                        const jackpot = parseInt(data[key][e], 10);
                        if (maxJackpot < jackpot) {
                            maxJackpot = jackpot;
                        }
                    });
                    jackpotData[gId] = maxJackpot;
                    if (gId === 'ktf1992') {
                        jackpotData[gId] = 0;
                    }
                }
            });
        }
        return {
            code: 200,
            status: 'OK',
            data: jackpotData
        };
    }
});
