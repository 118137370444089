export default ({ app: { $gtm } }, inject) => {
    /*
    ** Only run on client-side and only in production mode
    */
    const tracking = (data) => {
        if ($gtm) {
            $gtm.push(data);
        }
    };
    const trackingResponse = () => {
        // const apiUrl = res.request.responseURL;
        // const lastPath = apiUrl.substring(apiUrl.lastIndexOf('/') + 1);
        // if ($gtm) {
        //     $gtm.pushEvent(data);
        // }
    };
    inject('tracking', tracking);
    inject('trackingResponse', trackingResponse);
};
