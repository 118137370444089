import mapDomain from '../config/domain.json';
import { isCacheCrawlerAgent } from '../helper';

export const state = () => ({
    user: null,
    currentTab: null,
    iframe: {
        loaded: false,
        height: 850,
        tab: '',
        tabName: '',
        tabUrl: ''
    },
    iframeUrl: '',
    iframeDisplay: false,
    userTab: null,
    maintenance: {
        enable: false
    },
    device: 'desktop',
    newTabMap: {},
    wallet: {
        balance: 0,
        oldBalance: 0,
        oldBalanceSub: 0,
        balanceSub: 0,
        maxBalance: 0,
        screenUpdate: ''
    },
    jackpot: {},
    refreshInterval: null,
    utmQuery: {},
    categories: [],
    maintain: '0',
    pwa: 0,
    mapDomain: {},
    freeSpinCode: '',
    plan: {},
    urlAfterLogin: {
        url: '',
        type: 0
    },
    switchVersion: 0,
    enablePwa: 0,
    isSafari: false,
    totalWithDraw: 0,
    invoicePromotion: 0,
    isOpenMiniGame: false,
    isOpeningMiniGame: false,
    hideHeader: false,
    hideFooter: false,
    ktekToken: null,
    visitVivo: false,
    visitSbtech: false,
    langs: ['en', 'vi'],
    lang: 'vi',
    routerLoadingEnable: true,
    livechatLoaded: false,
    luckySpin: false,
    isCacheCrawlerAgent: false,
    showMaintain: false,
    showMergeWallet: false,
    spinsCount: 0,
    limitGold: 0,
    limitSilver: 0,
    limitCopper: 0,
    limitRefund: 0,
    prizeNumberList: [],
    bankStatement: {}
});

export const mutations = {
    mutate(state, payload) {
        state[payload.property] = typeof payload.with === 'object' && payload.with !== null ? { ...state[payload.property], ...payload.with } : payload.with;
    },
    SET_LANG(state, lang) {
        if (state.langs.indexOf(lang) !== -1) {
            state.lang = lang;
        }
    },
    setRouterLoadingEnable(state, payload) {
        state.routerLoadingEnable = payload;
    },
    setPrizeNumberList(state, prizeNumberList) {
        state.prizeNumberList = prizeNumberList;
    }
};

export const getters = {
    getCurrentTab(state) {
        return state.currentTab;
    },
    getUserTab(state) {
        return state.userTab;
    },
    getCategories(state) {
        return state.categories;
    },
    getbankStatement(state) {
        return state.bankStatement;
    }
};

const initDevice = ({ commit }, {
    app,
    route
}) => {
    // get device from cookies
    const device = app.$cookies.get('setDevice');
    if (device) {
        commit('mutate', {
            property: 'device',
            with: device
        });
    }
    if (route.query.device) {
        // set fake device cookies
        app.$cookies.set('setDevice', route.query.device, {
            path: '/',
            maxAge: 60 * 60 * 24 * 10
        });
        app.$cookies.set('device', route.query.device, {
            path: '/'
        });
        commit('mutate', {
            property: 'device',
            with: route.query.device
        });
        if (route.query.device === 'mobile') {
            app.$cookies.set('os', 'ios', {
                path: '/'
            });
        } else {
            app.$cookies.set('os', '', {
                path: '/'
            });
        }
    }
    // set device cookie by real device
    if (app.$device.isDesktop) {
        app.$cookies.set('device', 'desktop', {
            path: '/'
        });
        if (!device) {
            commit('mutate', {
                property: 'device',
                with: 'desktop'
            });
        }
    } else {
        app.$cookies.set('device', 'mobile', {
            path: '/'
        });
        if (app.$device.isIos) {
            app.$cookies.set('os', 'ios', {
                path: '/'
            });
        } else {
            app.$cookies.set('os', 'android', {
                path: '/'
            });
        }
        if (!device) {
            commit('mutate', {
                property: 'device',
                with: 'mobile'
            });
        }
    }
};

const initAff = ({ commit }, { app, route, req }) => {
    // INIT UTM CODE
    if (route.query.a) {
        const affId = route.query.a;
        app.$cookies.set('aff_id', affId, {
            path: '/',
            maxAge: 60 * 60 * 24 * 10
        });
        const querystring = route.fullPath.substr('1');
        app.$cookies.set('querystring', querystring, {
            path: '/',
            maxAge: 60 * 60 * 24 * 10
        });
    }
    const arrQuery = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

    arrQuery.forEach((e) => {
        if (route.query[e]) {
            const value = route.query[e];
            app.$cookies.set(e, value, {
                path: '/',
                maxAge: 60 * 60 * 24 * 10
            });
        }
    });
};

export const actions = {
    async nuxtServerInit({ commit }, {
        app,
        route,
        req,
        redirect
    }) {
        // detect crawling from bot google cache
        const userAgent = req.headers['user-agent'];
        if (isCacheCrawlerAgent(userAgent)) {
            commit('mutate', {
                property: 'isCacheCrawlerAgent',
                with: true
            });
        }
        /**
         *  lấy cookies data và khở tạo lại state
         */
        const user = app.$cookies.get('user', { parseJSON: true });
        if (user) {
            commit('mutate', {
                property: 'user',
                with: user
            });
        }

        initDevice({ commit }, {
            app,
            route,
            req,
            redirect
        });

        initAff({ commit }, {
            app, route, req, redirect
        });

        // ENV
        try {
            const maintain = process.env.MAINTAIN;
            if (maintain === '1') {
                commit('mutate', {
                    property: 'maintain',
                    with: process.env.MAINTAIN
                });
                return redirect('/bao-tri');
            }
            const switchVersion = process.env.SWITCH_VERSION;
            if (switchVersion === '1') {
                commit('mutate', {
                    property: 'switchVersion',
                    with: process.env.SWITCH_VERSION
                });
            }
            const enablePwa = process.env.PWA;
            if (enablePwa === '1') {
                commit('mutate', {
                    property: 'enablePwa',
                    with: process.env.PWA
                });
            }

            // const mapDomain = JSON.parse(process.env.DOMAIN_MAP) || {};
            const MAIN_DOMAIN = process.env.MAIN_DOMAIN;
            const BANNED_DOMAIN = process.env.BANNED_DOMAIN ? process.env.BANNED_DOMAIN.split(',') : [];
            let DOMAIN = req.headers.host;
            let redirectFlg = false;
            // redirect
            if (BANNED_DOMAIN.includes(DOMAIN)) {
                DOMAIN = MAIN_DOMAIN;
                redirectFlg = true;
            }
            commit('mutate', {
                property: 'mapDomain',
                with: mapDomain[DOMAIN] ? mapDomain[DOMAIN] : mapDomain[MAIN_DOMAIN]
            });

            app.$cookies.set('domain', mapDomain[DOMAIN].HOME_URL, {
                path: '/'
            });

            app.$cookies.set('host', mapDomain[DOMAIN].HOST, {
                path: '/'
            });

            // this.$axios.defaults.baseURL = mapDomain[DOMAIN].HOME_URL;

            // redirect
            if (redirectFlg) {
                redirect(mapDomain[DOMAIN].HOME_URL);
            }

            if (route.path === '/khuyen-mai/') {
                const arrString = route.fullPath.split('/khuyen-mai/');
                redirect(`/khuyen-mai${arrString[1]}`);
            }
        } catch (e) {
            console.log(e);
        }
    },
    async login({ commit }, data) {
        try {
            const result = await this.$userApi.login(data);
            if (result.status === 'OK' || result.status === 'SHOW_MESSAGE') {
                commit('mutate', {
                    property: 'user',
                    with: result.data[0]
                });
                this.$tracking({ event: 'formSubmitted', formName: 'Form_Login' });
                // clear iframe
                this.dispatch('clearIframe');
                // set refresh interval
                this.dispatch('refreshInterval');
                // SET TOKEN FOR MINIGAME
                // if (localStorage) {
                //     localStorage.setItem('user_token', result.data[0].tp_token);
                // }
                if (this.state.user.balance === 0) {
                    // this.$router.push('/user/deposit');
                    setTimeout(() => {
                        this.$router.push('/user/deposit?tab=index');
                    }, 1500);
                } else {
                    // this.$router.push('/');
                    // eslint-disable-next-line no-lonely-if
                    if (this.state.device === 'mobile') {
                        if (this.state.urlAfterLogin.url) {
                            if (this.state.urlAfterLogin.type === 0) {
                                this.$router.push(this.state.urlAfterLogin.url);
                            } else {
                                this.$router.push('/');
                                this.$cookies.set('openNewTab', this.state.urlAfterLogin.url, '1h');
                            }
                        } else {
                            this.$router.push('/');
                        }
                        // fix minigame
                        window.location.reload();
                    } else if (this.state.urlAfterLogin.url !== '') {
                        if (this.state.urlAfterLogin.type === 0) {
                            this.$router.push(this.state.urlAfterLogin.url);
                        } else {
                            this.$cookies.set('openNewTab', this.state.urlAfterLogin.url, '1h');
                            window.location.reload();
                        }
                    } else {
                        this.$router.go({ path: '/', force: true });
                    }
                }
            }
            return result;
        } catch (error) {
            console.log(error);
        }
    },
    async register({ commit }, data) {
        try {
            const result = await this.$userApi.register(data);
            if (result.status === 'OK') {
                commit('mutate', {
                    property: 'user',
                    with: result.data[0]
                });
                this.$tracking({ event: 'formSubmitted', formName: 'Form_Register' });
                // clear iframe
                this.dispatch('clearIframe');
                // set refresh interval
                this.dispatch('refreshInterval');
                // this.$router.push('/user/deposit');
                setTimeout(() => {
                    this.$router.push('/user/deposit?tab=index');
                }, 1500);
            }
            return result;
        } catch (error) {
            console.log(error);
        }
    },
    async logout({ commit }) {
        await this.$userApi.logout({});
        commit('mutate', {
            property: 'user',
            with: null
        });
        // clear refresh interval
        if (this.state.refreshInterval !== null) {
            clearInterval(this.state.refreshInterval);
            commit('mutate', {
                property: 'refreshInterval',
                with: null
            });
        }
        // clear iframe
        this.dispatch('clearIframe');
        // reset wallet
        // reset plan
        commit('mutate', {
            property: 'plan',
            with: {
                type: ''
            }
        });
        // reset urlAfterLogin
        commit('mutate', {
            property: 'urlAfterLogin',
            with: {
                url: '',
                type: 0
            }
        });
        // close mini game
        if (this.state.isOpenMiniGame) {
            commit('mutate', {
                property: 'isOpenMiniGame',
                with: false
            });
        }
        // clear minigame token
        // if (localStorage) {
        //     localStorage.setItem('user_token', '');
        // }
        // this.$router.push('/');
        this.$router.go({ path: '/', force: true });
    },

    async test() {
        try {
            const result = await this.$axios.$get('/api/v1/hotmatch');
            console.log(JSON.stringify(result));
        } catch (error) {
            console.log(error);
        }
    },

    async updateInfo({ commit }, data) {
        try {
            const result = await this.$userApi.update(data);
            if (result.status === 'OK') {
                commit('mutate', {
                    property: 'user',
                    with: result.user
                });
            }
            return result;
        } catch (error) {
            console.log(error);
        }
    },

    async transferMoney({ commit }, { amount, balance }) {
        try {
            const type = amount > 0 ? 'DEPOSIT' : 'WITHDRAW';
            if (amount < 0) {
                amount = -amount;
            }
            const res = await this.$userApi.transfer({
                amount,
                type
            });
            if (res.status === 'SUCCESS') {
                // update user state
                commit('mutate', {
                    property: 'user',
                    with: {
                        balance
                    }
                });
            }
            return res;
        } catch (error) {
            console.log(error);
        }
    },

    async updateIframe({ commit }, data) {
        try {
            commit('mutate', {
                property: 'iframe',
                with: data
            });
        } catch (error) {
            console.log(error);
        }
    },

    async updateHeightIframe({ commit }, data) {
        try {
            commit('mutate', {
                property: 'iframe',
                with: {
                    loaded: true,
                    height: data
                }
            });
        } catch (error) {
            console.log(error);
        }
    },

    async updateIframeUrl({ commit }, data) {
        try {
            commit('mutate', {
                property: 'iframeUrl',
                with: data
            });
        } catch (error) {
            console.log(error);
        }
    },

    async clearIframe({ commit }) {
        try {
            commit('mutate', {
                property: 'iframe',
                with: {
                    loaded: false,
                    tabUrl: '/',
                    height: 850,
                    tab: -1
                }
            });
            commit('mutate', {
                property: 'iframeUrl',
                with: ''
            });
        } catch (error) {
            console.log(error);
        }
    },

    async updateMaintenance({ commit }, data) {
        try {
            commit('mutate', {
                property: 'maintenance',
                with: data
            });
        } catch (error) {
            console.log(error);
        }
    },

    async updateWallet({ commit }, data) {
        try {
            commit('mutate', {
                property: 'wallet',
                with: data
            });
        } catch (error) {
            console.log(error);
        }
    },

    async updateUser({ commit }, data) {
        try {
            commit('mutate', {
                property: 'user',
                with: data
            });
        } catch (error) {
            console.log(error);
        }
    },

    async updateBalance({ commit }, data) {
        try {
            commit('mutate', {
                property: 'user',
                with: data
            });
        } catch (error) {
            console.log(error);
        }
    },

    async getJackpot({ commit }) {
        try {
            const result = await this.$gameApi.jackpot();
            if (result !== null && result.status === 'OK' && result.data) {
                commit('mutate', {
                    property: 'jackpot',
                    with: result.data
                });
            }
            return result;
        } catch (error) {
            console.log(error);
        }
    },

    async refreshToken({ commit }) {
        try {
            if (this.state.user !== null) {
                const result = await this.$axios.$get('/api/v1/refresh', { progress: false });
                if (result !== null && result.status === 'OK' && result.user) {
                    commit('mutate', {
                        property: 'user',
                        with: result.user
                    });
                    // set localStorage
                    // if (localStorage) {
                    //     if (!localStorage.getItem('user_token')) {
                    //         localStorage.setItem('user_token', result.user.tp_token);
                    //     }
                    // }
                }
                if (result !== null && result.status === 'OK' && result.maintain === '1') {
                    commit('mutate', {
                        property: 'maintain',
                        with: result.maintain
                    });
                    this.$router.push('/bao-tri');
                }
            }
        } catch (error) {
            console.log(error);
        }
    },

    async refreshInterval({ commit }) {
        try {
            // set refresh interval
            if (this.state.refreshInterval === null && this.state.maintain !== '1') {
                const interval = setInterval(async () => {
                    this.dispatch('refreshToken');
                }, 5000);

                commit('mutate', {
                    property: 'refreshInterval',
                    with: interval
                });
            }
        } catch (error) {
            console.log(error);
        }
    },

    async getCategories({ commit }) {
        const categories = this.getters.getCategories;
        if (categories.length > 0) {
            return categories;
        }
        const res = await this.$postApi.getCategories();
        if (res.status === 'OK') {
            commit('mutate', {
                property: 'categories',
                with: res.categories
            });
            return res.categories;
        }
        return [];
    },

    async updatePlan({ commit }, plan) {
        commit('mutate', {
            property: 'plan',
            with: plan
        });
    },

    async setUrlAfterLogin({ commit }, data) {
        commit('mutate', {
            property: 'urlAfterLogin',
            with: data
        });
    },

    async countWithDraw({ commit }) {
        try {
            if (this.state.user !== null) {
                const result = await this.$withDrawApi.count();
                if (result !== null && result.status === 'OK') {
                    commit('mutate', {
                        property: 'totalWithDraw',
                        with: result.total
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    },

    async countInvoicePromotion({ commit }) {
        try {
            if (this.state.user !== null) {
                const result = await this.$userApi.countInvoicePromotion();
                if (result !== null && result.status === 'OK') {
                    commit('mutate', {
                        property: 'invoicePromotion',
                        with: result.data.total
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    },

    async getKtekToken({ commit }) {
        try {
            if (this.state.user !== null) {
                const rs = await this.$gameApi.getToken();
                if (rs.status === 'OK') {
                    if (localStorage) {
                        localStorage.setItem('user_token', rs.data.token);
                        commit('mutate', {
                            property: 'ktekToken',
                            with: rs.data.token
                        });
                    }
                } else {
                    commit('mutate', {
                        property: 'ktekToken',
                        with: 'error'
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    },

    async getLuckySpin({ commit }) {
        try {
            const result = await this.$userApi.getLuckySpin();
            if (result !== null && result.status === 'OK' && result.data) {
                if (result.data && result.data.luckySpin) {
                    commit('mutate', {
                        property: 'luckySpin',
                        with: true
                    });
                }
            }
            return result;
        } catch (error) {
            console.log(error);
        }
    },

    async setbankStatement({ commit }, data) {
        commit('mutate', {
            property: 'bankStatement',
            with: data
        });
    }
};
