<template>
    <b-modal
            id="completeSpinModal"
            dialog-class="modal-dialog-event"
            :content-class="['modal-wheel modal-wheel--complete-spin', {'holiday-custom flower': $isEnableThemeHoliday()}]"
            hide-footer
            ok-only
            no-stacking
            centered
            @show="beforeShowCompleteSpin"
    >
        <div class="modal-wrapper">
            <div class="modal-inner">
                <div class="header-wrapper">
                    <h3 class="header-wrapper__title desktop">
                        Hoàn thành
                    </h3>
                    <h3 class="header-wrapper__title desktop white">
                        {{ spinCountComplete }} lượt quay
                    </h3>
                    <h3 class="header-wrapper__title mobile">
                        <span>Hoàn thành {{ spinCountComplete }} lượt quay</span>
                    </h3>
                    <h3 class="header-wrapper__title white mobile">quà quá đã</h3>
                </div>
                <h4 class="modal-inner__title">Lịch sử quay</h4>
                <div class="modal-spin scrollbar">
                    <div class="modal-spin__list">
                        <div class="modal-spin__item" v-for="(spin, index) in reverseReward" :key="index">
                            <p class="modal-spin__turn">Lượt quay {{index + 1 }}</p>
                            <p class="modal-spin__result">{{ spin.name }}</p>
                        </div>
                    </div>
                </div>
                <div class="modal-inner__reward">
                    <span>Phần thưởng BẠN NHẬN ĐƯỢC</span>
                </div>
                <div class="modal-result">
                    <div class="modal-result__list">
                        <div class="modal-result__item">
                            <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/dialog/money.svg`" alt="Icon money"/>
                            <span>{{ totalMoney | formatAmount('VNĐ') }}</span>
                        </div>
                        <div class="modal-result__item">
                            <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/dialog/spin-wheel.svg`" alt="Icon spins"/>
                            <span>{{ numberOfPredictions }} giải đặc biệt</span>
                        </div>
                    </div>
                </div>
                <div class="modal-button">
                    <a
                        @click="openWinPrizeModal"
                        href="javascript:void(0)"
                        class="button show-desktop"
                    >
                        Nhận thưởng
                    </a>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import { eventWheelInfo, eventWheelNoelInfo } from '@/resources/event-wheel';

export default {
    props: {
        listRewardSpin: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            spinTurnList: [],
            completeSpinList: [],
            eventWheelInfo
        };
    },
    methods: {
        beforeShowCompleteSpin() {
            this.completeSpinList = this.listRewardSpin.map((spin) => ({
                code: spin.type,
                name: spin.name,
                number: spin.number
            }));
            if (this.$isEnableThemeNoel()) {
                this.completeSpinList = this.listRewardSpin.map((spin) => ({
                    code: spin,
                    name: this.eventWheelNoelInfo.spinMap[spin].name,
                    number: this.eventWheelNoelInfo.spinMap[spin].number
                }));
            }
        },
        openWinPrizeModal() {
            this.$bvModal.hide('completeSpinModal');
            this.$store.commit('mutate', {
                property: 'limitPrediction',
                with: this.numberOfPredictions
            });
            this.$nextTick(() => {
                if (this.numberOfPredictions > 0) {
                    this.$bvModal.show('predictedTeamModal');
                }
            });
        }
    },
    computed: {
        totalMoney() {
            let money = 0;
            if (this.$isEnableThemeNoel()) {
                this.completeSpinList.forEach(item => {
                    if (item.code !== 4 && item.code !== 6) {
                        money += item.number;
                    }
                });
                return money * 1000;
            }
            this.completeSpinList.forEach(item => {
                if (item.code !== 'lucky') {
                    money += item.number;
                }
            });
            return money * 1000;
        },
        numberOfPredictions() {
            if (this.$isEnableThemeNoel()) {
                const numberPrediction = this.completeSpinList.filter((spin) => spin.code === 4);
                return numberPrediction.length !== 0 ? numberPrediction.length : 0;
            }
            const numberPrediction = this.completeSpinList.filter((spin) => spin.code === eventWheelInfo.rewardMapping.money_fifty_thousand.type);
            return numberPrediction.length !== 0 ? numberPrediction.length : 0;
        },
        reverseReward() {
            return this.completeSpinList.slice().reverse();
        },
        spinCountComplete() {
            return this.reverseReward.length;
        }
    }
};
</script>
