const apiVersion = '/api/v1';

export default ($axios) => ({
    bets(payload) {
        return $axios.$get(`${apiVersion}/lsbAll`, {
            params: payload,
            progress: false
        });
    },
    athenaBets(payload) {
        return $axios.$get(`${apiVersion}/lsbAthena`, {
            params: payload,
            progress: false
        });
    },
    transactions(payload) {
        return $axios.$get(`${apiVersion}/lsgd`, {
            params: payload,
            progress: false
        });
    },
    matchDetail(payload) {
        return $axios.$get(`${apiVersion}/matchDetail`, {
            params: payload,
            progress: false
        });
    },
    loadParlay(payload) {
        return $axios.$get(`${apiVersion}/parlay`, {
            params: payload,
            progress: false
        });
    }
});
