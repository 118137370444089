<template>
    <b-modal id="tranferWalletModal" content-class="modal-tranferwallet" hide-footer>
        <template v-slot:modal-title>
          CHUYỂN VÍ
        </template>
        <b-row class="extent-wallet-tranfer">
            <b-col cols="3" class="main-wallet">
                <p>VÍ CHÍNH: <span ref="balanceInput">{{balance | formatAmount}}</span></p>
                <p><small>E-Thể Thao, Lô Đề, Keno,..</small></p>
            </b-col>
            <b-col cols="6" class="tranfer-bar">
                <range-slider
                    type="range"
                    ref="mainWallet"
                    class="slider"
                    min="0"
                    :max="maxBalance"
                    step="1"
                    v-model="balance"
                    data-orientation="horizontal"
                    v-on:change="checkvalue"></range-slider>
            </b-col>
            <b-col cols="3" class="sub-wallet">
                <p>VÍ PHỤ: <span ref="balanceSubInput">{{balanceSub | formatAmount}}</span></p>
                <p><small>A-Thể Thao & Thể Thao Ảo</small></p>
            </b-col>
        </b-row>
        <div v-if="this.$store.state.user !== null
            && (this.$store.state.wallet.balance === 0 && this.$store.state.wallet.balanceSub === 0) ">
            <p class="text-center">Vui lòng nạp tiền vào ví chính để sử dụng chức năng này !</p>
            <button @click.prevent="goTo('/user/deposit?tab=index')" class="button btn-submit">NẠP TIỀN</button>
        </div>
        <d-button v-else :apply="transfer" type="button"
            :disabled="this.oldBalanceSub === this.balanceSub"
            :loading="isSubmit"
            class="button btn-submit">
            <span v-if="!isChangeOk || this.oldBalanceSub !== this.balanceSub">XÁC NHẬN</span>
            <span v-if="isChangeOk && this.oldBalanceSub === this.balanceSub">CHUYỂN VÍ THÀNH CÔNG</span>
        </d-button>
      </b-modal>
</template>
<script>
// eslint-disable-next-line import/order
import RangeSlider from 'vue-range-slider';
// you probably need to import built-in style
import 'vue-range-slider/dist/vue-range-slider.css';

export default {
    name: 'tranferWallet',
    components: {
        RangeSlider
    },
    data() {
        return {
            balance: 0,
            oldBalance: 0,
            balanceSub: 0,
            oldBalanceSub: 0,
            maxBalance: 0,
            isSubmit: false,
            isChangeOk: false
        };
    },
    computed: {
        wallet() {
            return this.$store.state.wallet;
        }
    },
    watch: {
        balance(value) {
            if (this.$refs.balanceInput) {
                if (value > this.maxBalance) {
                    value = this.maxBalance;
                }
                this.balance = value;
                this.$refs.balanceInput.value = value;
                this.balanceSub = this.maxBalance - this.balance;
            }
        },
        balanceSub(value) {
            if (this.$refs.balanceSubInput) {
                if (value > this.maxBalance) {
                    value = this.maxBalance;
                }
                this.balanceSub = value;
                this.$refs.balanceSubInput.value = value;
                this.balance = this.maxBalance - this.balanceSub;
            }
        },
        wallet(value) {
            this.balance = value.balance;
            this.oldBalance = value.oldBalance;
            this.balanceSub = value.balanceSub;
            this.oldBalanceSub = value.oldBalanceSub;
            this.maxBalance = value.maxBalance;
        }
    },
    methods: {
        async transfer() {
            if (this.isSubmit) {
                return;
            }
            this.isSubmit = true;
            const amount = this.balanceSub - this.oldBalanceSub;
            if (amount !== 0) {
                const res = await this.$store.dispatch('transferMoney', { amount, balance: this.balance });
                if (res && res.status === 'SUCCESS') {
                    this.oldBalanceSub = this.balanceSub;
                    this.oldBalance = this.balance;
                    this.$store.dispatch('updateInfo', {
                        balance: this.balance
                    });
                    this.$store.dispatch('updateWallet', {
                        oldBalance: this.balance,
                        balance: this.balance,
                        oldBalanceSub: this.balanceSub,
                        balanceSub: this.balanceSub,
                        maxBalance: this.balance + this.balanceSub
                    });
                    this.isSubmit = false;
                    this.isChangeOk = true;
                } else {
                    // reupdate
                    this.$alertError('Có lỗi khi chuyển ví. Vui lòng thử lại');
                    const res = await this.$userApi.getWallet();
                    if (res.status === 'OK') {
                        this.balance = res.data.balance;
                        this.oldBalance = res.data.balance;
                        this.balanceSub = res.data.balanceSub;
                        this.oldBalanceSub = res.data.balanceSub;
                        this.maxBalance = res.data.balance + res.data.balanceSub;
                    }
                }
            }
            this.isSubmit = false;
        },
        checkvalue(value) {
            if (value > this.maxBalance) {
                value = this.maxBalance;
            }
            this.balance = value;
            this.$refs.balanceInput.value = value;
            this.balanceSub = this.maxBalance - this.balance;
        },
        goTo(url) {
            this.$bvModal.hide('tranferWalletModal');
            this.$router.push(url);
        },
        resetStatus(e) {
            if (e.target.className === 'close' || e.target.className === 'modal fade') {
                this.isChangeOk = false;
            }
        }
    },
    mounted() {
        window.addEventListener('click', this.resetStatus);
        document.addEventListener('touch', this.resetStatus);
    }
};
</script>
