export const state = () => ({
    summaryWinner: {
        listWinner: []
    },
    summary: {
        listSummary: [],
        currentUser: null
    },
    history: {
        page: 1,
        total: 0,
        totalPage: 0,
        formData: {
            page: 1,
            limit: 8
        },
        winnerBetHistory: []
    },
    wheelEvent: {
        userTurn: null,
        dataWheel: null,
        dataRewards: null
    },
    rewardList: []
});

export const mutations = {
    mutate(state, payload) {
        let data = payload.with;
        if (typeof payload.with === 'object' && payload.with !== null) data = { ...state[payload.property], ...payload.with };
        if (Array.isArray(payload.with)) data = payload.with;

        state[payload.property] = data;
    },
    mutateSummaryWinner(state, payload) {
        state.summaryWinner[payload.property] = payload.with;
    },
    mutateSummary(state, payload) {
        state.summary[payload.property] = payload.with;
    },
    mutateTurns(state, payload) {
        state.wheelEvent[payload.property] = payload.with;
    },
    mutateHistory(state, payload) {
        state.history[payload.property] = payload.with;
    }
};

export const actions = {
    async getDailyWinner({ commit }) {
        const result = await this.$worldCupWheelApi.dailyWinner(30);
        if (result.status === 'OK') {
            commit('mutateSummaryWinner', { property: 'listWinner', with: result.data.rows });
        }
    },
    async getTopMedal({ commit }) {
        const result = await this.$worldCupWheelApi.getTopMedal();
        if (result.status === 'OK') {
            commit('mutateSummary', { property: 'listSummary', with: result.data.results });
            commit('mutateSummary', { property: 'currentUserLogin', with: result.data.currentUser || null });
        }
    },
    async getDataTurns({ commit }) {
        const result = await this.$worldCupWheelApi.userTurn();
        if (result.status === 'OK') {
            commit('mutateTurns', { property: 'userTurn', with: result.data.turnLeft });
        }
    },
    async getRollTurns({ commit }, payload) {
        const result = await this.$worldCupWheelApi.roll(payload);
        if (result.status === 'OK') {
            commit('mutateTurns', { property: 'dataWheel', with: result.data });
        }
    },
    async getRewards({ commit }) {
        const result = await this.$worldCupWheelApi.getRewards();
        if (result.status === 'OK') {
            commit('mutateTurns', { property: 'dataRewards', with: result.data });
        }
    },
    async getUserHistoryWheel({ state, commit }, payload) {
        const { page, username } = payload;
        const limit = state.history.formData.limit;
        commit('mutateHistory', {
            property: 'formData',
            with: {
                page,
                limit
            }
        });
        const result = await this.$worldCupWheelApi.userHistory({ ...state.history.formData, username });
        if (result.status === 'OK') {
            commit('mutateHistory', { property: 'page', with: page });
            commit('mutateHistory', { property: 'total', with: result.data.totalItems });
            commit('mutateHistory', { property: 'totalPage', with: result.data.totalPages });
            commit('mutateHistory', { property: 'winnerBetHistory', with: result.data.items });
        }
    },
    async getRewardList({ commit }) {
        const response = await this.$worldCupWheelApi.rewardList();
        if (response.status === 'OK') {
            commit('mutate', {
                property: 'rewardList',
                with: response.data
            });
        }
    }
};
