// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import money from 'v-money';
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/vi';
import MaskedInput from 'vue-text-mask';
import VueScrollTo from 'vue-scrollto';
import {
    numberWithCommas
} from '../helper/index.js';
// eslint-disable-next-line import/no-unresolved
import DImg from '~/components/image.vue';
// eslint-disable-next-line import/no-unresolved
import DButton from '~/components/button.vue';
// eslint-disable-next-line import/no-unresolved
import SImg from '~/components/simage.vue';

dayjs.extend(relativeTime);
dayjs.locale('vi');

// register directive v-money and component <money>
Vue.use(money, {
    precision: 0,
    thousands: '.',
    // suffix: ' DVN',
    masked: false /* doesn't work with directive */
});
Vue.use(Vuelidate);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueScrollTo);
// You can also pass in the default options
Vue.use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});
Vue.component('d-img', DImg);
Vue.component('s-img', SImg);
Vue.component('d-button', DButton);
Vue.component('m-input', MaskedInput);
Vue.filter('fromNow', (value) => {
    if (!value) return '';
    return dayjs().to(dayjs(value));
});
Vue.filter('formatDate', (value, format) => {
    if (!value) return '';
    return dayjs(value).format(format);
});
Vue.filter('addDay', (value, number) => {
    if (!value) return dayjs();
    return dayjs(value).add(number, 'day');
});
Vue.filter('formatTime', (value, format) => {
    if (!value) return '';
    return dayjs(value).format(format);
});
Vue.filter('formatAmount', (value, suffix) => {
    value = Math.round(value);
    if (!suffix) suffix = 'D';
    return `${numberWithCommas(value)} ${suffix}`;
});
Vue.filter('formatCurrency', (value, suffix) => {
    value = Math.round(value);
    if (!suffix) suffix = '';
    return `${numberWithCommas(value)} ${suffix}`;
});
Vue.filter('lowerCase', (value) => {
    if (!value) return '';
    return value.toLowerCase();
});
Vue.filter('currencyCode', (value) => `${Math.round(value)}`);
Vue.filter('currencyDecimal', (value) => `${value % 1 !== 0 ? parseFloat(value).toFixed(2) : Math.round(value)} D`);
Vue.filter('fomatPhone', (value) => `${value.replace(/(\d{4})(\d{2})(\d{4})/, '$1.$2.$3')}`);
Vue.filter('formatNumberBank', (value) => value.slice(0, value.length - 4).replace(/[0-9]/g, '*') + value.slice(value.length - 4, value.length));