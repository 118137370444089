<template>
    <button @click="apply" :disabled="disabled && !loading" class="btn-submit" :class="{'loading': loading}">
        <span v-if="loading" class="spinner-border-custom" role="status" aria-hidden="true"></span>
        <slot/>
    </button>
</template>
<script>
export default {
    inheritAttrs: true,
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        apply: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>
