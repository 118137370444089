export const state = () => ({
    summaryWinner: {
        page: 1,
        total: 0,
        totalPage: 0,
        formData: {
            page: 1,
            limit: 10
        },
        listWinner: []
    },
    summary: {
        page: 1,
        total: 0,
        totalPage: 0,
        formData: {
            page: 1,
            limit: 10
        },
        listSummary: [],
        currentUser: null
    },
    history: {
        page: 1,
        total: 0,
        totalPage: 0,
        formData: {
            page: 1,
            limit: 10
        },
        winnerBetHistory: []
    },
    rewardList: []
});

export const mutations = {
    mutate(state, payload) {
        let data = payload.with;
        if (typeof payload.with === 'object' && payload.with !== null) data = { ...state[payload.property], ...payload.with };
        if (Array.isArray(payload.with)) data = payload.with;

        state[payload.property] = data;
    },
    mutateSummaryWinner(state, payload) {
        state.summaryWinner[payload.property] = payload.with;
    },
    mutateSummary(state, payload) {
        state.summary[payload.property] = payload.with;
    },
    mutateHistory(state, payload) {
        state.history[payload.property] = payload.with;
    }
};

export const actions = {
    async getDailyWinner({ state, commit }, page) {
        const limit = state.summaryWinner.formData.limit;
        commit('mutateSummaryWinner', {
            property: 'formData',
            with: {
                page,
                limit
            }
        });
        const result = await this.$seaGameApi.dailyWinner({ ...state.summaryWinner.formData });
        if (result.status === 'OK') {
            commit('mutateSummaryWinner', { property: 'page', with: page });
            commit('mutateSummaryWinner', { property: 'total', with: result.data.totalItems });
            commit('mutateSummaryWinner', { property: 'totalPage', with: result.data.totalPages });
            commit('mutateSummaryWinner', { property: 'listWinner', with: result.data.items });
        }
    },
    async getTopMedal({ state, commit }, payload) {
        const { page, username } = payload;
        const limit = state.summary.formData.limit;
        commit('mutateSummary', {
            property: 'formData',
            with: {
                page,
                limit
            }
        });
        const result = await this.$seaGameApi.topMedal({ ...state.summary.formData, username });
        if (result.status === 'OK') {
            commit('mutateSummary', { property: 'page', with: page });
            commit('mutateSummary', { property: 'total', with: result.data.totalItems });
            commit('mutateSummary', { property: 'totalPage', with: result.data.totalPages });
            commit('mutateSummary', { property: 'listSummary', with: result.data.items });
            commit('mutateSummary', { property: 'currentUserLogin', with: result.data.currentUser || null });
        }
    },
    async getUserHistoryWheel({ state, commit }, payload) {
        const { page, username } = payload;
        const limit = state.history.formData.limit;
        commit('mutateHistory', {
            property: 'formData',
            with: {
                page,
                limit
            }
        });
        const result = await this.$seaGameApi.userHistoryWheel({ ...state.history.formData, username });
        if (result.status === 'OK') {
            commit('mutateHistory', { property: 'page', with: page });
            commit('mutateHistory', { property: 'total', with: result.data.totalItems });
            commit('mutateHistory', { property: 'totalPage', with: result.data.totalPages });
            commit('mutateHistory', { property: 'winnerBetHistory', with: result.data.items });
        }
    },
    async getRewardList({ commit }) {
        const response = await this.$seaGameApi.rewardList();
        if (response.status === 'OK') {
            commit('mutate', {
                property: 'rewardList',
                with: response.data
            });
        }
    }
};
