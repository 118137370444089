<template>
  <b-modal id="eventsRedirectModal" centered hide-header hide-footer no-close-on-backdrop>
    <div class="events-redirect">
      <div class="events-redirect__close" @click="onClose">
        <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/mobile/icon-close.svg`" alt="Close"/>
      </div>
      <div class="events-redirect__body">
        <div id="eventsWCRedirect" class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in items" :key="item.link">
              <img class="events-redirect__img" :src="`${$store.state.mapDomain.STATIC_URL + item.img}`" alt="event world cup" />
            </div>
          </div>
          <div id="eventsWCRedirect-pagination" class="events-redirect-pagination swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="events-redirect__bottom">
      <a href="javascript:void(0)" @click="goToLink" class="events-redirect__link btn-submit button">THAM GIA NGAY</a>
    </div>
  </b-modal>
</template>
<script>
export default {
    data() {
        return {
            items: [
                {
                    link: '/goal-scorer',
                    img: '/assets/img/event/worldcup/event-redirect-goal-2.jpg?v=1'
                },
                {
                    link: '/worldcup',
                    img: '/assets/img/event/worldcup/event-redirect.jpg?v=1'
                }

            ],
            itemActive: 0
        };
    },
    mounted() {
        if (process.env.EVENT_POPUP && process.env.EVENT_POPUP === '1') {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.showPopup();
                    this.initSlider();
                }, 2000);
            });
        }
    },
    methods: {
        initSlider() {
            const self = this;
            setTimeout(() => {
                const swiper = this.$swiper('#eventsWCRedirect', {
                    allowTouchMove: true,
                    slidesPerView: 1,
                    autoplay: {
                        delay: 6000
                    },
                    watchOverflow: true,
                    pagination: {
                        el: '#eventsWCRedirect-pagination',
                        clickable: true
                    }
                });
                swiper.on('slideChange', () => {
                    console.log('slide changed');
                    self.itemActive = swiper.realIndex;
                });
            }, 1000);
        },
        goToLink() {
            const { itemActive, items } = this;
            const link = items[itemActive].link || '/worldcup';
            this.$router.push(link);
            this.onClose();
        },
        onClose() {
            localStorage.setItem('last-time-turn-off-eventsRedirectModal', (new Date()).getDate());
            this.$bvModal.hide('eventsRedirectModal');
        },
        async showPopup() {
            const currentTime = (new Date()).getDate();
            const lastTime = Number(localStorage.getItem('last-time-turn-off-eventsRedirectModal'));
            if (lastTime === null || lastTime !== currentTime) {
                this.$bvModal.show('eventsRedirectModal');
            }
        }
    }
};
</script>

<style scoped lang="scss">
  ::v-deep {
    .modal {
      &-dialog {
        max-width: 450px;
      }
      &-body {
        padding: 0;
      }
    }
  }
  .events-redirect {
    position: relative;
    overflow: hidden;
    &__close {
      position: absolute;
      right: 16px;
      top: 16px;
      background: #000000;
      opacity: 0.6;
      border-radius: 50%;
      cursor: pointer;
      z-index: 100;
      img {
        width: 25px;
        height: 25px;
      }
    }
    &__img {
      width: 100%;
      max-width: 450px;
    }
    &__bottom {
      background: radial-gradient(37.56% 50% at 50% 0%, rgba(255, 244, 217, 0.128) 0%, rgba(255, 244, 217, 0) 100%), linear-gradient(90deg, rgba(184, 153, 72, 0) 8.42%, rgba(184, 153, 72, 0.2) 50.18%, rgba(184, 153, 72, 0) 88.6%);
      background-blend-mode: color-dodge, normal;
      padding: 23px 0;
    }
    &__link {
      max-width: 169px;
    }
    ::v-deep {
      .events-redirect-pagination {
        width: auto !important;
        min-width: 68px;
        padding: 0 10px;
        height: 17px;
        margin: 0 auto;
        left: 50% !important;
        transform: translateX(-50%);
        bottom: 0 !important;
        border-radius: 8px 8px 0 0;
        .swiper-pagination-bullet {
          margin: 0 4px 4px;
          width: 4px;
          height: 4px;
        }
        .swiper-pagination-bullet-active {
          width: 18px;
          height: 4px;
          background: #DCC27E;
          border-radius: 30px;
        }
      }
    }
  }
</style>
