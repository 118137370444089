<template>
    <ul class="pagination pagination-custom">
        <li @click.prevent="loadPage(page-1)" v-if="page > 1" class="page-item">
            <a class="page-link icon" href="#" aria-label="Previous">
                <span aria-hidden="true">
                    <img src="~/assets/img/icon/icon-prev.svg" alt="next">
                </span>
            </a>
        </li>
        <li v-else class="page-item disable">
            <a class="page-link icon" aria-label="Previous">
                <span aria-hidden="true">
                    <img src="~/assets/img/icon/icon-prev.svg" alt="next">
                </span>
            </a>
        </li>
        <li @click.prevent="loadPage(1)" v-if="page > this.marginPage + 2" class="page-item">
            <a class="page-link" href="#">1</a>
        </li>
        <li v-if="page > this.marginPage + 3" class="dot">
            <span>. . .</span>
        </li>
        <li v-for="item in pages" :key="item"
            @click.prevent="loadPage(item)"
            class="page-item" :class="{active : item == page}">
            <a class="page-link" href="#">{{item}}</a>
        </li>
        <li v-if="page < totalPage - (this.marginPage + 1)" class="dot">
            <span>. . .</span>
        </li>
        <li @click.prevent="loadPage(totalPage)" v-if="page < totalPage - this.marginPage" class="page-item">
            <a class="page-link" href="#">{{totalPage}}</a>
        </li>
        <li @click.prevent="loadPage(page+1)" v-if="page < totalPage" class="page-item">
            <a class="page-link icon" href="#" aria-label="Next">
                <span aria-hidden="true">
                    <img src="~/assets/img/icon/icon-next.svg" alt="next">
                </span>
            </a>
        </li>
        <li v-else class="page-item disable">
            <a class="page-link icon" aria-label="Next">
                <span aria-hidden="true">
                    <img src="~/assets/img/icon/icon-next.svg" alt="next">
                </span>
            </a>
        </li>
    </ul>
</template>
<script>
export default {
    components: {
    },
    props: ['page', 'totalPage', 'handlerPage'],
    head() {
        return {};
    },
    data() {
        return {
            marginPage: 1,
            pages: []
        };
    },
    computed: {
    },
    watch: {
        page() {
            // this.$emit('input', val);
            this.setPage();
        },
        totalPage() {
            this.setPage();
        }
    },
    methods: {
        setPage() {
            let startPage = this.page - this.marginPage;
            startPage = startPage < 1 ? 1 : startPage;

            let endPage = this.page + this.marginPage;
            endPage = endPage > this.totalPage ? this.totalPage : endPage;

            this.pages = [];
            for (let i = startPage; i <= endPage; i++) {
                this.pages[i - startPage] = i;
            }
        },
        loadPage(val) {
            this.handlerPage(val);
        }
    },
    mounted() {
        this.$nextTick(async () => {
            this.setPage();
        });
    }
};
</script>
