<template>
    <img :src="fullSrc">
</template>
<script>
export default {
    name: 'sImg',
    props: ['src'],
    data() {
        return {
            fullSrc:
            // eslint-disable-next-line no-nested-ternary
            (!(/^http(s?):\/\//.test(this.src))
                && !(/^\/\//.test(this.src)) ? `${this.$store.state.mapDomain.IMAGE_URL}${this.src}` : this.src)
        };
    },
    mounted() {
    }
};
</script>
