<template>
    <div class="menu-mobile">
        <div class="menu-mobile-list flex-box">
            <a @click.passive="openMenu" class="menu-mobile-item border-y toggle-menu" href="javascript:void(0)">
                <img  src="/assets/img/mobile/menu-home.svg" alt="home"/>
                <span>Menu</span>
            </a>
            <n-link class="menu-mobile-item border-y" to="/the-thao" :class="{'active': currentTab === 'sport'}">
                <img src="/assets/img/mobile/menu-sport.svg" alt="sport"/>
                <span>Thể thao</span>
            </n-link>
            <a href="javascript:void(0)" v-if="$isEnableThemeWorldcup()" @click="wcPopupActive = !wcPopupActive" class="menu-mobile-item border-y" :class="{'active': currentTab === 'worldcup'}">
                <img src="/assets/img/mobile/icon-worldcup.png" alt="worldcup"/>
                <span>Event</span>
                <p class="menu-note hot animation">
                  <s-img src="/assets/img/header/icon-hot.png" alt="hot"/>
                </p>
            </a>
            <n-link  v-if="this.user === null && !$isEnableThemeWorldcup()" class="menu-mobile-item border-y" to="/huong-dan" :class="{'active': currentTab === 'huong-dan'}">
                <img src="/assets/img/mobile/menu-guide.png" alt="guide"/>
                <span>Hướng dẫn</span>
            </n-link>
            <n-link v-if="this.user !== null" class="menu-mobile-item border-y" to="/user/deposit?tab=index" :class="{'active': currentTab === 'deposit'}">
                <img src="/assets/img/mobile/menu-deposit.png" alt="deposit"/>
                <span>Nạp tiền</span>
            </n-link>
            <template>
                <n-link v-if="this.user === null && $isEnableThemeWorldcup()" class="menu-mobile-item border-y 11" to="/khuyen-mai" :class="{'active': currentTab === 'saleoff'}">
                    <img src="/assets/img/mobile/menu-saleoff.svg" alt="saleoff"/>
                    <span>Khuyến mãi</span>
                </n-link>
                <n-link v-if="!$isEnableThemeWorldcup()" class="menu-mobile-item border-y 22" to="/khuyen-mai" :class="{'active': currentTab === 'saleoff'}">
                    <img src="/assets/img/mobile/menu-saleoff.svg" alt="saleoff"/>
                    <span>Khuyến mãi</span>
                </n-link>
            </template>
            <a @click.prevent="openLiveChat" class="menu-mobile-item border-y" href="#">
                <img src="/assets/img/mobile/menu-chat.svg" alt="live chat"/>
                <span>Live chat</span>
            </a>
            <div :class="['menu-mobile__world-cup', wcPopupActive ? 'active' : '']" v-if="$isEnableThemeWorldcup()">
            <n-link to="/goal-scorer" class="menu-mobile__world-cup__item">
              <img class="menu-mobile__world-cup__item__img img-soccer" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/worldcup/wc-soccer-bar.png`" />
              <p class="menu-mobile__world-cup__item__text">săn thẻ<br/> đổi thưởng</p>
            </n-link>
            <n-link to="/worldcup" class="menu-mobile__world-cup__item">
              <img class="menu-mobile__world-cup__item__img" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/worldcup/wc-wheel-bar.png`" />
              <p class="menu-mobile__world-cup__item__text">Vòng quay<br/> Qatar</p>
            </n-link>
          </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';

export default {
    data() {
        return {
            wcPopupActive: false
        };
    },
    computed: {
        livechatMaintain() {
            return process.env.LIVECHAT_MAINTAIN;
        },
        user() {
            return this.$store.state.user;
        },
        currentTab() {
            return this.$store.getters.getCurrentTab;
        }
    },
    methods: {
        showLiveChatMaintain() {
            this.$showPopupById('livechatMaintain');
        },
        openMenu() {
            $('body').toggleClass('off-canvas-active');
        },
        async openTranferWallet() {
            this.$bvModal.show('tranferWalletModal');
            try {
                const res = await this.$userApi.getWallet();
                if (res.status === 'OK') {
                    this.$store.dispatch('updateWallet', {
                        oldBalance: res.data.balance,
                        balance: res.data.balance,
                        oldBalanceSub: res.data.balanceSub,
                        balanceSub: res.data.balanceSub,
                        maxBalance: res.data.balanceAll
                    });
                }
            } catch (error) {
                this.$alertError(error.message);
            }
        },
        openLiveChat() {
            if (this.livechatMaintain === 1) {
                this.$showPopupById('livechatMaintain');
            } else {
                this.$openLiveChat();
            }
        }
    }
};
</script>
