export const state = () => ({
    goalScorer: {
        dataScorer: null,
        dataUserTurns: null,
        dataLogoTeam: null,
        dataSchedule: null
    },
    summaryWinner: {
        listWinner: []
    },
    goalEvent: {
        userTurn: null,
        dataWheel: null,
        dataRewards: null
    },
    rewardList: [],
    topGoalscorer: {
        listTopGoalscorer: [],
        currentUser: null
    },
    goldenShoes: {
        page: 1,
        total: 0,
        totalPage: 0,
        formData: {
            page: 1,
            limit: 10
        },
        listGoldenShoes: [],
        currentUser: null
    },
    listPrize: {
        page: 1,
        total: 0,
        totalPage: 0,
        formData: {
            page: 1,
            limit: 10
        },
        listPrize: [],
        currentUser: null
    },
    history: {
        page: 1,
        total: 0,
        totalPage: 0,
        formData: {
            page: 1,
            limit: 10
        },
        listHistoryUser: [],
        currentUser: null
    }
});

export const mutations = {
    mutateScorer(state, payload) {
        state.goalScorer[payload.property] = payload.with;
    },
    mutateSummaryWinner(state, payload) {
        state.summaryWinner[payload.property] = payload.with;
    },
    mutateTurns(state, payload) {
        state.goalEvent[payload.property] = payload.with;
    },
    mutateTopGoalscorer(state, payload) {
        state.topGoalscorer[payload.property] = payload.with;
    },
    mutateGoldenShoes(state, payload) {
        state.goldenShoes[payload.property] = payload.with;
    },
    mutateListPrize(state, payload) {
        state.listPrize[payload.property] = payload.with;
    },
    mutateHistoryUser(state, payload) {
        state.history[payload.property] = payload.with;
    }
};

export const actions = {
    async getDataScorer({ commit }, payload) {
        const result = await this.$worldCupGoalApi.roll(payload.turn, payload.listPlayer);
        if (result.status === 'OK') {
            commit('mutateScorer', { property: 'dataScorer', with: result.data });
        }
    },
    async getDataUserTurns({ commit }) {
        const result = await this.$worldCupGoalApi.userTurn();
        if (result.status === 'OK') {
            commit('mutateScorer', { property: 'dataUserTurns', with: result.data });
        }
    },
    async getListLogoTeam({ commit }, payload) {
        const result = await this.$worldCupGoalApi.getListLogoTeam(payload.logo, payload.offset, payload.limit);
        if (result.status === 'OK') {
            commit('mutateScorer', { property: 'dataLogoTeam', with: result.data });
        }
    },
    async getDailyWinner({ commit }) {
        const result = await this.$worldCupGoalApi.dailyWinner();
        if (result.status === 'OK') {
            commit('mutateSummaryWinner', { property: 'listWinner', with: result.data.items });
        }
    },
    async getRewards({ commit }) {
        const result = await this.$worldCupGoalApi.getRewards();
        if (result.status === 'OK') {
            commit('mutateTurns', { property: 'dataRewards', with: result.data });
        }
    },
    async getTopGoalscorer({ commit }) {
        const result = await this.$worldCupGoalApi.getListTopGoalScore();
        if (result.status === 'OK') {
            commit('mutateTopGoalscorer', { property: 'listTopGoalscorer', with: result.data.ranks });
            commit('mutateTopGoalscorer', { property: 'currentUser', with: result.data.currentUser || null });
        }
    },
    async getSchedule({ commit }, payload) {
        const result = await this.$worldCupGoalApi.getSchedule(payload);
        if (result.status === 'OK') {
            commit('mutateScorer', { property: 'dataSchedule', with: result.data });
        }
    },
    async getGoldenShoes({ commit }) {
        const result = await this.$worldCupGoalApi.getListTopGoal();
        if (result.status === 'OK') {
            commit('mutateGoldenShoes', { property: 'listGoldenShoes', with: result.data.ranks });
            commit('mutateGoldenShoes', { property: 'currentUser', with: result.data.currentUser || null });
        }
    },
    async getListPrize({ commit }, payload) {
        const { page, limit } = payload;
        commit('mutateListPrize', {
            property: 'formData',
            with: {
                page,
                limit
            }
        });
        const result = await this.$worldCupGoalApi.dailyWinner(page, limit);
        if (result.status === 'OK') {
            commit('mutateListPrize', { property: 'page', with: page });
            commit('mutateListPrize', { property: 'limit', with: limit });
            commit('mutateListPrize', { property: 'total', with: result.data.totalItems });
            commit('mutateListPrize', { property: 'totalPage', with: result.data.totalPages });
            commit('mutateListPrize', { property: 'listPrize', with: result.data.items });
        }
    },
    async getHistoryUser({ commit }, payload) {
        const { page, limit } = payload;
        commit('mutateHistoryUser', {
            property: 'formData',
            with: {
                page,
                limit
            }
        });
        const result = await this.$worldCupGoalApi.userHistory(page, limit);
        if (result.status === 'OK') {
            commit('mutateHistoryUser', { property: 'page', with: page });
            commit('mutateHistoryUser', { property: 'limit', with: limit });
            commit('mutateHistoryUser', { property: 'total', with: result.data.totalItems });
            commit('mutateHistoryUser', { property: 'totalPage', with: result.data.totalPages });
            commit('mutateHistoryUser', { property: 'listHistoryUser', with: result.data.items });
        }
    }
};
