<template>
    <div :id="id" class="swiper-container banner-event">
        <div class="swiper-wrapper">
            <div v-for="(item, index) in listBanner" :key="index" class="swiper-slide">
                <a href="javascript:void(0)" @click="moveTo(item)">
                    <img :src="$device.isDesktop ? item.img : item.imgMB">
                </a>
            </div>
        </div>
        <div :id="`${id}-pagination`" class="banner-event-pagination swiper-pagination"></div>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            listBanner: [
                {
                    img: '/assets/img/event/banner-event-goal-soccer.jpg',
                    imgMB: '/assets/img/event/banner-event-goal-soccer-mb.jpg',
                    link: '/goal-scorer'
                },
                {
                    img: '/assets/img/event/banner-event-wheel.jpg',
                    imgMB: '/assets/img/event/banner-event-wheel-mb.jpg',
                    link: '/worldcup'
                }
            ]
        };
    },
    methods: {
        initSlider() {
            setTimeout(() => {
                this.$swiper(`#${this.id}`, {
                    slidesPerView: 1,
                    autoplay: true,
                    watchOverflow: true,
                    pagination: {
                        el: `#${this.id}-pagination`,
                        clickable: true
                    }
                });
            }, 1500);
        },
        moveTo(item) {
            this.$bvModal.hide('loginModal');
            this.$bvModal.hide('registerModal');
            this.$router.push(item.link);
        }
    },
    mounted() {
        this.$nextTick(async () => {
            this.initSlider();
        });
    }
};
</script>
