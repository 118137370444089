<template>
    <b-modal
            id="TotalSlotsModal"
            dialog-class="modal-dialog-event"
            :content-class="['modal-seagame modal-seagame--total']"
            hide-footer
            ok-only
            no-stacking
            centered
            @show="getSeaGameGoldMedal"
    >
        <div class="modal-wrapper">
            <div class="modal-inner text-center">
                <div class="total-slots">
                    <div class="title">SỐ HUY CHƯƠNG VÀNG<br>
                        ĐỘI TUYỂN VIỆT NAM HIỆN CÓ</div>
                    <div class="amount">{{seaGameGoldMedal}} HCV</div>
                    <div class="total-slots__amount">
                        <div class="thumb">
                            <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/seagame/modal/slots.png`" alt="slots"/>
                        </div>
                        <div class="content">
                            <div class="label">TỔNG HŨ HIỆN TẠI</div>
                            <div class="money">{{ totalSlot | formatAmount('VND') }} </div>
                        </div>
                    </div>
                    <div class="note seagame__desktop">
                        Tổng giải thưởng sẽ được tính bằng <span>( Tổng huy chương vàng đội tuyển Việt Nam nhân 5.000.000 VND).</span> Phần thưởng được chia đều cho 10 người có số điểm sưu tầm cao nhất.
                    </div>
                    <div class="note seagame__mobile">
                        (Chia đều tiền <span>(TIỀN HŨ)</span> cho 10 người có
                        số điểm  sưu tầm huy chương cao nhất)
                    </div>
                </div>
                <div class="modal-inner__button seagame__desktop">
                    <a
                            @click="$bvModal.hide('TotalSlotsModal')"
                            data-toggle="modal"
                            href="javascript:void(0)"
                            class="button"
                    >
                        QUAY TIẾP
                    </a>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    data() {
        return {
            seaGameGoldMedal: 0,
            slot: 5000000
        };
    },
    computed: {
        totalSlot() {
            return this.seaGameGoldMedal * this.slot;
        }
    },
    mounted() {
        this.$nextTick(async () => {
            await this.getSeaGameGoldMedal();
        });
    },
    methods: {
        async getSeaGameGoldMedal() {
            try {
                const res = await this.$seaGameApi.getSeaGameGoldMedal();
                if (res && res.Data) {
                    const vietnam = res.Data.find(item => item.Contingent === 'Vietnam');
                    if (vietnam) {
                        this.seaGameGoldMedal = vietnam.Gold || 0;
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
};
</script>
