const apiVersion = '/api/v1';

export default ($axios) => ({
    index() {
        return $axios.$get(`${apiVersion}/payment/indexdeposit`);
    },
    cards() {
        return $axios.$get(`${apiVersion}/payment/gwinfo`);
    },
    bank(bankCode) {
        return $axios.$get(`${apiVersion}/payment/bankHistory`, {
            params: {
                bank_code: bankCode,
                progress: false
            }
        });
    },
    create(type, payload) {
        if (type === 'bank') {
            return $axios.$post(`${apiVersion}/payment/depositbank`, payload, { progress: false });
        }
        if (type === 'card') {
            return $axios.$post(`${apiVersion}/payment/depositcard`, payload, { progress: false });
        }
        if (type === 'fastpay') {
            return $axios.$post(`${apiVersion}/payment/smartpay`, payload, { progress: false });
        }
    },
    cancelPromotion() {
        return $axios.$post(`${apiVersion}/payment/cancelpromotion`, {}, { progress: false });
    },
    momos() {
        return $axios.$get(`${apiVersion}/payment/momoAccount`);
    },
    cryptoPay(payload) {
        return $axios.$post(`${apiVersion}/payment/cryptopay`, payload, { progress: false });
    },
    cryptocheck() {
        return $axios.$get(`${apiVersion}/payment/cryptocheck`);
    },
    cancelcrypto(payload) {
        return $axios.$post(`${apiVersion}/payment/cancelcrypto`, payload, { progress: false });
    },
    codeMomo() {
        return $axios.$get(`${apiVersion}/user/momo/code`);
    },
    codeFastPay() {
        return $axios.$get(`${apiVersion}/user/codepay`);
    },
    maintenanceBanks() {
        return $axios.$get(`${apiVersion}/payment/bank/schedule`);
    }
});
