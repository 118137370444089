/* eslint-disable import/no-extraneous-dependencies */
import Swiper from 'swiper';
import Swal from 'sweetalert2/dist/sweetalert2';
import anime from 'animejs/lib/anime.es';
import 'sweetalert2/src/sweetalert2.scss';

export default ({
    store, app, route
}, inject) => {
    const swiper = (ele, options) => new Swiper(ele, options);
    const isMobile = () => store.state.device === 'mobile';
    const isDesktop = () => store.state.device === 'desktop';
    const LINK_TYPE = {
        CASINO: 'CASINO',
        GAME: 'GAME',
        APP: 'APP',
        API: 'API',
        PAGE: 'PAGE',
        FUNC: 'FUNC',
        MATCH: 'MATCH'
    };
    const PARTNER_GAME_ID = {
        QS_XENGFRUIT: 'qs_xengfruit'
    };
    const GAME_DISPLAY_TYPE = {
        FEATURED: 1,
        TABLE: 2,
        SLOT: 3,
        OTHER: 4,
        INGAME: 5,
        LOTTERY: 7,
        GAME_BAI: 15,
        BATTLE: 16
    };
    // const GAME_SLOT_BLOCK_PROMOTION = [1954, 2008, 1960, 2006, 2003, 1998, 1983, 1977, 1990, 1949, 1967, 1961];
    const GAME_TABLEGAME_BLOCK_PROMOTION = [2013, 871, 872, 873, 874, 875, 876, 877];
    const GAME_PGSOFT_BLOCK_PROMOTION = 'pgsoft';
    const GAME_TYPE = {
        ASIA: 1,
        EURO: 2
    };
    const GAME_FISHING_BACK_LOBBY = ['ktf1999', 'ktf1990', 'ktf1988', 'ktf1992', 'ktf1993'];
    const GAME_INGAME_BACK_LOBBY = ['ktrng3999', 'ktrng3998', 'ktrng3997', 'ktrng3991', 'ktrng3989', 'ktrng3979', 'ktrng3986', 'ktrng3985', 'ktrng3988', 'ktc7994', 'ktrng3971', 'ktrng3970'];
    const GAME_BATTEL_BACK_LOBBY = ['ktc7993', 'ktc7989'];
    const PARTNER = {
        TECHPLAY: 'vingame',
        PLAYNGO: 'playngo'
    };
    const ALIAS_TYPE_GAME = {
        'tat-ca': 'GAMES HOT',
        'tro-choi-khac': 'GAMES KHÁC',
        'no-hu': 'NỔ HŨ',
        'no-hu-vegas': 'SLOTS',
        spribe: 'SPRIBE GAMES',
        'game-bai': 'GAME BÀI',
        'battle-game': 'BATTLE GAMES',
        'danh-bai': 'TABLE GAMES',
        evo: 'EVOLUTION GAMES',
        'ban-ca': 'BẮN CÁ',
        ingame: 'INGAME',
        'no-hu-18': 'NỔ HŨ 18+',
        'no-hu-vegas-18': 'SLOTS 18+',
        'quay-so': 'QUAY SỐ'
    };
    const LABEL_TYPE = {
        HOT: 'hot',
        NEW: 'new',
        LIVE: 'live',
        EVENT_WHEEL: 'event_wheel',
        EVENT_NOEL: 'event_noel',
        EVENT_HOLIDAY: 'event_holiday',
        EVENT_EURO: 'event_euro',
        EVENT_BIRTHDAY: 'event_birthday',
        LABEL_EVENT: 'event'
    };
    const isEnableEventWheel = () => process.env.EVENT_WHEEL === '1';
    const isEnableThemeNoel = () => process.env.EVENT_NOEL === '1';
    const isEnableThemeHoliday = () => process.env.EVENT_HOLIDAY === '1';
    const isEnableThemeEuro = () => process.env.EVENT_EURO === '1';
    const isEnableThemeOlympic = () => process.env.EVENT_OLYMPIC === '1';
    const isEnableThemeBirthday = () => process.env.EVENT_BIRTHDAY === '1';
    const isEnableThemeDaily = () => process.env.EVENT_DAILY === '1';
    const isEnableThemeSeagame = () => process.env.EVENT_SEAGAME === '1';
    const isEnableMergeWallet = () => process.env.ENABLE_SHOW_MERGE_WALLET === '1';
    const isEnableThemeWorldcup = () => process.env.EVENT_WORLDCUP === '1';
    const showById = (id) => {
        app.router.app.$root.$bvModal.show(id);
    };
    const hideById = (id) => {
        app.router.app.$root.$bvModal.hide(id);
    };
    const vueSwal = (options) => {
        Swal.fire(options);
    };
    const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });
    const alert = (msg) => Toast.fire({
        icon: 'success',
        title: msg
    });
    const alertUpdateUsernameSuccess = (msg, onClose) => Toast.fire({
        icon: 'success',
        title: msg,
        onAfterClose() {
            onClose();
        }
    });
    const alertLoading = (msg, time, onClose) => Toast.fire({
        icon: 'success',
        title: msg,
        timer: time,
        onAfterClose() {
            onClose();
        }
    });
    const alertError = (msg) => Toast.fire({
        icon: 'error',
        title: msg
    });
    const alertErrorTime = (msg, time) => Toast.fire({
        icon: 'error',
        title: msg,
        timer: time
    });
    const alertHtmlErrorLogin = (options) => Swal.fire({
        title: 'LỖI ĐĂNG NHẬP',
        html: '<img class="margin-bottom-20" src="/assets/img/icon/warning-login.png">'
            + '<p class="margin-bottom-20">Tên đăng nhập hoặc mật khẩu không đúng !</p>',
        customClass: {
            container: 'alert alert--type-1 error-login'
        },
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Quên mật khẩu?',
        // animation: true
        ...options
    });
    const alertHtml = (title, html) => Swal.fire({
        title,
        html,
        customClass: {
            container: 'alert alert--type-1'
        },
        showCloseButton: true,
        showConfirmButton: false
        // animation: true
    });
    const alertHtmlLogin = (title, html) => Swal.fire({
        title,
        html,
        customClass: {
            container: 'alert alert--type-1'
        },
        showCloseButton: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        confirmButtonText: 'Đăng nhập lại',
        cancelButtonText: 'Bỏ qua',
        timer: 2000
        // animation: true
    });
    const alertHtmlConfirm = (title, html, options) => Swal.fire({
        title,
        html,
        customClass: {
            container: 'alert alert--type-1'
        },
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Bỏ qua',
        // animation: true,
        ...options
    });
    const alertSystemMaintain = () => Toast.fire({
        icon: 'error',
        title: 'Hệ thống đang bảo trì. Vui lòng quay lại sau'
    });
    const alertLoginRequired = () => {
        showById('loginModal');
    };

    const hasPromotion = () => {
        if (store.state.user && store.state.user.package_id && store.state.user.package_id !== 1) {
            return true;
        }
        return false;
    };
    inject('hasPromotion', hasPromotion);

    const playGame = (item, fullPath) => {
        // check lottery game
        if (item.display_types.includes(GAME_DISPLAY_TYPE.LOTTERY)
            && (item.partner_game_id === 'quaysothantai' || item.partner_game_id === 'quaysosexy' || item.partner_game_id === 'vinkeno' || item.partner_game_id === 'vinnumbersgame')) {
            if (store.state.device === 'desktop') {
                app.router.push(item.url);
            } else {
                window.open(item.url, '_blank');
            }
            return;
        }

        // NO LOGIN
        if (store.state.user == null) {
            alertLoginRequired();
            return;
        }

        if (item.partner_game_id === 'ktrng3995' || item.partner_game_id === 'kts6998' || GAME_TABLEGAME_BLOCK_PROMOTION.includes(item.id)) {
            if (store.state.user && store.state.user.package_id && store.state.user.package_id !== 1) {
                alertError('Khuyến mãi quý khách đang sử dụng không áp dụng cho game này. Liên hệ: Tư Vấn Viên để được hỗ trợ.');
                return;
            }
        }

        if (item.display_types.includes(GAME_DISPLAY_TYPE.BATTLE) && store.state.user) {
            if (store.state.user.package_id && store.state.user.package_id !== 1) {
                alertError('Khuyến mãi quý khách đang sử dụng không áp dụng cho thể loại game này. Liên hệ: Tư Vấn Viên để được hỗ trợ.');
                return;
            }
        }

        // check balance for not techplay game
        if (item.partner_provider !== PARTNER.TECHPLAY && store.state.user && store.state.user.balance === 0) {
            alertError('Vui lòng nạp tiền để tham gia trò chơi');
            return;
        }
        if (
            (item.display_types.includes(GAME_DISPLAY_TYPE.INGAME)
                || item.display_types.includes(GAME_DISPLAY_TYPE.GAME_BAI))
            && store.state.user) {
            // check promotion and withdraw
            if (store.state.user.package_id && store.state.user.package_id !== 1) {
                alertError('Khuyến mãi quý khách đang sử dụng không áp dụng cho thể loại game này. Liên hệ: Tư Vấn Viên để được hỗ trợ.');
                return;
            }
            if (store.state.invoicePromotion > 0
                && store.state.totalWithDraw === 0) {
                // da hoan thanh khuyen mai nhung chua rut tien
                alertError('Vui lòng thực hiện lệnh rút tiền trước khi chơi Game bài');
                return;
            }
        }
        // Check block game slot PGSoft
        if (item.display_types.includes(GAME_DISPLAY_TYPE.SLOT) && GAME_PGSOFT_BLOCK_PROMOTION.includes(item.partner_provider) && store.state.user) {
            // check promotion
            if (store.state.user.package_id && store.state.user.package_id !== 1) {
                alertErrorTime('Khuyến mãi quý khách đang sử dụng không áp dụng cho thể loại game này. Liên hệ: Tư Vấn Viên để được hỗ trợ.', 5000);
                return;
            }
        }
        let url = '';
        const newTab = true;
        // check mobile
        if (store.state.device === 'mobile') {
            // QS_XENGFRUIT
            if (item.partner_game_id === PARTNER_GAME_ID.QS_XENGFRUIT) {
                url = item.path + store.state.user.tp_token;
            } else if (item.partner_provider === PARTNER.TECHPLAY) {
                url = `/slot?gId=${item.partner_game_id}`;
                if (store.state.user) {
                    url += `&token=${store.state.user.tp_token}`;
                }
                if (fullPath) {
                    url += `&ru=${fullPath}`;
                }
            } else {
                url = item.url;
            }
        } else {
            // QS_XENGFRUIT
            // eslint-disable-next-line no-lonely-if
            if (item.partner_game_id === PARTNER_GAME_ID.QS_XENGFRUIT) {
                url = item.path + store.state.user.tp_token;
            } else if (item.partner_provider === PARTNER.TECHPLAY) {
                url = `/slot?gId=${item.partner_game_id}`;
                if (store.state.user) {
                    url += `&token=${store.state.user.tp_token}`;
                }
                if (fullPath) {
                    url += `&ru=${fullPath}`;
                }
            } else if (item.url) {
                if (item.partner_provider === PARTNER.PLAYNGO) {
                    url = `${store.state.mapDomain.GAME_URL}?url=${encodeURIComponent(item.url)}&location=asia`;
                } else {
                    url = item.url;
                }
            }
        }
        if (!url) {
            // url = `/game?partnerProvider=${item.partner_provider}&partnerGameId=${item.partner_game_id}`;
            url = fullPath
                ? `/api/playGame?partnerProvider=${item.partner_provider}&partnerGameId=${item.partner_game_id}&ru=${fullPath}`
                : `/api/playgame?partnerProvider=${item.partner_provider}&partnerGameId=${item.partner_game_id}`;
        }
        // NO LOGIN
        // if (store.state.user == null) {
        //     if (store.state.pwa === 1 && item.partner_provider === PARTNER.TECHPLAY) {
        //         newTab = false;
        //     }
        //     alertLoginRequired(url, newTab);
        //     return;
        // }
        // PWA
        if (store.state.pwa === 1) {
            if (item.partner_provider === PARTNER.TECHPLAY) {
                app.router.push(url);
            } else {
                app.router.push(`/iframe?url=${encodeURIComponent(url)}
                    &title=${encodeURIComponent(item.name)}&id=${item.partner_provider}&ru=/game`);
            }
            return;
        }
        if (item.maintain !== undefined && item.maintain === '1') {
            alertError('Game đang bảo trì. Vui lòng quay lại sau hoặc chọn game khác.');
        } else if (!newTab) {
            app.router.push(url);
        } else if (item.partner_game_id === 'soccerlottery') {
            window.open(url, '_blank', 'menubar=1,resizable=1,width=410,height=656');
        } else {
            window.open(url, '_blank');
        }
    };
    const playCasino = (item) => {
        if (store.state.user == null) {
            // const url = `/cs?partnerCode=${item.partnerCode}&code=${item.code}`;
            alertLoginRequired(null, 1);
            return;
        }
        if (!item.url) {
            alertSystemMaintain();
            return;
        }
        if (item.url === 'denyPlay') {
            alertError('Khuyến mãi quý khách đang sử dụng không áp dụng cho thể loại game này. Liên hệ: Tư Vấn Viên để được hỗ trợ.');
            return;
        }

        if (store.state.user.package_id && store.state.user.package_id !== 1) {
            alertError('Khuyến mãi quý khách đang sử dụng không áp dụng cho thể loại game này. Liên hệ: Tư Vấn Viên để được hỗ trợ.');
            return;
        }

        if (store.state.pwa === 1) {
            if (item.partnerCode === 'vivo-sexy' || item.partnerCode === 'vivo') {
                const title = 'Sòng bài Châu Âu';
                if (!app.$device.isIos || store.state.visitVivo) {
                    app.router.push(`/iframe?url=${encodeURIComponent(item.url)}&title=${encodeURIComponent(title)}&id=vivo&ru=/casino`);
                } else {
                    store.commit('mutate', {
                        property: 'visitVivo',
                        with: true
                    });
                    app.$cookies.set('visitVivo', true, {
                        path: '/',
                        maxAge: 60 * 60 * 24 * 30
                    });
                    // app.router.push(`/iframe?url=${encodeURIComponent(item.url)}&title=${encodeURIComponent(title)}&id=vivo&ru=/casino`);
                    window.open(item.url, '_blank');
                    // popup.addEventListener('load', () => {
                    //     popup.close();
                    //     app.router.push(`/iframe?url=${encodeURIComponent(item.url)}&title=${encodeURIComponent(title)}&id=vivo&ru=/casino`);
                    // }, true);
                }
            }
            if (item.partnerCode === 'hg') {
                const title = 'Sòng bài Châu Á';
                app.router.push(`/iframe?url=${encodeURIComponent(item.url)}&title=${encodeURIComponent(title)}&id=hg&ru=/casino`);
            }
            return;
        }
        window.open(item.url, '_blank');
    };
    const fixScrollTo = (ele, options) => {
        const iframeHeight = $('#iframe-content').length > 0 ? $('#iframe-content').height() : 0;
        app.$scrollTo(ele, {
            ...options,
            offset: options && options.offset ? -iframeHeight + options.offset : -iframeHeight
        });
    };
    const scrollToTop = (ele, options) => {
        const bodyHeight = $('body').length > 0 ? $('body').height() : 0;
        app.$scrollTo(ele, {
            ...options,
            offset: options && options.offset ? -bodyHeight + options.offset : -bodyHeight
        });
    };
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    };
    const openLink = (url, options) => {
        if (options.loginRequired && store.state.user == null) {
            alertLoginRequired(url);
            return;
        }
        if (!options.newTab) {
            app.router.push(url);
            return;
        }
        if (store.state.pwa === 1) {
            // skip esport
            if (app.$device.isIos && url === 'the-thao-chau-a') {
                if (!store.state.visitSbtech) {
                    store.commit('mutate', {
                        property: 'visitSbtech',
                        with: true
                    });
                    app.$cookies.set('visitSbtech', true, {
                        path: '/',
                        maxAge: 60 * 60 * 24
                    });
                    window.open(url, '_blank');
                    return;
                }
            }
            app.router.push(url);
            return;
        }
        window.open(url, '_blank');
    };
    const openGame = (url, options) => {
        if (options.loginRequired && store.state.user == null) {
            alertLoginRequired(url);
            return;
        }
        // GAME BAI
        if (options.type === GAME_DISPLAY_TYPE.INGAME) {
            if (store.state.user.package_id && store.state.user.package_id !== 1) {
                alertError('Khuyến mãi quý khách đang sử dụng không áp dụng cho thể loại game này. Liên hệ: Tư Vấn Viên để được hỗ trợ.');
                return;
            }
            if (store.state.invoicePromotion > 0
                && store.state.totalWithDraw === 0) {
                // da hoan thanh khuyen mai nhung chua rut tien
                alertError('Vui lòng thực hiện lệnh rút tiền trước khi chơi Game bài');
                return;
            }
        }
        if (!options.newTab || store.state.pwa === 1) {
            app.router.push(url);
            return;
        }
        window.open(url, '_blank');
    };
    const openLobby = (url) => {
        app.router.push(url);
    };
    const openGameSlot = (url) => {
        openGame(url, {
            loginRequired: true,
            newTab: true
        });
    };
    const openInGame = (url) => {
        openGame(url, {
            loginRequired: true,
            newTab: true,
            type: GAME_DISPLAY_TYPE.INGAME
        });
    };
    const openEsports = () => {
        const url = '/the-thao-so';
        const options = {
            loginRequired: true,
            newTab: true,
            hasBalance: true
        };
        if (options.loginRequired && store.state.user == null) {
            alertLoginRequired(url);
            return;
        }
        // if (options.hasBalance && store.state.user && store.state.user.balance === 0) {
        //     alert('Vui lòng nạp tiền để tham gia trò chơi');
        //     return;
        // }
        window.open(url, '_blank');
    };
    const opacity = (time, el) => {
        setTimeout(() => {
            $(el).css('opacity', 1);
        }, time);
    };
    const getRoute = (clientRoute) => clientRoute || route || {
        name: '',
        params: {
            slug: ''
        },
        fullPath: '',
        query: {
        }
    };

    inject('scrollToTop', scrollToTop);
    inject('shuffleArray', shuffleArray);
    inject('fixScrollTo', fixScrollTo);
    inject('openLink', openLink);
    inject('openGame', openGame);
    inject('openLobby', openLobby);
    inject('openGameSlot', openGameSlot);
    inject('openInGame', openInGame);
    inject('openEsports', openEsports);
    inject('opacity', opacity);
    inject('playCasino', playCasino);
    inject('Swal', Swal);
    inject('vueSwal', vueSwal);
    inject('alert', alert);
    inject('alertUpdateUsernameSuccess', alertUpdateUsernameSuccess);
    inject('alertLoading', alertLoading);
    inject('alertError', alertError);
    inject('alertErrorTime', alertErrorTime);
    inject('alertHtmlErrorLogin', alertHtmlErrorLogin);
    inject('alertHtml', alertHtml);
    inject('alertHtmlLogin', alertHtmlLogin);
    inject('alertHtmlConfirm', alertHtmlConfirm);
    inject('alertSystemMaintain', alertSystemMaintain);
    inject('alertLoginRequired', alertLoginRequired);
    inject('GAME_DISPLAY_TYPE', GAME_DISPLAY_TYPE);
    inject('GAME_FISHING_BACK_LOBBY', GAME_FISHING_BACK_LOBBY);
    inject('GAME_INGAME_BACK_LOBBY', GAME_INGAME_BACK_LOBBY);
    inject('GAME_BATTEL_BACK_LOBBY', GAME_BATTEL_BACK_LOBBY);
    inject('LINK_TYPE', LINK_TYPE);
    inject('LABEL_TYPE', LABEL_TYPE);
    inject('isEnableEventWheel', isEnableEventWheel);
    inject('isEnableThemeNoel', isEnableThemeNoel);
    inject('isEnableThemeHoliday', isEnableThemeHoliday);
    inject('isEnableThemeEuro', isEnableThemeEuro);
    inject('isEnableThemeOlympic', isEnableThemeOlympic);
    inject('isEnableThemeBirthday', isEnableThemeBirthday);
    inject('isEnableThemeDaily', isEnableThemeDaily);
    inject('isEnableThemeSeagame', isEnableThemeSeagame);
    inject('isEnableMergeWallet', isEnableMergeWallet);
    inject('isEnableThemeWorldcup', isEnableThemeWorldcup);
    inject('GAME_TYPE', GAME_TYPE);
    inject('PARTNER', PARTNER);
    inject('playGame', playGame);
    inject('showPopupById', showById);
    inject('hidePopupById', hideById);
    inject('swiper', swiper);
    inject('anime', anime);
    inject('isMobile', isMobile);
    inject('isDesktop', isDesktop);
    inject('ALIAS_TYPE_GAME', ALIAS_TYPE_GAME);
    inject('getRoute', getRoute);
};
