<template>
    <img ref="ele" v-lazy="{src: fullSrc, loading: loading, error: error}">
</template>
<script>
export default {
    name: 'dImg',
    props: ['src', 'resize', 'w', 'h', 'loading', 'error'],
    data() {
        return {
            fullSrc:
            // eslint-disable-next-line no-nested-ternary
            this.resize ? `${this.$store.state.mapDomain.RESIZE_IMAGE_URL}/${this.w}x${this.h}/${this.src}`
                : (!(/^http(s?):\/\//.test(this.src))
                && !(/^\/\//.test(this.src)) ? `${this.$store.state.mapDomain.IMAGE_URL}/${this.src}` : this.src)
        };
    },
    mounted() {
    }
};
</script>
