<template>
    <b-modal id="registerModal" content-class="modal--full-height" ok-only no-stacking @show="resetModal" @hidden="resetModal">
        <template v-slot:modal-title>
            ĐĂNG KÝ TÀI KHOẢN
        </template>
        <form class="form" id="register" @submit.prevent="register">
            <div class="form__custom" :class="{ 'error': $v.formData.username.$error }">
              <label>Tên đăng nhập</label>
                <input autocomplete="username" class="form-input" placeholder="Tên đăng nhập 6 đến 30 ký tự"
                       v-model="$v.formData.username.$model" type="text" >
                <div v-if="$v.formData.username.$error">
                    <p v-if="!$v.formData.username.required" class="form-note">Vui lòng nhập tên đăng nhập tài khoản</p>
                    <p v-if="!$v.formData.username.usernameRegex" class="form-note">{{$t('errors.username.usernameRegex')}}</p>
                    <p v-if="$v.formData.username.usernameRegex && !$v.formData.username.minLength"
                       class="form-note">Tên đăng nhập yêu cầu từ 6 đến 30 ký tự</p>
                    <p v-if="$v.formData.username.usernameRegex && !$v.formData.username.maxLength"
                       class="form-note">Tên đăng nhập yêu cầu từ 6 đến 30 ký tự</p>
                    <p v-if="!$v.formData.username.isUnique" class="form-note">{{$t('errors.username.exist')}}</p>
                </div>
            </div>
            <div class="form__custom" :class="{ 'error': $v.formData.fullname.$error }">
              <label>Tên hiển thị</label>
                <input autocomplete="fullname" class="form-input" placeholder="Tên hiển thị 6 đến 30 ký tự"
                       v-model="$v.formData.fullname.$model" type="text" >
                <div v-if="$v.formData.fullname.$error">
                    <p v-if="!$v.formData.fullname.required" class="form-note">Vui lòng nhập tên hiển thị tài khoản</p>
                    <p v-if="!$v.formData.fullname.minLength"
                       class="form-note">Tên hiển thị yêu cầu từ 6 đến 30 ký tự</p>
                    <p v-if="!$v.formData.fullname.maxLength"
                       class="form-note">Tên hiển thị yêu cầu từ 6 đến 30 ký tự</p>
                    <p v-if="!$v.formData.fullname.isUnique" class="form-note">{{$t('errors.fullname.exist')}}</p>
                    <p v-if="!$v.formData.fullname.isValid" class="form-note">{{$t('errors.fullname.usernameSame')}}</p>
                </div>
            </div>
            <div class="form__custom" :class="{ 'error': $v.formData.password.$error }">
              <label>Mật khẩu</label>
                <input autocomplete="new-password" class="form-input password" placeholder="Mật khẩu 6 đến 32 ký tự"
                       v-model.trim="$v.formData.password.$model" :type="isShowPassword ? 'text' : 'password'">
                <i class="las la-eye"
                   :class="{active: isShowPassword}"
                   @click="isShowPassword = !isShowPassword"></i>
                <div v-if="$v.formData.password.$error">
                    <p v-if="!$v.formData.password.required" class="form-note">Vui lòng nhập mật khẩu tài khoản</p>
                    <p v-if="!$v.formData.password.minLength" class="form-note">Mật khẩu truy cập yêu cầu từ 6 đến 32 ký tự</p>
                    <p v-if="!$v.formData.password.maxLength" class="form-note">Mật khẩu truy cập yêu cầu từ 6 đến 32 ký tự</p>
                </div>
            </div>
            <div class="form__custom" :class="{ 'error': $v.formData.confirmPassword.$error }">
              <label>Nhập lại mật khẩu</label>
                <input autocomplete="new-password" class="form-input password" placeholder="Nhập lại Mật khẩu"
                       v-model.trim="$v.formData.confirmPassword.$model" :type="isShowPasswordComfirm ? 'text' : 'password'">
                <i class="las la-eye"
                   :class="{active: isShowPasswordComfirm}"
                   @click="isShowPasswordComfirm = !isShowPasswordComfirm"></i>
                <div v-if="$v.formData.confirmPassword.$error">
                    <p v-if="!$v.formData.confirmPassword.required" class="form-note">Vui lòng nhập lại mật khẩu lần thứ 2</p>
                    <p v-if="$v.formData.confirmPassword.required
                    && !$v.formData.confirmPassword.sameAsPassword" class="form-note">Mật khẩu lần 2 chưa chính xác</p>
                </div>
            </div>
            <div class="form__custom" :class="{ 'error': $v.formData.phone.$error }">
              <label>Số điện thoại</label>
                <m-input :mask="numberMask" type="tel" inputmode="numeric"
                         class="form-input" name="phone" placeholder="Nhập số điện thoại" v-model.trim="$v.formData.phone.$model">
                </m-input>
                <div v-if="$v.formData.phone.$error">
                    <p v-if="!$v.formData.phone.maxLength" class="form-note">Số điện thoại không được nhiều hơn 12 ký tự</p>
                    <p v-if="!$v.formData.phone.minLength" class="form-note">Số điện thoại không được ít hơn 10 ký tự</p>
                    <p v-if="!$v.formData.phone.required" class="form-note">Vui lòng nhập số điện thoại</p>
                </div>
            </div>
            <d-button class="button"
                      :disabled="isSubmit"
                      :loading="isSubmit">
                ĐĂNG KÝ NGAY
            </d-button>
            <div class="form-link flex-box just-center"><a target="_blank" href="/dieu-khoan-dieu-kien">Điều Khoản &amp; Điều Kiện</a></div>
            <div class="form-link flex-box">Bạn đã có tài khoản? <a data-dismiss="modal" v-b-modal.loginModal
                                                                    data-toggle="modal" href="javascript:void(0)"> ĐĂNG NHẬP NGAY</a></div>
        </form>
        <template v-if="$isEnableThemeWorldcup()" v-slot:modal-footer :hide="!$isEnableThemeWorldcup()">
            <bannerEventForm id="bannerModalRegister" />
        </template>
    </b-modal>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import registerMixins from '@/mixins/user/register';
// eslint-disable-next-line import/no-unresolved
import bannerEventForm from '@/components/desktop/banner-event-form.vue';

export default {
    mixins: [registerMixins],
    components: {
        bannerEventForm
    }
};
</script>
<style  scoped lang="scss">
  .form__custom {
    i {
      top: 38px !important;
    }
  }
</style>
