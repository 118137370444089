const apiVersion = '/api/v1';

export default ($axios) => ({
    login(payload) {
        return $axios.$post(`${apiVersion}/login`, payload, { progress: false });
    },
    register(payload) {
        return $axios.$post(`${apiVersion}/register`, payload, { progress: false });
    },
    logout(payload) {
        return $axios.$post(`${apiVersion}/logout`, payload, { progress: false });
    },
    update(payload) {
        return $axios.$post(`${apiVersion}/updateInfo`, payload, { progress: false });
    },
    updatePassword(payload) {
        return $axios.$post(`${apiVersion}/updatePassword`, payload, { progress: false });
    },
    forgotPassword(payload) {
        return $axios.$post(`${apiVersion}/forgotPassword`, payload, { progress: false });
    },
    resetPassword(payload) {
        return $axios.$post(`${apiVersion}/resetPassword`, payload, { progress: false });
    },
    syscOW() {
        return $axios.$get(`${apiVersion}/syscOW`, { progress: false });
    },
    verify(payload) {
        return $axios.$post(`${apiVersion}/verifyUsername`, payload, { progress: false });
    },
    verifyWithCancel(payload, cancelSource) {
        if (cancelSource) {
            return $axios.$post(`${apiVersion}/verifyUsername`, payload, {
                cancelToken: cancelSource.token,
                progress: false
            });
        }
        return $axios.$post(`${apiVersion}/verifyUsername`, payload, { progress: false });
    },
    verifyEmail(payload) {
        return $axios.$post(`${apiVersion}/verifyEmail`, payload, { progress: false });
    },
    verifyEmailWithCancel(payload, cancelSource) {
        if (cancelSource) {
            return $axios.$post(`${apiVersion}/verifyEmail`, payload, {
                cancelToken: cancelSource.token,
                progress: false
            });
        }
        return $axios.$post(`${apiVersion}/verifyEmail`, payload, { progress: false });
    },
    index() {
        return $axios.$get(`${apiVersion}/indexaccount`);
    },
    getBet(payload) {
        return $axios.$post(`${apiVersion}/account/bet`, payload);
    },
    getCommission(payload) {
        return $axios.$post(`${apiVersion}/account/commission`, payload);
    },
    getWallet() {
        return $axios.$get(`${apiVersion}/wallet`);
    },
    getBalance() {
        return $axios.$get(`${apiVersion}/balance`);
    },
    transfer(payload) {
        return $axios.$get(`${apiVersion}/athena/transfer`, {
            params: payload,
            progress: false
        });
    },
    getPlan() {
        return $axios.$get(`${apiVersion}/account/plan`);
    },
    countInvoicePromotion() {
        return $axios.$get(`${apiVersion}/account/promotion`);
    },
    getLuckySpin() {
        return $axios.$get(`${apiVersion}/user/lucky-spin`);
    },
    legitBanks() {
        return $axios.$get(`${apiVersion}/account/userbank`);
    }
});
