const apiVersion = process.env.EVENT_SEAGAME_API_PATH;

export default ($axios) => ({
    spinsCount(username) {
        return $axios.$get(`${apiVersion}/seagame/user-info`, {
            params: {
                username,
                t: Math.random() * 10
            }
        });
    },
    spinTurn(username, spinNumber = 1) {
        return $axios.$post(`${apiVersion}/seagame/roll?t=${Math.random() * 10}`, {
            username,
            turn: spinNumber
        });
    },
    dailyWinner(payload) {
        return $axios.$get(`${apiVersion}/seagame/daily-winner`, {
            params: { ...payload, t: Math.random() * 10 }
        });
    },
    topMedal(payload) {
        return $axios.$get(`${apiVersion}/seagame/top-medal`, {
            params: { ...payload, t: Math.random() * 10 }
        });
    },
    userHistoryWheel(payload) {
        return $axios.$get(`${apiVersion}/seagame/user-history`, {
            params: { ...payload, t: Math.random() * 10 }
        });
    },
    rewardList() {
        return $axios.$get(`${apiVersion}/seagame/reward-list`, {
            params: {
                type: 'full',
                t: Math.random() * 10
            }
        });
    },
    getSeaGameGoldMedal() {
        return $axios.$get(process.env.EVENT_SEAGAME_API_MEDAL, {
            params: {
                t: Math.random() * 10
            }
        });
    }
});
