<template>
  <b-modal
      id="modal_update_username"
      class="modal"
      hide-footer
      hide-header
      ok-only
      :no-close-on-esc="false"
      :no-close-on-backdrop="false">
    <h5 class="modal-header">Cập nhật tên đăng nhập</h5>
    <div class="desc">
    <span>
        Vì lý do bảo mật, Quý khách vui lòng cập nhật Tên Đăng Nhập.<br>
        (Tên Đăng Nhập hiện tại của Quý khách là <span class="current-username">{{ getUsername() }}</span>)
      </span>
    </div>
    <form class="form" @submit.prevent="onSubmit">
      <div class="form__custom" :class="{'error': $v.formData.username.$error}">
        <input class="form-input" placeholder="Tên đăng nhập" type="text" v-model.trim="$v.formData.username.$model">
        <div v-if="$v.formData.username.$error">
          <p v-if="!$v.formData.username.required" class="form-note">Vui lòng nhập tên đăng nhập</p>
          <p v-if="!$v.formData.username.usernameValidation" class="form-note">Tên đăng nhập chỉ được gồm: a-z, 0-9 và dấu gạch dưới (_).</p>
        </div>
      </div>
      <d-button type="submit" class="button"
          :disabled="isSubmitting"
          :loading="isSubmitting">
        Cập nhật
      </d-button>
    </form>
  </b-modal>
</template>
<script>

import { helpers, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

const usernameValidation = helpers.regex('usernameValidation', /^[a-zA-Z0-9_]*$/);
export default {
    mixins: [validationMixin],
    data() {
        return {
            formData: {
                username: ''
            },
            isSubmitting: false
        };
    },
    validations: {
        formData: {
            username: {
                usernameValidation,
                required
            }
        }
    },
    watch: {
        'formData.username': function (value) {
            this.formData.username = value.toLowerCase().trim();
        }
    },
    computed: {
        getCurrentUser() {
            return this.$store.state.user;
        }
    },
    methods: {
        getUsername() {
            if (this.getCurrentUser) {
                if (this.getCurrentUser.username_new) {
                    return this.getCurrentUser.username_new;
                }
                return this.getCurrentUser.username;
            }
            return '';
        },
        async onSubmit() {
            this.isSubmitting = true;
            if (!this.$v.$invalid) {
                const requestData = {
                    username: this.formData.username
                };
                try {
                    const response = await this.$userApi.verifyWithCancel({ ...requestData });
                    // Tên user chưa tồn tại
                    if (response.exist) {
                        this.$alertError('Tài khoản này đã tồn tại, vui lòng nhập tên khác !');
                        this.isSubmitting = false;
                    } else {
                        await this.onUpdateUsername();
                    }
                } catch (e) {
                    this.isSubmitting = false;
                    this.$alertError(e.message);
                }
            } else {
                console.error('invalid');
                this.isSubmitting = false;
            }
        },
        async onUpdateUsername() {
            const requestData = {
                username_new: this.formData.username
            };
            try {
                const response = await this.$store.dispatch('updateInfo', requestData);
                if (response && response.status === 'OK'
                ) {
                    this.$store.commit('user', requestData);
                    this.$alertUpdateUsernameSuccess('Cập nhật thành công, Vui lòng đăng nhập lại', () => {
                        this.$bvModal.hide('modal_update_username');
                        this.resetForm();
                        this.logout();
                    });
                } else {
                    this.$alertError(response.message);
                }
                this.isSubmitting = false;
            } catch
            (error) {
                this.$alertError(error.message);
                this.isSubmitting = false;
            }
        },
        async logout() {
            try {
                localStorage.setItem('showLoginModal', true);
                await this.$store.dispatch('logout');
            } catch (e) {
                this.$alertError('Server đang bị lỗi! Vui lòng thử lại sau!');
                this.formError = e.message;
            }
        },
        resetForm() {
            this.$v.$reset();
            this.formData.username = '';
        }
    }
};
</script>
<style lang="scss">
#modal_update_username {
  .modal-header {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #DCC27E;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 13px 38px 5px;
    color: #E2E2E2;
    text-align: center;
  }

  span {
    color: #E2E2E2;
    text-align: center;

  }

  .current-username {
    color: #ffc107;
  }

  &__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 13px 38px 5px;
    color: #E2E2E2;
    text-align: center;

    span {
      text-transform: uppercase;
      font-weight: 800;
    }

    br {
      display: none;
    }
  }

  .modal-body {
    padding-top: 0px;
  }

  .form {
    .form-input {
      margin-top: 1rem;
    }
  }

  .button {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

</style>
