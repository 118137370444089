<template>
    <b-modal id="warningStatement" content-class="modal--maintain-bank"  centered hide-header hide-footer ok-only no-stacking>
        <div class="modal-close" @click="closeModal">
            <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/mobile/icon-close.svg`" alt="Close"/>
        </div>
        <div class="modal--maintain-bank__content">
            <div class="logo-bank">
                <img :src="logoBank($store.state.bankStatement.bank_code)">
            </div>
            <p class="text">Từ <span class="text-yellow bold">{{$store.state.bankStatement.start_time}}</span> tới <span class="text-yellow bold"> {{$store.state.bankStatement.end_time}}</span>, trong khoảng thời gian <span>{{$store.state.bankStatement.note}}</span> của ngân hàng <span class="text-yellow">{{$store.state.bankStatement.bank_name}}</span>, quý khách vui lòng chuyển tiền vào ngân hàng khác của hệ thống. Xin cảm ơn!</p>
            <d-button type="submit"
                      :apply="closeModal"
                      class="button">
                XÁC NHẬN
            </d-button>
        </div>
    </b-modal>
</template>
<script>
export default {
    methods: {
        logoBank(bankcode) {
            const { $store: { state } } = this;
            switch (bankcode) {
                case 'VietinBank':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-vietinbank.svg`;
                case 'Sacombank':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-sacombank.svg`;
                case 'VCB':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-vietcombank.svg`;
                case 'BIDV':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-bidv.svg`;
                case 'ACB':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-acb.svg`;
                case 'DongA':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-donga.svg`;
                case 'Techcombank':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-techcombank.svg`;
                case 'VietCapital':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-vietcap.svg`;
                case 'Eximbank':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-eximbank.svg`;
                case 'MBbank':
                    return `${state.mapDomain.STATIC_URL}/assets/img/banks/logo-mbbank.svg`;
                default:
                    return '';
            }
        },
        closeModal() {
            this.$hidePopupById('warningStatement');
        }
    }
};
</script>
