export const state = () => ({
    currentStep: 1,
    selectedCurrency: {},
    package: 1,
    paymentId: ''
});

export const mutations = {
    setValue(state, payload) {
        state[payload.key] = payload.value;
    }
};
