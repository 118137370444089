<template>
    <b-modal id="loginModal" content-class="modal--full-height" ok-only no-stacking  @show="resetModal"
      @hidden="resetModal">
        <template v-slot:modal-title>
          đăng nhập
        </template>
        <form class="form" @submit.prevent="login">
            <div class="form__custom" :class="{'error': $v.formData.username.$error}">
              <label> Tên đăng nhập</label>
                <input class="form-input" placeholder="Tên đăng nhập" type="text" v-model.trim="$v.formData.username.$model">
                <div v-if="$v.formData.username.$error">
                    <p v-if="!$v.formData.username.required" class="form-note">Vui lòng nhập tên đăng nhập</p>
                    <p v-if="!$v.formData.username.usernameValidation" class="form-note">Tên đăng nhập chỉ được gồm: a-z, 0-9 và dấu gạch dưới (_).</p>
                </div>
            </div>
            <div class="form__custom" :class="{'error': $v.formData.password.$error}">
              <label>Mật khẩu</label>
                <input class="form-input" placeholder="Vui lòng nhập mật khẩu"
                    v-model.trim="$v.formData.password.$model"
                    :type="isShowPassword ? 'text' : 'password'">
                <i class="las la-eye" :class="{active: isShowPassword}" @click="isShowPassword = !isShowPassword"></i>
                <div v-if="$v.formData.password.$error">
                    <p v-if="!$v.formData.password.required" class="form-note">Vui lòng nhập mật khẩu</p>
                    <p v-if="!$v.formData.password.minLength" class="form-note">Mật khẩu phải trên 6 ký tự</p>
                </div>
            </div>
            <div class="forgot-pass">
                <a @click="$bvModal.show('forgotPasswordModal')" data-toggle="modal" href="javascript:void(0)">Quên Mật Khẩu?</a>
            </div>
            <div class="form__custom text-center error">
                <p v-if="messageLoginFail !== ''" class="form-note text-center">{{messageLoginFail}}</p>
            </div>
            <d-button type="submit" class="button"
                :disabled="isSubmit"
                :loading="isSubmit">
                ĐĂNG NHẬP NGAY
            </d-button>
            <div class="form-link flex-box">
                Bạn chưa có tài khoản?
                <a data-dismiss="modal" v-b-modal.registerModal data-toggle="modal" href="javascript:void(0)"> ĐĂNG KÝ NGAY</a>
            </div>
        </form>
        <template v-if="$isEnableThemeWorldcup()" v-slot:modal-footer>
            <bannerEventForm id="bannerModalLogin" />
        </template>
      </b-modal>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import loginMixins from '@/mixins/user/login';
// eslint-disable-next-line import/no-unresolved
import bannerEventForm from '@/components/desktop/banner-event-form.vue';

export default {
    mixins: [loginMixins],
    components: {
        bannerEventForm
    }
};
</script>
<style  scoped lang="scss">
.form__custom {
  i {
    top: 38px !important;
  }
}
</style>
