<template>
    <div class="nav-mobile" id="wrapNav">
        <div class="flex-box nav-mobile-close line-menu" ref="boxTop">
            <a @click.prevent="goTo('/')" class="logo" href="javascript:void(0)">
                <img src="/assets/img/logo.png" alt="oxbet"/></a>
            <a @click.prevent="closeMenu" class="close" href="javascript:void(0)">
                <img src="/assets/img/mobile/icon-close.svg" alt="close"/></a>
        </div>
        <div v-if="$isEnableThemeWorldcup()" class="menu-daily menu-daily__world-cup">
            <p class="menu-daily__text">Sự Kiện Đặc Biệt WorldCup 2022</p>
            <div class="menu-daily__box">
                <n-link class="menu-daily__link" to="/worldcup">
                    <img src="/assets/img/event/worldcup/menu-wc-mb.jpg" alt="worldcup"/>
                    <p class="menu-note hot animation">
                        <s-img src="/assets/img/header/icon-hot.svg" alt="hot"/>
                    </p>
                </n-link>
                <n-link class="menu-daily__link" to="/goal-scorer">
                    <img src="/assets/img/event/worldcup/menu-wc-soccer-mb.jpg" alt="worldcup"/>
                    <p class="menu-note hot animation">
                        <s-img src="/assets/img/header/icon-hot.svg" alt="hot"/>
                    </p>
                </n-link>
            </div>
        </div>
        <b-container class="game-mobile line-menu" ref="boxMiddle">
            <a v-if="$isEnableThemeDaily()" @click.prevent="goTo('/daily-event')" class="menu-daily" href="javascript:void(0)">
                <img src="/assets/img/event/daily/menu-daily.png" alt="daily"/>
                <span><img src="/assets/img/navigation-next.svg" alt="next"></span>
            </a>
            <a v-if="$isEnableThemeSeagame()" @click.prevent="goTo('/seagames')" class="menu-daily" href="javascript:void(0)">
                <img src="/assets/img/event/seagame/menu-seagame.png" alt="seagame"/>
                <span><img src="/assets/img/navigation-next.svg" alt="next"></span>
            </a>
            <b-row class="row-menu">
                <b-col cols="3" class="col-menu" v-for="(item, index) in menus" :key="index"  @click.prevent="closeMenu">
                    <div class="product__item">
                        <a href="javascript:void(0)" @click.prevent="moveTo(item)">
                            <div class="product__item-thumb default-img">
                                <s-img v-if="item.label === $LABEL_TYPE.LABEL_EVENT" class="label"
                                       src="/assets/img/event/icon-event.svg" alt="event"/>
                                <img :src="item.iconUrl" :alt="item.title"/>
                            </div>
                            <div class="product__item-title text-center">{{item.title}}</div>
                        </a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <!--Accordion wrapper-->
        <div class="accordion__menu" role="tablist" ref="boxBottom">
            <div no-body class="accordion__menu__item line-menu">
                <div header-tag="header" class="accordion-header" role="tab"  @click.prevent="collapseScroll">
                    <div class="title class-css" block v-b-toggle.accordion-about variant="info">Giới thiệu về OXBET</div>
                </div>
                <b-collapse id="accordion-about" accordion="my-accordion" role="tabpanel">
                    <div class="accordion-body line-menu">
                        <ul class="list-menu-mobile">
                            <li v-for="(item, index) in aboutItem" :key="index">
                                <n-link :to="item.link">{{item.title}}</n-link>
                            </li>
                        </ul>
                    </div>
                </b-collapse>
            </div>
            <div class="accordion__menu__item line-menu">
                <div class="accordion-header">
                    <a class="title" href="/khuyen-mai">Khuyến Mãi</a>
                </div>
            </div>
            <div class="accordion__menu__item line-menu">
                <div class="accordion-header">
                    <a class="title" href="/tin-tuc">Tin Tức</a>
                </div>
            </div>
            <div no-body class="accordion__menu__item  line-menu">
                <div header-tag="header" class="accordion-header" role="tab"  @click.prevent="collapseScroll">
                    <div class="title class-css" block v-b-toggle.accordion-guide variant="info">Hướng Dẫn</div>
                </div>
                <b-collapse id="accordion-guide" accordion="my-accordion" role="tabpanel">
                    <div class="accordion-body line-menu">
                        <ul class="list-menu-mobile">
                            <li v-for="(item, index) in GuideItem" :key="index">
                                <n-link :to="item.link">{{item.title}}</n-link>
                            </li>
                        </ul>
                    </div>
                </b-collapse>
            </div>
            <div no-body class="accordion__menu__item  line-menu">
                <div header-tag="header" class="accordion-header" role="tab"  @click.prevent="collapseScroll">
                    <div class="title class-css" block v-b-toggle.accordion-contact variant="info">Liên Hệ</div>
                </div>
                <b-collapse id="accordion-contact" accordion="my-accordion" role="tabpanel">
                    <div class="accordion-body line-menu">
                        <ul class="footer__information-menu">
                            <!-- <li>
                                <a class="flex-box" :href="`tel: ${this.$t('contact.phone')}`">
                                    <div class="icon">
                                        <img src="/assets/img/footer/icon-mobile.svg" alt="mobile"/>
                                    </div>
                                    <div class="text">
                                        <span>SĐT: </span>
                                        <span class="label">{{this.$t('contact.phone') | fomatPhone}}</span>
                                    </div>
                                </a>
                            </li> -->
                            <li>
                                <a class="flex-box" :href="`mailto:${this.$t('contact.email')}`">
                                    <div class="icon">
                                        <img src="/assets/img/footer/icon-gmail.svg" alt="gmail"/>
                                    </div>
                                    <div class="text">
                                        <span>Email: </span>
                                        <span class="label">{{this.$t('contact.email')}}</span>
                                    </div>
                                </a>
                            </li>
<!--                            <li>-->
<!--                                <a class="flex-box" target="_blank" :href="`https://zalo.me/${this.$t('contact.zalo')}`">-->
<!--                                    <div class="icon">-->
<!--                                        <img src="/assets/img/footer/zalo.svg" alt="zalo"/>-->
<!--                                    </div>-->
<!--                                    <div class="text">-->
<!--                                        <span>Zalo: </span>-->
<!--                                        <span class="label">{{this.$t('contact.zalo') | fomatPhone}}</span>-->
<!--                                    </div>-->
<!--                                </a>-->
<!--                            </li>-->
                        </ul>
                    </div>
                </b-collapse>
            </div>
        </div>
        <div id="endNav"></div>
    </div>
</template>
<script>
import $ from 'jquery';

export default {
    data() {
        return {
            menus: [
                {
                    title: 'Thể thao',
                    link: '/the-thao',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/thethao.png',
                    newTab: false,
                    label: this.$LABEL_TYPE.EVENT_WHEEL
                },
                {
                    title: 'E-SPORTS',
                    link: '/the-thao-so',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/esport.png',
                    newTab: true
                },
                {
                    title: 'GAME BÀI',
                    link: '/tro-choi?tab=macau&type=game-bai',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/gamebai.png',
                    newTab: false
                },
                {
                    title: 'THỂ THAO ẢO',
                    link: '/the-thao-ao',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/thethaoao.png',
                    newTab: true
                },
                {
                    title: 'LIVE CASINO',
                    link: '/livecasino',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/livecasino.png',
                    newTab: false
                },
                {
                    title: 'Keno',
                    link: '/keno',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/keno.png',
                    newTab: true
                },
                {
                    title: 'Nổ hũ',
                    link: '/tro-choi?tab=macau&type=no-hu',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/slots.png',
                    newTab: false,
                    label: ''
                },
                {
                    title: 'Bắn cá',
                    link: '/tro-choi?tab=macau&type=ban-ca',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/fish.png',
                    newTab: false
                },
                {
                    title: 'Quay số',
                    link: '/tro-choi?tab=macau&type=quay-so',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/lottery.png',
                    newTab: false
                },
                {
                    title: 'INGAME',
                    link: '/tro-choi?tab=macau&type=ingame',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/ingame.png',
                    newTab: false
                },
                {
                    title: 'Lô đề',
                    link: '/tro-choi-lo-de',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/lode.png',
                    newTab: false
                },
                {
                    title: 'Numbers Game',
                    link: '/tro-choi-so',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/numbergame.png',
                    newTab: true
                },
                {
                    title: 'Table Games',
                    link: '/tro-choi?tab=macau&type=danh-bai',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/tablegame.png',
                    newTab: false
                },
                {
                    title: 'TRÒ CHƠI',
                    link: '/tro-choi',
                    loginRequired: false,
                    iconUrl: '/assets/img/game/conggame.png',
                    newTab: false
                }
            ],
            aboutItem: [
                {
                    title: 'Về Chúng Tôi',
                    link: '/gioi-thieu'
                },
                {
                    title: 'Chính Sách Bảo Mật',
                    link: '/chinh-sach-bao-mat'
                },
                {
                    title: 'Điều Khoản và Điều Kiện',
                    link: '/dieu-khoan-dieu-kien'
                }
            ],
            GuideItem: [
                {
                    title: 'Hướng Dẫn Đăng Ký',
                    link: '/huong-dan-dang-ky'
                },
                {
                    title: 'Hướng Dẫn Cá Cược',
                    link: '/huong-dan-ca-cuoc'
                },
                {
                    title: 'Hướng Dẫn Nạp Tiền',
                    link: '/huong-dan-nap-tien'
                },
                {
                    title: 'Hướng Dẫn Rút Tiền',
                    link: '/huong-dan-rut-tien'
                }
            ]
        };
    },
    computed: {
        footer() {
            return this.$t('footer');
        },
        user() {
            return this.$store.state.user;
        },
        locales() {
            return this.$store.state.langs;
        },
        locale() {
            return this.$store.state.lang;
        }
    },
    methods: {
        moveTo(item) {
            this.$fixScrollTo('html');
            if (item.newTab) {
                if (item.link === '/tro-choi-so') {
                    this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=/numbergame`, {
                        loginRequired: item.loginRequired,
                        newTab: true
                    });
                } else if (item.link === '/the-thao-so') {
                    this.$openEsports();
                } else if (item.link === '/the-thao-ao') {
                    this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=/vssport`, {
                        loginRequired: item.loginRequired,
                        newTab: true
                    });
                } else {
                    this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=${item.link}`, {
                        loginRequired: item.loginRequired,
                        newTab: true
                    });
                }
            } else {
                this.$openLink(item.link, {
                    loginRequired: item.loginRequired,
                    newTab: item.newTab
                });
            }
        },
        closeMenu() {
            $('#accordion-about').removeClass('show');
            $('#accordion-guide').removeClass('show');
            $('#accordion-contact').removeClass('show');
            $('.accordion-header .title').removeClass('not-collapsed');
            $('body').removeClass('off-canvas-active');
        },
        goTo(url) {
            this.closeMenu();
            this.$router.push(url);
            this.$fixScrollTo('html');
        },
        showChat() {
            this.$openLiveChat();
        },
        collapseScroll() {
            const boxTopHeight = this.$refs.boxTop.clientHeight;
            const boxMiddleHeight = this.$refs.boxMiddle.clientHeight;
            const boxBottomHeight = this.$refs.boxBottom.clientHeight;
            $('#wrapNav').animate({
                scrollTop: boxTopHeight + boxMiddleHeight + boxBottomHeight
            }, 700);
        }
    }
};
</script>
