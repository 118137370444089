const apiVersion = '/api/v1/event/v2';

export default ($axios) => ({
    d_spinsCount() {
        return $axios.$get(`${apiVersion}/user/turn`);
    },
    d_spinTurn(spinNumber = 1) {
        return $axios.$post(`${apiVersion}/user/spin`, { turn: spinNumber });
    },
    d_spinsReward() {
        return $axios.$get(`${apiVersion}/rewards`);
    },
    d_userHistory(payload) {
        return $axios.$get(`${apiVersion}/user/history`, {
            params: payload
        });
    },
    d_countSpecial() {
        return $axios.$get(`${apiVersion}/user/special-spin/count`);
    },
    d_openBoxRewardSpecial(key) {
        return $axios.post(`${apiVersion}/user/special-spin`,
            {
                key,
                pick: Math.floor(Math.random() * 12) + 1
            }, {});
    }
});
