// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import {
    required, minLength, helpers, maxLength, sameAs
} from 'vuelidate/lib/validators';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const usernameRegex = helpers.regex('usernameRegex', /^[a-zA-Z0-9]*$/);
const CancelToken = axios.CancelToken;
let verifyUsernameSource = null;
let verifyFullnameSource = null;

export default {
    mixins: [validationMixin],
    data() {
        return {
            isModalVisible: false,
            isShowPassword: false,
            isShowPasswordComfirm: false,
            formData: {
                phone: '',
                username: '',
                fullname: '',
                password: '',
                confirmPassword: ''
            },
            numberMask: createNumberMask({
                prefix: '',
                suffix: '',
                integerLimit: 12,
                thousandsSeparatorSymbol: '',
                allowLeadingZeroes: true
            }),
            isSubmit: false
        };
    },
    validations: {
        formData: {
            phone: {
                required,
                maxLength: maxLength(12),
                minLength: minLength(10)
            },
            username: {
                required,
                usernameRegex,
                minLength: minLength(6),
                maxLength: maxLength(30),
                async isUnique(value) {
                    if (value === null || value.length < 6 || value.length > 30 || !usernameRegex(value)) {
                        return true;
                    }
                    if (verifyUsernameSource) {
                        verifyUsernameSource.cancel();
                    }
                    verifyUsernameSource = CancelToken.source();
                    try {
                        const res = await this.$userApi.verifyWithCancel({
                            username: value
                        }, verifyUsernameSource);
                        return !res.exist;
                    } catch (e) {
                        return true;
                    }
                }
            },
            fullname: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(30),
                async isValid(value) {
                    return !(this.formData.username == value)
                },
                async isUnique(value) {
                    if (value === null || value.length < 6 || value.length > 30) {
                        return true;
                    }
                    if (verifyFullnameSource) {
                        verifyFullnameSource.cancel();
                    }
                    verifyFullnameSource = CancelToken.source();
                    try {
                        const res = await this.$userApi.verifyWithCancel({
                            fullname: value
                        }, verifyFullnameSource);
                        return !res.exist;
                    } catch (e) {
                        return true;
                    }
                }
            },
            password: {
                required,
                maxLength: maxLength(32),
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    methods: {
        async register() {
            try {
                this.$v.$touch();
                this.isSubmit = true;
                if (!this.$v.$invalid) {
                    const data = await this.$store.dispatch('register', { ...this.formData });
                    if (data && data.status === 'OK') {
                        this.isSubmit = false;
                        this.$bvModal.hide('registerModal');
                        this.$alert('Đăng ký tài khoản thành công !');
                        this.$v.$reset();
                        this.formData.phone = '';
                        this.formData.username = '';
                        this.formData.fullname = '';
                        this.formData.password = '';
                        this.formData.confirmPassword = '';
                        setTimeout(() => {
                            this.$Swal.close();
                        }, 1500);
                    } else {
                        this.isSubmit = false;
                        this.$alertError(data.message);
                    }
                } else {
                    this.isSubmit = false;
                }
            } catch (e) {
                this.formError = e.message;
                this.isSubmit = false;
                this.$bvModal.hide('registerModal');
                this.$alertError(e.message);
            }
        },
        resetModal() {
            this.$v.$reset();
            this.formData.phone = '';
            this.formData.username = '';
            this.formData.fullname = '';
            this.formData.password = '';
            this.formData.confirmPassword = '';
        }
    },
    watch: {
        'formData.username': function (value) {
            this.formData.username = value.toLowerCase().trim();
        },
        'formData.fullname': function (value) {
            this.formData.fullname = value.trim();
        }
    }
};
