<template>
  <div :class="{ 'event-noel': $isEnableThemeNoel(), 'event-holiday': $isEnableThemeHoliday() }">
    <Appheader v-if="!hideHeader && !hideHeaderPage"/>
    <nuxt />
    <transition v-if="showIframe" name="fade" mode="out-in">
        <app-iframe v-show="showIframe" />
    </transition>
    <menu-mobile v-if="!hideHeader && !hideHeaderPage"/>
    <nav-mobile/>
    <Appfooter  v-if="!hideFooter"/>
    <client-only>
      <modal-login />
      <modal-register />
      <modal-forgot-password />
      <modal-tranfer-wallet />
      <update-domain-modal />
      <modal-update-username/>
      <merge-wallet />
      <events-redirect-modal />
      <livechat-maintain  v-if="livechatMaintain==='1'"/>
      <div v-if="livechatMaintain==='1'" @click="showLiveChatMaintain" data-toggle="modal" class="livechat-maintain">
        <img src="/assets/img/livechat/notify.png" alt="live chat"/>
      </div>
      <WarningStatementModal />
    </client-only>
    <client-only v-if="$isEnableThemeDaily()" >
      <DailyRulesConditionModal />
    </client-only>
    <client-only v-if="$isEnableEventWheel()">
      <RulesConditionModal />
      <HistoryPredictionModal />
      <LuckyEventModal />
      <CompleteSpinModal
        v-if="$store.state.prizeNumberList.length !== 0"
        :list-reward-spin="$store.state.prizeNumberList" />
      <PredictedTeamModal @checkRewardSpecialNoel="checkRewardSpecialNoel"/>
      <SpecialModal :isRewardSpecial="isRewardSpecial"/>
    </client-only>
    <client-only v-if="$isEnableThemeSeagame()">
      <LuckySeagameModal />
      <CompleteSeagameModal
        v-if="$store.state.prizeNumberList.length !== 0"
        :list-reward-spin="$store.state.prizeNumberList"
      />
      <TotalSlotsModal />
    </client-only>
    <div v-if="eventPopup && $device.isDesktop && !hideFooter" :class="['hot-events-bar', hideHotEventsBar ? 'hide' : '']">
      <div class="hot-events-bar__wrap">
        <img class="hot-events-bar__title" alt="title" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/worldcup/hot-events-text.png`" />
        <img @click="hideHotEventsBar = !hideHotEventsBar" class="hot-events-bar__close" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/worldcup/play.svg`" />
        <div class="hot-events-bar__content">
          <n-link to="/goal-scorer" :class="['hot-events-bar__item']">
            <img class="hot-events-bar__img soccer" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/worldcup/wc-soccer-bar.png`" />
          </n-link>
          <n-link to="/worldcup" class="hot-events-bar__item">
            <img class="hot-events-bar__img" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/worldcup/wc-wheel-bar.png`" />
          </n-link>
        </div>
        <img @click="goToScheduleGoalScore" class="hot-events-bar__schedule" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/worldcup/hot-event-bar-schedule.png`" />
      </div>
    </div>
    <vergo-minigame v-if="!hideHeader && !hideMiniGame" partner=oxbet top=100px left=auto right=0 bottom=auto id="vergoMinigame" :token="tpToken"></vergo-minigame>
  </div>
</template>
<style>
</style>
<script>
import menuMobile from '../components/mobile/menu.vue';
import navMobile from '../components/mobile/nav.vue';
import Appheader from '../components/desktop/header.vue';
import modalLogin from '../components/desktop/dialogs/login.vue';
import modalRegister from '../components/desktop/dialogs/register.vue';
import modalForgotPassword from '../components/desktop/dialogs/forgotPassword.vue';
import modalTranferWallet from '../components/desktop/dialogs/tranferWallet.vue';
import UpdateDomainModal from '../components/desktop/dialogs/update-domain.vue';
import MergeWallet from '../components/desktop/dialogs/merge-wallet.vue';
import livechatMaintain from '../components/desktop/dialogs/livechatMaintain.vue';
import RulesConditionModal from '../components/event/wheel/modal/rules-condition-modal.vue';
import HistoryPredictionModal from '../components/event/wheel/modal/history-prediction-modal.vue';
import LuckyEventModal from '../components/event/wheel/modal/lucky-event-modal.vue';
import CompleteSpinModal from '../components/event/wheel/modal/complete-spin-modal.vue';
import PredictedTeamModal from '../components/event/wheel/modal/predicted-team-modal.vue';
import SpecialModal from '../components/event/wheel/modal/special-modal.vue';
import DailyRulesConditionModal from '../components/event/daily/modal/rules-condition-modal.vue';
import LuckySeagameModal from '../components/event/seagame/modal/lucky-event-modal.vue';
import TotalSlotsModal from '../components/event/seagame/modal/total-slots-modal.vue';
import CompleteSeagameModal from '../components/event/seagame/modal/complete-seagame-modal';
import WarningStatementModal from '../components/desktop/dialogs/warningStatement.vue';
import EventsRedirectModal from '../components/desktop/dialogs/events-redirect.vue';
import ModalUpdateUsername from '../components/desktop/dialogs/modal-update-username.vue';

export default {
    middleware: 'maintain',
    components: {
        ModalUpdateUsername,
        Appheader,
        Appfooter: () => import('@/components/desktop/footer.vue'),
        AppIframe: () => import('@/components/desktop/iframe.vue'),
        menuMobile,
        navMobile,
        modalLogin,
        modalRegister,
        modalForgotPassword,
        modalTranferWallet,
        livechatMaintain,
        UpdateDomainModal,
        RulesConditionModal,
        HistoryPredictionModal,
        LuckyEventModal,
        CompleteSpinModal,
        PredictedTeamModal,
        SpecialModal,
        DailyRulesConditionModal,
        LuckySeagameModal,
        CompleteSeagameModal,
        TotalSlotsModal,
        WarningStatementModal,
        MergeWallet,
        EventsRedirectModal
    },
    data() {
        return {
            isRewardSpecial: false,
            hideHotEventsBar: false,
            eventPopup: process.env.EVENT_POPUP && process.env.EVENT_POPUP === '1'
        };
    },
    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$getRoute(this.$route).name === 'the-loai-slug-page'
                        ? `${this.$store.state.mapDomain.HOME_URL}/the-loai/${this.$getRoute(this.$route).params.slug}`
                        : this.getCanonical()
                }
            ],
            __dangerouslyDisableSanitizers: ['script'],
            script: [
                {
                    src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
                    defer: true
                },
                {
                    hid: 'onesignal',
                    innerHTML: `
                    window.OneSignal = window.OneSignal || [];
                        window.OneSignal.push(() => {
                            window.OneSignal.init({
                                appId: '57a2a772-56e4-4d19-82d3-d81c74e09d51'
                            });
                        });
                    `,
                    type: 'text/javascript',
                    charset: 'utf-8'
                }
            ],
            title: this.setSeo('title'),
            meta: [
                {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0'
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: this.setSeo('description')
                },
                {
                    hid: 'keywords',
                    name: 'keywords',
                    content: this.setSeo('keywords')
                },
                {
                    name: 'google',
                    content: 'notranslate'
                },
                {
                    name: 'google-site-verification',
                    content: 'N86-m2jvK9doEYBbP3lIhmtPS2Q4fyfadkH1C6hduOQ'
                },
                {
                    name: 'google-site-verification',
                    content: 'E-yMosWAkaBSmWhdbAVgjmR5vKoQRhm8K4eXn991RSE'
                },
                {
                    name: 'google-site-verification',
                    content: '6rFfagP8T3IHay7iFWOhftIWqITKLD836ZJc9U9A3HE'
                }
            ],
            bodyAttrs: {
                class: 'scrollbar',
                ref: 'body'
            }
        };
    },
    computed: {
        livechatMaintain() {
            return process.env.LIVECHAT_MAINTAIN;
        },
        hideHeader() {
            return (this.$getRoute(this.$route) ? this.$getRoute(this.$route).name === 'bao-tri' && this.$store.state.device === 'desktop' : true);
        },
        hideMiniGame() {
            return (this.$getRoute(this.$route) ? this.$getRoute(this.$route).name === 'goal-scorer' : true);
        },
        hideHeaderPage() {
            return (this.$getRoute(this.$route) ? (this.$getRoute(this.$route).name === 'seagames'
            || this.$getRoute(this.$route).name === 'goal-scorer'
            || this.$getRoute(this.$route).name === 'worldcup')
          && this.$store.state.device === 'mobile' : true);
        },
        hideMenuMobile() {
            return (this.$getRoute(this.$route) ? (this.$getRoute(this.$route).name === 'worldcup')
          && (this.$getRoute(this.$route).name === 'goal-scorer')
          && this.$store.state.device === 'mobile' : true);
        },
        hideFooter() {
            return this.$getRoute(this.$route).name === 'bao-tri'
            || this.$getRoute(this.$route).name === 'quay-so'
            || this.$getRoute(this.$route).name === 'quay-so-sexy'
            || this.$getRoute(this.$route).name === 'lode'
            || this.$getRoute(this.$route).name === 'lo-de-sieu-toc'
            || this.$getRoute(this.$route).name === 'keno'
            || this.$getRoute(this.$route).name === 'tro-choi-so'
            || this.$getRoute(this.$route).name === 'the-thao-chau-au'
            || this.$getRoute(this.$route).name === 'the-thao-chau-a'
            || this.$getRoute(this.$route).name === 'ksport'
            || this.$getRoute(this.$route).name === 'the-thao-chau-a-tran-dau'
            || this.$getRoute(this.$route).name === 'the-thao-ao'
            || this.$getRoute(this.$route).name === 'the-thao-so'
            || this.$route.name === 'match';
        },
        showIframe() {
            return this.$getRoute(this.$route).name === 'quay-so'
            || this.$getRoute(this.$route).name === 'quay-so-sexy'
            || this.$getRoute(this.$route).name === 'lode'
            || this.$getRoute(this.$route).name === 'lo-de-sieu-toc'
            || this.$getRoute(this.$route).name === 'keno'
            || this.$getRoute(this.$route).name === 'tro-choi-so'
            || this.$getRoute(this.$route).name === 'the-thao-chau-au'
            || this.$getRoute(this.$route).name === 'the-thao-chau-a'
            || this.$getRoute(this.$route).name === 'ksport'
            || this.$getRoute(this.$route).name === 'the-thao-chau-a-tran-dau'
            || this.$getRoute(this.$route).name === 'the-thao-ao'
            || this.$getRoute(this.$route).name === 'the-thao-so'
            || this.$route.name === 'match';
        },
        tpToken() {
            return this.$store.state.user && this.$store.state.user.tp_token;
        },
        getCurrentUser() {
            return this.$store.state.user;
        }
    },
    methods: {
        checkShowLoginModal() {
            if (localStorage.getItem('showLoginModal') === 'true') {
                setTimeout(() => {
                    this.$showPopupById('loginModal');
                }, 2000);
            }
        },
        showModalUpdateUsername() {
            if (this.getCurrentUser) {
                const createdTimeRule = process.env.CREATED_TIME || null;
                const createdAt = this.getCurrentUser.created_time;
                const username_new = this.getCurrentUser.username_new;
                if (username_new) {
                    return;
                }
                const dateCreated = new Date(createdAt);
                const dateChangeRule = new Date(createdTimeRule);
                if (dateCreated < dateChangeRule) {
                    setTimeout(() => {
                        this.$showPopupById('modal_update_username');
                    }, 2000);
                }
            }
        },
        goToScheduleGoalScore() {
            if (this.$route.name === 'goal-scorer') {
                const ele = $('#schedulePrize');
                if (ele && ele.length) {
                    $('html, body').animate({
                        scrollTop: ele.offset().top
                    }, 700);
                }
            } else {
                this.$router.push('/goal-scorer?go_to=schedulePrize');
            }
        },
        checkRewardSpecialNoel(val) {
            this.isRewardSpecial = val;
        },
        showLiveChatMaintain() {
            this.$showPopupById('livechatMaintain');
        },
        setSeo(tag) {
            if (this.$getRoute(this.$route).name === 'tro-choi'
            || this.$getRoute(this.$route).name === 'khuyen-mai') {
                return this.$t(`meta.${this.convertUrl(this.$getRoute(this.$route).fullPath)}.${tag}`);
            }
            return this.$te(`meta.${this.$getRoute(this.$route).name}`)
                ? this.$t(`meta.${this.$getRoute(this.$route).name}.${tag}`)
                : this.$t(`meta.all.${tag}`);
        },
        convertUrl(fullPath) {
            switch (fullPath) {
                case '/tro-choi':
                case '/tro-choi?type=tat-ca':
                case '/tro-choi?tab=macau&type=tat-ca':
                    return 'tro-choi-macau';
                case '/tro-choi?type=danh-bai':
                case '/tro-choi?tab=macau&type=danh-bai':
                    return 'tro-choi-macau-danh-bai';
                case '/tro-choi?type=no-hu':
                case '/tro-choi?tab=macau&type=no-hu':
                    return 'tro-choi-macau-no-hu';
                case '/tro-choi?type=ban-ca':
                case '/tro-choi?tab=macau&type=ban-ca':
                    return 'tro-choi-macau-ban-ca';
                case '/tro-choi?type=ingame':
                case '/tro-choi?tab=macau&type=ingame':
                    return 'tro-choi-macau-ingame';
                case '/tro-choi?type=quay-so':
                case '/tro-choi?tab=macau&type=quay-so':
                    return 'tro-choi-macau-quay-so';
                case '/tro-choi?type=tro-choi-khac':
                case '/tro-choi?tab=macau&type=tro-choi-khac':
                    return 'tro-choi-macau-khac';
                case '/tro-choi?tab=macau&type=game-bai':
                    return 'tro-choi-macau-game-bai';
                case '/tro-choi?tab=macau&type=battle-game':
                    return 'tro-choi-macau-battle-game';
                case '/tro-choi?tab=vegas&type=tat-ca':
                    return 'tro-choi-vegas';
                case '/tro-choi?tab=vegas&type=danh-bai':
                    return 'tro-choi-vegas-danh-bai';
                case '/tro-choi?tab=vegas&type=no-hu-vegas':
                    return 'tro-choi-vegas-no-hu';
                case '/tro-choi?tab=vegas&type=no-hu-vegas-18':
                    return 'tro-choi-vegas-no-hu-18';
                case '/tro-choi?tab=vegas&type=tro-choi-khac':
                    return 'tro-choi-vegas-khac';
                case '/khuyen-mai':
                case '/khuyen-mai?type=welcome':
                    return 'khuyen-mai-welcome';
                case '/khuyen-mai?type=promotion':
                    return 'khuyen-mai-promotion';
                case '/khuyen-mai?type=commission':
                    return 'khuyen-mai-commission';
                default:
                    return 'index';
            }
        },
        getCanonical() {
            const fullPath = this.$getRoute(this.$route).fullPath;
            if (fullPath === '/tro-choi?tab=vegas&type=no-hu-vegas'
            || fullPath === '/tro-choi?tab=vegas&type=no-hu-vegas-18'
            || fullPath === '/tro-choi?tab=vegas&type=tro-choi-khac'
            || fullPath === '/tro-choi?tab=macau&type=tro-choi-khac') {
                return `${this.$store.state.mapDomain.HOME_URL}/tro-choi?tab=macau&type=no-hu`;
            }
            return this.$store.state.mapDomain.HOME_URL + fullPath;
        }
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        $route(to, from) {
            // hide all alert
            this.$Swal.close();
        },
        getCurrentUser(newUser, old) {
            // login
            if (newUser && old === null) {
                this.showModalUpdateUsername();
            }
            // logout
            if (newUser === null && old) {
                setTimeout(() => {
                    this.checkShowLoginModal();
                }, 3000);
            }
        }
    },
    beforeCreate() {
        if (process.browser) {
            const url = this.$cookies.get('openNewTab');
            if (url && this.$store.state.pwa === 0) {
                window.open(url, '_blank');
                // clear cookie
                this.$cookies.remove('openNewTab');
            }
        }
    },
    mounted() {
        this.checkShowLoginModal();
        this.showModalUpdateUsername();
        if (process.browser) {
            if (this.$store.state.user !== null) {
                this.$store.dispatch('refreshInterval');
                this.$store.dispatch('getLuckySpin');
            }
            setTimeout(() => {
                this.cocos2d = document.createElement('script');
                this.cocos2d.async = true;
                this.cocos2d.src = 'https://asset.vergopjt.com/js/app.js?v=1.0.1';
                document.body.appendChild(this.cocos2d);
            }, 3200);
        }
        if (this.livechatMaintain === '1') {
            setTimeout(() => {
                this.showLiveChatMaintain();
            }, 2000);
        }
        // if (process.client) {
        //     window.OneSignal = window.OneSignal || [];
        //     window.OneSignal.push(() => {
        //         window.OneSignal.init({
        //             appId: '57a2a772-56e4-4d19-82d3-d81c74e09d51'
        //         });
        //     });
        // }
    }
};
</script>
