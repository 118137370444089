<template>
  <!--            //Todo: add class for event noel-->
  <b-modal
      id="predictedTeamModal"
      dialog-class="modal-dialog-event"
      :content-class="['modal-wheel modal-wheel--predicted-team', {'holiday-custom': $isEnableThemeHoliday()}]"
      hide-footer
      ok-only
      no-stacking
      static
      :centered="$isEnableThemeHoliday()"
      @show="beforeInit"
  >
    <div class="modal-wrapper">
        <template v-if="$isEnableThemeHoliday()">
            <img v-if="$device.isMobile" class="special" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/dialog/special-mb.png`" alt="special" />
            <img v-else class="special" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/dialog/special.png`" alt="special" />
        </template>
        <template v-if="$isEnableThemeNoel()">
            <img v-if="$device.isMobile" class="special" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/dialog/special-mb.png`" alt="special" />
            <img v-else class="special" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/dialog/special.png`" alt="special" />
        </template>
      <template v-else>
            <img v-if="$device.isMobile" class="special" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/dialog/special-mb.png`" alt="special" />
            <img v-else class="special" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/dialog/special.png`" alt="special" />
        </template>
        <div class="modal-inner">
            <p v-if="totalReward > 0" class="modal-inner__desc">bạn có : <span>{{totalReward}}</span> lượt mở quà</p>
            <p  v-else class="modal-inner__desc">Bạn đã hết lượt mở quà</p>
            <div class="modal-inner__box">
                <button v-for="(item, index) in listBox" :key="index" class="modal-inner__item" @click="openJackpot">
                    <d-img class="bg-gift" :src="item.imgBg" alt="bg gift"/>
                    <d-img v-if="$isEnableThemeHoliday()" class="gift" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/dialog/gift.png`"  alt="gift"/>
                    <d-img  v-if="$isEnableThemeNoel()"  class="gift" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/dialog/gift.png`"  alt="gift"/>
                    <d-img v-else class="gift" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/dialog/gift.png`"  alt="gift"/>
                </button>
            </div>
        </div>
      <div class="modal-container scrollbar hide-scrollbar">
        <div class="loading" v-if="isLoading">
            <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/spin/loading-spec.gif`" alt="loading"/>
        </div>
        <div v-else class="team-list">
          <div class="event-letter__note text-center">
            <p v-html="eventWheelInfo.textSpecial"></p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { eventWheelInfo } from '@/resources/event-wheel';

export default {
    data() {
        return {
            eventWheelInfo,
            isLoading: false,
            isSubmit: false,
            rewards: [],
            countReward: {},
            totalReward: 0,
            listBox: [
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                },
                {
                    imgBg: '/assets/img/event/noel/dialog/bg-gift.png'
                }
            ]
        };
    },
    methods: {
        beforeInit() {
            this.$nextTick(async () => {
                this.countSpecial();
            });
        },
        async openJackpot() {
            if (this.totalReward > 0 && !this.isSubmit) {
                this.isSubmit = true;
                this.isLoading = true;
                try {
                    const response = await this.$eventWheelDefaultApi.d_openBoxRewardSpecial(this.rewards[0]);
                    console.log('WTF', response);
                    if (response.data.status === 'OK') {
                        this.$emit('checkRewardSpecialNoel', response.data.data[0] === 'money_fifty_thousand');
                        this.$bvModal.show('specialEventModal');
                        this.isSubmit = false;
                        this.isLoading = false;
                        await this.countSpecial();
                    }
                } catch (e) {
                    // todo: fake
                    // const { data, status } = await new Promise(resolve => setTimeout(resolve({ status: 'OK', data: ['money_fifty_thousand'] }), 1000));
                    // if (status === 'OK') {
                    //     await this.countSpecial();
                    //     this.$emit('checkRewardSpecialNoel', data[0]);
                    //     this.$bvModal.show('specialEventModal');
                    //     this.isSubmit = false;
                    //     this.isLoading = false;
                    // }
                    this.isLoading = false;
                    console.error(e);
                }
            }
        },
        async countSpecial() {
            try {
                const { data, status } = await this.$eventWheelDefaultApi.d_countSpecial(this.$store.state.user.username);

                if (status === 'OK') {
                    this.totalReward = data.total;
                    this.rewards = data.keys;
                }
            } catch (e) {
                console.error(e);
                // todo: fake data
                // const { keys, status, total } = await new Promise(resolve => setTimeout(resolve({ status: 'OK', keys: ['money_50mil'], total: 10 }), 1000)); // fake api
                // if (status === 'OK') {
                //     this.totalReward = total;
                //     this.rewards = keys;
                // }
            }
        }
    }
};
</script>
