import $ from 'jquery';

export default ({ app, route, env}, inject) => {
    /*
    ** Only run on client-side and only in production mode
    */
    // if (process.env.NODE_ENV !== 'production') return;
    if (route.name === 'banca') return;
    // app.store.state.device !== 'desktop' ||

    const user = app.store.state.user;
    const liveChatData = user !== null
        ? [
            { name: 'Login', value: user.username },
            { name: 'Desc', value: 'Đang đăng nhập' },
            { name: 'is_login', value: 'yes' },
            { name: 'is_deposit', value: 'yes' },
            { name: 'Balance', value: user.balance }
        ] : [
            { name: 'Login', value: 'guest' },
            { name: 'Desc', value: 'Chưa đăng nhập' },
            { name: 'is_login', value: 'no' },
            { name: 'is_deposit', value: 'no' }
        ];

    window.__lc = window.__lc || {};
    window.__lc.license = process.env.LIVE_CHAT_ID;
    window.__lc.params = liveChatData;

    window.LC_API = window.LC_API || {};

    window.LC_API.on_after_load = () => {
        // show button open
        app.store.commit('mutate', {
            property: 'livechatLoaded',
            with: true
        });
        if (window.innerWidth <= 992) {
            const interval = setInterval(() => {
                if (window.LC_API.chat_window_minimized()) {
                    window.LC_API.hide_chat_window();
                    clearInterval(interval);
                }
            }, 100);
        }
    };

    if (app.store.state.device === 'mobile') {
        $('#chat-widget-container').hide();
        // window.LC_API.on_before_load = () => {
        // };
        window.LC_API.on_chat_window_minimized = () => {
            window.LC_API.hide_chat_window();
        };
        // window.LC_API.on_chat_window_hidden = () => {
        // };
    }

    const openLiveChat = () => {
        if (!app.store.state.livechatLoaded) {
            return;
        }
        if (typeof window.LC_API.open_chat_window === 'function') {
            window.LC_API.open_chat_window();
            $('#chat-widget-container').show();
        }
    };
    inject('openLiveChat', openLiveChat);
    if (env.LIVECHAT_MAINTAIN === '0') {
        // eslint-disable-next-line func-names
        (function () {
            const lc = document.createElement('script'); lc.type = 'text/javascript'; lc.defer = true;
            lc.src = `${document.location.protocol === 'https:' ? 'https://' : 'http://'}cdn.livechatinc.com/tracking.js`;
            const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
        }());
    }
};
