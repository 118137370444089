export const state = () => ({
    titleModalFast: '',
    classCustomModalFast: '',
    listInfoModalFast: []
});
export const mutations = {
    setTitleModalFast(state, titleModalFast) {
        state.titleModalFast = titleModalFast;
    },
    setClassCustomModalFast(state, classCustomModalFast) {
        state.classCustomModalFast = classCustomModalFast;
    },
    setListInfoModalFast(state, listInfoModalFast) {
        state.listInfoModalFast = listInfoModalFast;
    }
};
