<template>
    <div class="header__bottom border-x">
        <div class="container flex-box just-center">
            <div class="header-menu">
                <ul class="header-menu_nav flex-box">
                    <li class="header-menu-item"
                    :class="{'show-sub' : typeof item.subMenu !== 'undefined',
                    'active': checkActiveMenu(item)}"
                    v-for="(item, index) in menus" :key="index">
                        <a @click.prevent="moveTo(item)" href="javascript:void(0)" class="header-menu-link custom-after" :target="item.newTab">
                            <s-img v-if="item.label === $LABEL_TYPE.LIVE" class="anim-light hot animation"
                                   src="/assets/img/header/label-live.svg" alt="hot"/> {{item.title}}
                            <span v-if="item.label === $LABEL_TYPE.HOT" class="menu-note hot animation">
                                <s-img src="/assets/img/header/icon-hot.svg" alt="hot"/>
                            </span>
                            <span v-if="item.label === $LABEL_TYPE.LABEL_EVENT" class="menu-note animation">
                                <s-img src="/assets/img/event/icon-event.svg" alt="new"/>
                            </span>
                        </a>
                        <ul class="header-menu-sub">
                            <li class="header-menu-item" v-for="(subItem, i) in item.subMenu" :key="i"
                                :class="{'event': subItem.event,
                                'active': $route.fullPath === subItem.link}">
                                <a v-if="subItem.type === 'SOCIAL'" :target=" subItem.title === 'HOTLINE' ? '_self' : '_blank'" class="header-menu-link" :href="subItem.link">
                                    <img v-if="subItem.icon" class="icon" :src="subItem.icon" :alt="subItem.name">
                                    <div class="text">
                                        {{subItem.title}}
                                        <span v-if="subItem.label === $LABEL_TYPE.HOT" class="menu-note hot animation">
                                        <s-img src="/assets/img/header/icon-hot.svg" alt="hot"/>
                                    </span>
                                        <span v-if="subItem.label === $LABEL_TYPE.NEW" class="menu-note animation">
                                        <s-img src="/assets/img/header/icon-new.svg" alt="new"/>
                                    </span>
                                        <span v-if="subItem.label === $LABEL_TYPE.LABEL_EVENT" class="menu-note animation event">
                                        <s-img src="/assets/img/event/icon-event.svg" alt="new"/>
                                    </span>
                                    </div>
                                </a>
                                <a v-else class="header-menu-link" @click.prevent="moveToSub(subItem, $event)" href="javascript:void(0)" :class="{'coming': subItem.coming}" :title="subItem.title">
                                    <img v-if="subItem.icon"  class="icon" :src="subItem.icon" :alt="subItem.name">
                                    <div class="text">
                                        {{subItem.title}}
                                        <span v-if="subItem.label === $LABEL_TYPE.HOT" class="menu-note hot animation">
                                        <s-img src="/assets/img/header/icon-hot.svg" alt="hot"/>
                                    </span>
                                        <span v-if="subItem.label === $LABEL_TYPE.NEW" class="menu-note animation">
                                        <s-img src="/assets/img/header/icon-new.svg" alt="new"/>
                                    </span>
                                        <span v-if="subItem.label === $LABEL_TYPE.LABEL_EVENT" class="menu-note animation event">
                                        <s-img src="/assets/img/event/icon-event.svg" alt="new"/>
                                    </span>
                                    </div>
                                    <img v-if="subItem.coming" class="label" :src="subItem.coming" alt="coming">
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import globalFuncMixin from '@/mixins/global-func-mixin';

export default {
    props: ['currentTab', 'user'],
    mixins: [globalFuncMixin],
    components: {
    },
    data() {
        return {
            menus: [
                {
                    title: 'THỂ THAO',
                    link: '/the-thao',
                    loginRequired: false,
                    label: this.$LABEL_TYPE.EVENT_WHEEL,
                    newTab: '',
                    subMenu: [
                        {
                            title: 'A - Thể Thao',
                            link: '/the-thao-chau-a',
                            loginRequired: false,
                            label: this.$LABEL_TYPE.HOT,
                            icon: '/assets/img/header/a-sport.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'E - Thể Thao',
                            link: '/the-thao-chau-au',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/e-sport.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'K - Thể Thao',
                            link: '/ksport',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/k-sport.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'ĐUA NGỰA 3D',
                            link: '/dua-ngua-3d',
                            loginRequired: true,
                            label: this.$LABEL_TYPE.NEW,
                            icon: '/assets/img/header/house-3d.svg',
                            display_types: [3],
                            type: 'GAME',
                            partner_provider: 'vingame',
                            partner_game_id: 'kts6998'
                        },
                        {
                            title: 'THỂ THAO ẢO',
                            link: '/the-thao-ao',
                            loginRequired: false,
                            label: '',
                            newTab: '',
                            icon: '/assets/img/header/esport.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'THỂ THAO ĐIỆN TỬ',
                            link: '/the-thao-so',
                            loginRequired: true,
                            label: '',
                            newTab: true,
                            icon: '/assets/img/header/e-sports.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'VÀO SẢNH',
                            link: '/the-thao',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/go-lobby.svg',
                            type: 'PAGE'
                        }
                    ]
                },
                {
                    title: 'CASINO',
                    link: '/livecasino',
                    loginRequired: false,
                    label: this.$LABEL_TYPE.LIVE,
                    newTab: '',
                    subMenu: [
                        {
                            title: 'SEXY CASINO',
                            link: '/livecasino?type=sexy-casino',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/sexy-casino.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'SẢNH ATLANTIC',
                            link: '/livecasino?type=lobby-atlantic',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/alantic.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'SẢNH ĐÔNG DƯƠNG',
                            link: '/livecasino?type=lobby-asia',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/las-vegas.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'Vào sảnh',
                            link: '/livecasino',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/go-lobby.svg',
                            type: 'PAGE'
                        }
                    ]
                },
                {
                    title: 'TRÒ CHƠI',
                    link: '/tro-choi',
                    loginRequired: false,
                    label: '',
                    newTab: '',
                    subMenu: [
                        {
                            title: 'BẮN CÁ',
                            link: '/tro-choi?tab=macau&type=ban-ca',
                            loginRequired: false,
                            icon: '/assets/img/header/ban-ca.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'NỔ HŨ',
                            link: '/tro-choi?tab=macau&type=no-hu',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/no-hu.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'QUAY SỐ',
                            link: '/tro-choi?tab=macau&type=quay-so',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/lottery.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'INGAME',
                            link: '/tro-choi?tab=macau&type=ingame',
                            loginRequired: false,
                            label: this.$LABEL_TYPE.NEW,
                            icon: '/assets/img/header/ingame.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'TABLE GAMES',
                            link: '/tro-choi?tab=macau&type=danh-bai',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/table-game.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'LÔ ĐỀ',
                            link: '/tro-choi-lo-de',
                            loginRequired: false,
                            label: this.$LABEL_TYPE.HOT,
                            icon: '/assets/img/header/lode.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'KENO',
                            link: '/keno',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/keno.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'NUMBERS GAME',
                            link: '/tro-choi-so',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/number-game.svg',
                            type: 'PAGE'
                        },
                        {
                            title: 'Vào sảnh',
                            link: '/tro-choi',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/go-lobby.svg',
                            type: 'PAGE'
                        }
                    ]
                },
                {
                    title: 'GAME BÀI',
                    link: '/tro-choi?type=game-bai',
                    loginRequired: false,
                    label: this.$LABEL_TYPE.HOT,
                    newTab: '',
                    subMenu: [
                        {
                            title: 'BẦU CUA',
                            link: '/',
                            loginRequired: true,
                            label: this.$LABEL_TYPE.HOT,
                            icon: '/assets/img/header/bau-cua.svg',
                            id: 1931,
                            display_types: [15],
                            type: 'GAME',
                            partner_provider: 'go',
                            partner_game_id: 'vgcg_14'
                        },
                        {
                            title: 'XÓC ĐĨA',
                            link: '/',
                            loginRequired: true,
                            label: '',
                            icon: '/assets/img/header/xocdia.svg',
                            id: 1928,
                            display_types: [15],
                            type: 'GAME',
                            partner_provider: 'go',
                            partner_game_id: 'vgcg_9'
                        },
                        {
                            title: 'XÌ DÁCH',
                            link: '/',
                            loginRequired: true,
                            label: '',
                            icon: '/assets/img/header/xi-dach.svg',
                            id: 909,
                            display_types: [5, 15],
                            type: 'GAME',
                            partner_provider: 'vingame',
                            partner_game_id: 'ktc7994'
                        },
                        {
                            title: 'TIẾN LÊN',
                            link: '/',
                            loginRequired: true,
                            label: '',
                            icon: '/assets/img/header/tien-len.svg',
                            id: 1921,
                            display_types: [1, 15],
                            type: 'GAME',
                            partner_provider: 'go',
                            partner_game_id: 'vgcg_1'
                        },
                        {
                            title: 'MẬU BINH',
                            link: '/',
                            loginRequired: true,
                            label: '',
                            icon: '/assets/img/header/mau-binh.svg',
                            id: 1922,
                            display_types: [15],
                            type: 'GAME',
                            partner_provider: 'go',
                            partner_game_id: 'vgcg_4'
                        },
                        {
                            title: 'LIÊNG',
                            link: '/',
                            loginRequired: true,
                            label: '',
                            icon: '/assets/img/header/lieng.svg',
                            id: 1923,
                            display_types: [15],
                            type: 'GAME',
                            partner_provider: 'go',
                            partner_game_id: 'vgcg_5'
                        },
                        {
                            title: 'PHỎM',
                            link: '/',
                            loginRequired: true,
                            label: '',
                            icon: '/assets/img/header/phom.svg',
                            id: 1924,
                            display_types: [15],
                            type: 'GAME',
                            partner_provider: 'go',
                            partner_game_id: 'vgcg_8'
                        },
                        {
                            title: 'POKER',
                            link: '/',
                            loginRequired: true,
                            label: '',
                            icon: '/assets/img/header/poker.svg',
                            id: 1925,
                            display_types: [15],
                            type: 'GAME',
                            partner_provider: 'go',
                            partner_game_id: 'vgcg_6'
                        },
                        {
                            title: 'SÂM',
                            link: '/',
                            loginRequired: true,
                            label: '',
                            icon: '/assets/img/header/sam.svg',
                            id: 1926,
                            display_types: [15],
                            type: 'GAME',
                            partner_provider: 'go',
                            partner_game_id: 'vgcg_2'
                        },
                        {
                            title: 'XÌ TỐ',
                            link: '/',
                            loginRequired: true,
                            label: '',
                            icon: '/assets/img/header/xito.svg',
                            id: 1927,
                            display_types: [15],
                            type: 'GAME',
                            partner_provider: 'go',
                            partner_game_id: 'vgcg_7'
                        },
                        {
                            title: 'Vào sảnh',
                            link: '/tro-choi?type=game-bai',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/go-lobby.svg',
                            type: 'PAGE'
                        }
                    ]
                },
                {
                    title: 'HỖ TRỢ',
                    link: '/huong-dan',
                    loginRequired: false,
                    label: '',
                    newTab: '',
                    subMenu: [
                        {
                            title: 'HOTLINE',
                            link: `tel:${this.$t('contact.phone')}`,
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/hot-line.svg',
                            type: 'SOCIAL'
                        },
                        {
                            title: 'EMAIL',
                            link: `mailto:${this.$t('contact.email')}`,
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/email.svg',
                            type: 'SOCIAL'
                        },
                        // {
                        //     title: 'ZALO',
                        //     link: `https://zalo.me/${this.$t('contact.zalo')}`,
                        //     loginRequired: false,
                        //     label: '',
                        //     icon: '/assets/img/header/zalo.svg',
                        //     type: 'SOCIAL'
                        // },
                        // {
                        //     title: 'TELEGRAM',
                        //     link: '/',
                        //     loginRequired: false,
                        //     label: '',
                        //     icon: '/assets/img/header/telegram.svg',
                        //     type: 'SOCIAL'
                        // },
                        {
                            title: 'Xem thêm',
                            link: '/huong-dan',
                            loginRequired: false,
                            label: '',
                            icon: '/assets/img/header/go-lobby.svg',
                            type: 'PAGE'
                        }
                    ]
                },
                // {
                //     title: 'SỰ KIỆN',
                //     link: '/worldcup',
                //     loginRequired: false,
                //     label: this.$LABEL_TYPE.HOT,
                //     newTab: '',
                //     subMenu: [
                //         {
                //             title: 'Vòng Quay Quatar',
                //             link: '/worldcup',
                //             loginRequired: false,
                //             label: this.$LABEL_TYPE.HOT,
                //             type: 'PAGE',
                //             event: true
                //         },
                //         {
                //             title: 'truy tìm cúp vàng',
                //             link: '/goal-scorer',
                //             loginRequired: false,
                //             label: this.$LABEL_TYPE.HOT,
                //             type: 'PAGE',
                //             event: true
                //         }
                //     ]
                // },
                {
                    title: 'KHUYẾN MÃI',
                    link: '/khuyen-mai',
                    loginRequired: false,
                    label: '',
                    newTab: ''
                },
                {
                    title: 'TIN TỨC',
                    link: '/tin-tuc',
                    loginRequired: false,
                    label: '',
                    newTab: ''
                }
            ],
            menuDaily: {
                title: 'DAILY EVENT',
                link: '/daily-event',
                loginRequired: false,
                label: '',
                newTab: ''
            },
            menuSeagame: {
                title: 'EVENT',
                link: '/seagames',
                loginRequired: false,
                label: this.$LABEL_TYPE.HOT,
                newTab: ''
            }
        };
    },
    watch: {
    },
    methods: {
        moveTo(item) {
            if (item.link === '/the-thao-so') {
                this.$openEsports();
            } else {
                this.$router.push(item.link);
            }
        },
        moveToSub(item, e) {
            const el = $(e.currentTarget).closest('.header-menu-item.show-sub');
            if (el) {
                el.removeClass('hover');
            }
            this.$_globalMixin_moveTo(item);
        },
        toggleSubMenu() {
            $('.show-sub').hover(
                (e) => {
                    $(e.currentTarget).addClass('hover');
                }, (e) => {
                    $(e.currentTarget).removeClass('hover');
                }
            );
        },
        checkActiveMenu(item) {
            return this.$route.fullPath === item.link || item.subMenu?.find(i => this.$route.fullPath === i.link && i.link !== '/');
        }
    },
    mounted() {
        this.toggleSubMenu();
        if (this.$isEnableThemeDaily()) {
            this.menus.push(this.menuDaily);
        }
        if (this.$isEnableThemeSeagame()) {
            this.menus.push(this.menuSeagame);
        }
    }
};
</script>
