const apiVersion = `${process.env.EVENT_WORLD_CUP_API_PATH}/world-cup-goal`;

export default ($axios) => ({
    roll(turn = 1, listPlayer) {
        return $axios.$post(`${apiVersion}/roll?t=${Math.random() * 10}`, {
            turn,
            listPlayer
        }, {
            withCredentials: true
        });
    },
    userTurn() {
        return $axios.$get(`${apiVersion}/user/turn?t=${Math.random() * 10}`, {
            withCredentials: true
        });
    },
    userHistory(page = 1, limit = 10) {
        return $axios.$get(`${apiVersion}/user/history?t=${Math.random() * 10}`, {
            withCredentials: true,
            params: {
                page,
                limit
            }
        });
    },
    dailyWinner(page = 1, limit = 10) {
        return $axios.$get(`${apiVersion}/history?t=${Math.random() * 10}`, {
            params: {
                page,
                limit
            }
        });
    },
    getRewards() {
        return $axios.$get(`${apiVersion}/rewards?t=${Math.random() * 10}`);
    },
    getListTopGoalScore(page = 1, limit = 10) {
        return $axios.$get(`${apiVersion}/list-goal-score?t=${Math.random() * 10}`, {
            params: {
                page,
                limit
            },
            withCredentials: true
        });
    },
    getListTopGoal(page = 1, limit = 10) {
        return $axios.$get(`${apiVersion}/list-top-goal?t=${Math.random() * 10}`, {
            params: {
                page,
                limit
            },
            withCredentials: true
        });
    },
    getListLogoTeam(logo = '', offset = 0, limit = 10) {
        return $axios.$get(`${apiVersion}/list-logo?t=${Math.random() * 10}`, {
            params: {
                logo,
                offset,
                limit
            },
            withCredentials: true
        });
    },
    redeemReward(logo, numberLogo) {
        return $axios.$post(`${apiVersion}/redeem-reward?t=${Math.random() * 10}`, {
            logo,
            numberLogo
        }, {
            withCredentials: true
        });
    },
    getSchedule(params) {
        return $axios.$get(`${apiVersion}/schedule?t=${Math.random() * 10}`, {
            params
        });
    }
});
