<template>
    <b-modal
            id="specialEventModal"
            dialog-class="modal-dialog-event"
            content-class="modal-wheel modal-wheel--special"
            hide-footer
            hide-header
            ok-only
            no-stacking
            centered
    >
        <div class="modal-wrapper">
            <div class="modal-inner text-center">
                <div v-if="isRewardSpecial">
                  <div class="modal-inner__text text-center">
                    <div class="title">CHÚC MỪNG BẠN ĐÃ TRÚNG GIẢI ĐẶC BIỆT</div>
                   </div>
                    <d-img v-if="$isEnableThemeHoliday()" class="modal-inner__img" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/dialog/box-500.png`" alt="special"/>
                    <d-img v-else class="modal-inner__img" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/dialog/box-500.png`" alt="special"/>
                </div>
                <div v-else>
                    <d-img v-if="$isEnableThemeHoliday()" class="modal-inner__img" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/dialog/box-lucky.png`" alt="special"/>
                    <d-img v-else class="modal-inner__img" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/dialog/box-lucky.png`" alt="special"/>
                    <div class="modal-inner__text style-2 text-center">
                        <p>CHÚC BẠN</p>
                        <div class="title">MAY MẮN LẦN SAU</div>
                    </div>
                </div>
                <div class="modal-inner__button">
                    <a
                        @click="opendReward()"
                        data-toggle="modal"
                        href="javascript:void(0)"
                        class="button background-2"
                    >
                        MỞ QUÀ TIẾP
                    </a>
                    <a
                        v-if="isRewardSpecial"
                        @click="moveTo()"
                        data-toggle="modal"
                        href="javascript:void(0)"
                        class="button"
                    >
                        NHẬN THƯỞNG
                    </a>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    props: {
        isRewardSpecial: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        opendReward() {
            this.$bvModal.hide('specialEventModal');
            this.$bvModal.show('predictedTeamModal');
        },
        moveTo() {
            this.$bvModal.hide('specialEventModal');
            this.$bvModal.show('historyPredictionModal');
        }
    }
};
</script>
