<template>
    <b-modal id="forgotPasswordModal" content-class="modal--full-height" hide-footer @show="resetModal"
      @hidden="resetModal">
      <template v-slot:modal-title>
        QUÊN MẬT KHẨU
      </template>
      <form class="form" @submit.prevent="submit">
        <div class="form__custom" :class="{'error' : $v.formData.email.$error || error}">
          <label>Địa chỉ email</label>
            <input v-model.trim="$v.formData.email.$model" class="form-input" placeholder="Nhập email đã đăng ký"  type="text">
            <div v-if="$v.formData.email.$error || error">
                <p v-if="!$v.formData.email.required" class="form-note">{{$t('errors.email.required')}}</p>
                <p v-if="!$v.formData.email.email" class="form-note">{{$t('errors.email.email')}}</p>
                <p v-if="!$v.formData.email.$error" class="form-note">{{error}}</p>
            </div>
        </div>
        <div>Link tạo mật khẩu mới sẽ được gửi tới email mà quý khách đã đăng ký</div>
        <d-button
            :apply="submit"
            :disabled="isSubmit"
            :loading="isSubmit" type="submit" class="button">
            GỬI
        </d-button>
       </form>
    </b-modal>
</template>
<script>
import { validationMixin } from 'vuelidate';
import {
    required, email
} from 'vuelidate/lib/validators';

export default {
    mixins: [validationMixin],
    data() {
        return {
            formData: {
                email: ''
            },
            error: '',
            isSubmit: false
        };
    },
    validations: {
        formData: {
            email: {
                required,
                email
            }
        }
    },
    methods: {
        async submit() {
            try {
                this.$v.$touch();
                if (!this.$v.$invalid && !this.isSubmit) {
                    this.error = '';
                    this.isSubmit = true;
                    const data = await this.$userApi.verifyEmail({ ...this.formData });
                    if (data.exist) {
                        const res = await this.$userApi.forgotPassword({ ...this.formData });
                        if (res.status === 'OK') {
                            this.$alert(res.message);
                            setTimeout(() => {
                                this.$Swal.close();
                                this.$bvModal.hide('forgotPasswordModal');
                            }, 2500);
                        } else {
                            this.error = 'Hệ thống đang bảo trì. Vui lòng quay lại sau';
                        }
                    } else {
                        this.error = 'Email không tồn tại trong hệ thống.';
                    }
                    this.isSubmit = false;
                }
            } catch (e) {
                this.isSubmit = false;
            }
        },
        resetModal() {
            this.$v.$reset();
            this.formData.email = '';
        }
    }
};
</script>
