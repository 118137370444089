export const eventSeagameInfo = {
    prizes: {
        1: [
            12
        ],
        2: [
            2
        ],
        3: [
            6
        ],
        4: [
            8
        ],
        5: [
            11
        ],
        6: [
            3
        ],
        7: [
            7
        ],
        8: [
            9
        ],
        9: [
            5
        ],
        10: [
            1
        ],
        11: [
            4,
            10
        ]
    },
    spinMap: {
        1: {
            key: 1,
            name: '10.000',
            number: 10,
            type: 'money_ten'
        },
        2: {
            key: 2,
            name: '30.000',
            number: 30,
            type: 'money_thirty'
        },
        3: {
            key: 3,
            name: '100.000',
            number: 100,
            type: 'money_one_hundred'
        },
        4: {
            key: 4,
            name: '200.000',
            number: 200,
            type: 'money_two_hundred'
        },
        5: {
            key: 5,
            name: '500.000',
            number: 500,
            type: 'money_five_hundred'
        },
        6: {
            key: 6,
            name: '1.000.000',
            number: 1000,
            type: 'money_one_thousand'
        },
        7: {
            key: 7,
            name: 'refund',
            number: 0,
            type: 'super_return'
        },
        8: {
            key: 8,
            name: 'copper',
            number: 0,
            type: 'bronze_medal'
        },
        9: {
            key: 9,
            name: 'silver',
            number: 0,
            type: 'silver_medal'
        },
        10: {
            key: 10,
            name: 'gold',
            number: 0,
            type: 'gold_medal'
        },
        11: {
            key: 11,
            name: 'lucky',
            number: 0,
            type: 'lucky'
        }
    },
    listBox: [
        {
            icon: '/assets/img/event/seagame/banner/ic_login.png',
            title: 'ĐĂNG NHẬP 24H',
            func: 'login',
            isShowNotLogin: true
        },
        {
            icon: '/assets/img/event/seagame/banner/ic_betting.png',
            title: 'CƯỢC 1 TRIỆU',
            func: 'bet',
            text: 'CƯỢC NGAY'
        },
        {
            icon: '/assets/img/event/seagame/banner/ic_money.png',
            title: 'NẠP 500K',
            func: 'deposit',
            text: 'NẠP TIỀN NGAY'
        }
    ],
    prizeList: [
        {
            icon: '/assets/img/event/seagame/prize-1000.png',
            title: 'GIẢI TIỀN MẶT: <span> 1.000.000</span><img src="/assets/img/event/seagame/coin.png" alt="coin" />',
            sub: 'Số lượng giải còn lại: <span>99/100</span>',
            type: 'money_one_thousand'
        },
        {
            icon: '/assets/img/event/seagame/prize-500.png',
            title: 'GIẢI TIỀN MẶT: <span> 500.000</span><img src="/assets/img/event/seagame/coin.png" alt="coin" />',
            sub: 'Số lượng giải còn lại: <span>99/100</span>',
            type: 'money_five_hundred'
        },
        {
            icon: '/assets/img/event/seagame/prize-200.png',
            title: 'GIẢI TIỀN MẶT: <span> 200.000</span><img src="/assets/img/event/seagame/coin.png" alt="coin" />',
            sub: 'Số lượng giải còn lại: <span>99/100</span>',
            type: 'money_two_hundred'
        },
        {
            icon: '/assets/img/event/seagame/prize-100.png',
            title: 'GIẢI TIỀN MẶT: <span> 100.000</span><img src="/assets/img/event/seagame/coin.png" alt="coin" />',
            sub: 'Số lượng giải còn lại: <span>99/100</span>',
            type: 'money_one_hundred'
        },
        {
            icon: '/assets/img/event/seagame/prize-30.png',
            title: 'GIẢI TIỀN MẶT: <span> 30.000</span><img src="/assets/img/event/seagame/coin.png" alt="coin" />',
            sub: 'Số lượng giải còn lại: <span>99/100</span>',
            type: 'money_thirty'
        },
        {
            icon: '/assets/img/event/seagame/prize-10.png',
            title: 'GIẢI TIỀN MẶT: <span> 10.000</span><img src="/assets/img/event/seagame/coin.png" alt="coin" />',
            sub: 'Số lượng giải còn lại: <span>99/100</span>',
            type: 'money_ten'
        }
    ],
    listInfoRule: [
        {
            icon: '/assets/img/event/seagame/tabs/number-one.svg',
            content: [
                {
                    text: 'Đăng nhập mỗi ngày nhận lược <span>1 lượt quay</span> ( tối đa 1lượt/24h).'
                },
                {
                    text: 'Người chơi NẠP tối thiểu <span>500.000 VND</span> sẽ được nhận 1 lượt quay vòng quay.'
                },
                {
                    text: 'Tham gia cược từ <span>1.000.000 VND</span> sẽ nhận được 1 lượt quay vòng quay.'
                }
            ]
        },
        {
            icon: '/assets/img/event/seagame/tabs/number-two.svg',
            content: [
                {
                    text: 'Lượt chơi sẽ được dùng để tham gia chương trình.'
                },
                {
                    text: '1 lượt chơi, khách hàng sẽ được quay vòng quay 1 lần.'
                },
                {
                    text: 'Tương ứng mỗi lượt quay, khách hàng sẽ có cơ hội săn huy chương và các giải thưởng tiền mặt khác.'
                },
                {
                    text: 'Sưu tập huy chương và nằm trong Top người chơi, khách hàng sẽ trúng các giải thưởng trị giá cao.'
                },
                {
                    text: 'Huy chương Vàng: +3 điểm.'
                },
                {
                    text: 'Huy chương Bạc: +2 điểm.'
                },
                {
                    text: 'Huy chương Đồng: +1 điểm.'
                },
                {
                    text: 'Top 10 người chơi có số điểm cao nhất chia đều giải thưởng đặc biệt có giá trị bằng <br/><span>Số HCV của đội tuyển VIỆT NAM tại Seagames 31 x 5.000.000 VND.</span>'
                }
            ]
        },
        {
            icon: '/assets/img/event/seagame/tabs/number-three.svg',
            content: [
                {
                    text: 'Điều kiện rút thưởng:'
                },
                {
                    text: 'Hoàn thành 1 vòng cược doanh thu.'
                },
                {
                    text: 'Khách hàng có TKNH Chính Chủ mới được rút.'
                },
                {
                    text: 'Vé nạp và vé cược phải hợp lệ mới được tính trúng giải. Trong trường hợp vé không hợp lệ, khách dùng lượt quay đó để quay trung giải thì giải thưởng sẽ bị thu hồi.'
                },
                {
                    text: 'Chương trình sẽ hủy toàn bộ huy chương đã sưu tập, tiền thưởng của Quý khách nếu phát hiện có vé cược gian lận.'
                }
            ]
        }
    ],
    conditionList: [
        {
            text: 'Giải thưởng tiền mặt sẽ được cộng trực tiếp vào tài khoản tối đa 24h sau khi quay trúng.'
        },
        {
            text: 'Danh sách trúng giải đặc biệt sẽ được công bố sau ngày <span>31/05/2022</span>.'
        },
        {
            text: 'Giải thưởng chỉ được duyệt rút về các tài khoản ngân hàng đã đăng ký <span>NGÂN HÀNG CHÍNH CHỦ</span> (Tài khoản ngân hàng Nạp và Rút đã được hệ thống xác nhận).'
        },
        {
            text: 'Các quy định chung về khuyến mãi, bảo mật, điều khoản và miễn trách nhiệm của Oxbet sẽ được áp dụng.'
        }
    ],
    // eslint-disable-next-line max-len
    note: 'Oxbet sẽ tiến hành xem xét quá trình đặt cược của Người chơi và trong trường hợp phát hiện có bất kỳ một hành vi nào lạm dụng tiền thưởng khuyến mãi, chúng tôi có quyền hủy bỏ tất cả số tiền thắng cược và giải thưởng trúng được đã nhận từ các vé cược - Vi phạm như đặt cược hai bên , cược chéo , cược gấp thấp trong cùng một trận/thị trường, đặt cược nhiều cho cùng một kết quả trong cùng một trận/thị trường.  Và các cược hòa, cược hủy , cược kiểu HK tỷ lệ dưới 0.65 (tương đương cho các kiểu cược khác như Malay odd dưới 0.65; Indo odd dưới -1.53; và Dec odd dưới 1.65) sẽ không được tính vào tổng tiền đặt cược.',
    listEarnTurn: [
        {
            icon: '/assets/img/event/seagame/tabs/ic_login.png',
            title: 'ĐĂNG NHẬP 24H',
            sub: 'NHẬN <span>1 LƯỢT</span> QUAY',
            func: 'login',
            text: 'ĐĂNG NHẬP',
            isShowNotLogin: true
        },
        {
            icon: '/assets/img/event/seagame/tabs/ic_betting.png',
            title: 'CƯỢC 1 TRIỆU',
            sub: 'NHẬN <span>1 LƯỢT</span> QUAY',
            func: 'bet',
            text: 'CƯỢC NGAY'
        },
        {
            icon: '/assets/img/event/seagame/tabs/ic_money.png',
            title: 'NẠP 500K',
            sub: 'NHẬN <span>1 LƯỢT</span> QUAY',
            func: 'deposit',
            text: 'NẠP TIỀN NGAY'
        }
    ],
    earnTurnNote: '<p>Cùng tham gia Sự kiện <span>VÒNG QUAY SEAGAMES 31</span> để nhận được nhiều phần thưởng giá trị!</p>'
};
