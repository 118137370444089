<template>
  <div class="condition-wrapper">
    <div class="condition">
      <div class="condition__bg text-center">
        <template v-if="$isEnableThemeHoliday()">
          <d-img v-if="$device.isMobile" :src="`${$store.state.mapDomain.STATIC_URL}${eventWheelInfo.bannerRuleMBHoliday}`" alt="banner"/>
          <d-img v-else :src="`${$store.state.mapDomain.STATIC_URL}${eventWheelInfo.bannerRuleHoliday}`" alt="banner"/>
        </template>
        <template v-else>
          <d-img v-if="$device.isMobile" :src="`${$store.state.mapDomain.STATIC_URL}${eventWheelInfo.bannerRuleMB}`" alt="banner"/>
          <d-img v-else :src="`${$store.state.mapDomain.STATIC_URL}${eventWheelInfo.bannerRule}`" alt="banner"/>
        </template>
        <span>{{ eventWheelInfo.timeToLive}}</span>
      </div>
      <div class="condition__content">
        <ul class="rules__list">
          <li v-for="(item, index) in eventWheelInfo.listRuler" :key="index" class="rules__item">
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="condition__note">
        <div class="note-inner">
          <div class="note-inner__left">LƯU Ý</div>
          <div class="note-inner__right">
            {{ eventWheelInfo.ruleNote }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-button">
      <a
          @click="$bvModal.hide('rulesConditionModal')"
          data-toggle="modal" href="javascript:void(0)"
          class="btn-login-monile button background-2 padding-2 hidden"
      >
        QUAY LẠI
      </a>
      <a
          @click="$emit('click')"
          data-toggle="modal"
          href="javascript:void(0)"
          class="button show-desktop"
      >
        CƯỢC NGAY
      </a>
    </div>
  </div>
</template>
<script>
import { eventWheelInfo } from '@/resources/event-wheel';

export default {
    data() {
        return {
            eventWheelInfo
        };
    }
};
</script>
