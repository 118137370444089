<template>
  <div class="rules-wrapper">
    <div class="rules">
        <div class="rules__bg text-center">
          <template v-if="$isEnableThemeHoliday()">
            <d-img v-if="$device.isMobile" :src="`${$store.state.mapDomain.STATIC_URL}${eventWheelInfo.bannerRuleMBHoliday}`" alt="banner"/>
            <d-img v-else :src="`${$store.state.mapDomain.STATIC_URL}${eventWheelInfo.bannerRuleHoliday}`" alt="banner"/>
          </template>
          <template v-else>
            <d-img v-if="$device.isMobile" :src="`${$store.state.mapDomain.STATIC_URL}${eventWheelInfo.bannerRuleMB}`" alt="banner"/>
            <d-img v-else :src="`${$store.state.mapDomain.STATIC_URL}${eventWheelInfo.bannerRule}`" alt="banner"/>
          </template>
          <span>{{
              eventWheelInfo.timeToLive
            }}</span>
        </div>
        <div v-if="$device.isMobile" class="rules__accordion" role="tablist">
            <b-card no-body v-for="(item, inxd) in eventWheelInfo.termList" :key="inxd">
                <b-card-header block header-tag="header" role="tab" v-b-toggle="'accordion-' + inxd" variant="info">
                    <d-img :src="item.icon" alt="icon"/>
                    <div class="title">{{ item.title }}</div>
                </b-card-header>
                <b-collapse :id="'accordion-' + inxd" :visible="inxd === 0" accordion="accordion-term" role="tabpanel">
                    <ul class="rules__list">
                        <li v-for="(i, inx) in item.content" :key="inx" class="rules__item" v-html="i.text">
                        </li>
                    </ul>
                </b-collapse>
            </b-card>
        </div>
      <div v-else v-for="(item, index) in eventWheelInfo.termList" :key="index" class="rules__inner">
        <div class="rules__icon">
            <d-img :src="item.icon" alt="icon"/>
        </div>
        <ul class="rules__list">
          <li v-for="(i, inx) in item.content" :key="inx" class="rules__item" v-html="i.text">
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-button">
      <a
          @click="$bvModal.hide('rulesConditionModal')"
          data-toggle="modal" href="javascript:void(0)"
          class="btn-login-monile button background-2 padding-2 hidden"
      >
        QUAY LẠI
      </a>
      <a
          @click="$emit('click')"
          data-toggle="modal"
          href="javascript:void(0)"
          class="button show-desktop"
      >
        CƯỢC NGAY
      </a>
    </div>
  </div>
</template>
<script>
import { eventWheelInfo } from '@/resources/event-wheel';

export default {
    data() {
        return {
            eventWheelInfo
        };
    }
};
</script>
