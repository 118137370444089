export const eventWheelInfo = {
    prizes: {
        20: [
            7,
            11
        ],
        10: [
            4,
            6,
            8
        ],
        5000: [
            3
        ],
        50: [
            10
        ],
        0: [
            1
        ],
        8: [
            2,
            5,
            9,
            12
        ]
    },
    rewardMapping: {
        money_ten: {
            order: 5,
            name: '10.000',
            type: 'money_ten'
        },
        money_twenty: {
            order: 4,
            name: '20.000',
            type: 'money_twenty'
        },
        money_fifty: {
            order: 3,
            name: '50.000',
            type: 'money_fifty'
        },
        money_fifty_thousand: {
            order: 1,
            name: 'Giải đặc biệt',
            type: 'money_fifty_thousand'
        },
        money_five_thousand: {
            order: 2,
            name: '5.000.000',
            type: 'money_five_thousand'
        }
    },
    spinMap: {
        1: {
            name: '10.000',
            number: 10,
            type: 'money_ten'
        },
        2: {
            name: '20.000',
            number: 20,
            type: 'money_twenty'
        },
        3: {
            name: '50.000',
            number: 50,
            type: 'money_fifty'
        },
        4: {
            name: 'Giải đặc biệt',
            number: 0,
            type: 'money_fifty_thousand'
        },
        5: {
            name: '5.000.000',
            number: 5000,
            type: 'money_five_thousand'
        },
        6: {
            name: 'lucky',
            number: 8,
            type: 'lucky'
        }
    },
    amountSpecial: '50.000.000',
    timeToLive: 'Thời gian diễn ra : 17/06/2023 - 31/07/2023',
    textNoteHisory: 'Khi quay trúng giải này bạn sẽ chọn 1 hộp trong số 12 hộp để mở quà.<br/> Hộp trúng thưởng có giá trị <span>50.000.000 VNĐ</span>.',
    textSpecial: 'Mở trúng hộp quà đặc biệt, bạn sẽ nhận được <br> giải thưởng trị giá <span>50.000.000 VNĐ (*)</span>',
    bannerRule: '/assets/img/event/wheel/dialog/banner-rule.png',
    bannerRuleHoliday: '/assets/img/event/holiday/dialog/banner-rule.png',
    bannerRuleMB: '/assets/img/event/wheel/dialog/banner-rule-mb.png',
    bannerRuleMBHoliday: '/assets/img/event/holiday/dialog/banner-rule-mb.png',
    listRuler: [
        {
            text: 'Giải thưởng tiền mặt sẽ được cộng trực tiếp vào tài khoản tối đa 24h sau khi quay trúng.'
        },
        {
            text: 'Các phiếu cược vi phạm như đặt cược hai bên, cược chéo, cược gấp thấp trong cùng một trận/thị trường, đặt cược nhiều cho cùng một kết quả trong cùng một trận/thị trường. Và các cược hủy, cược kiểu HK tỷ lệ dưới 0.65 (tương đương cho các kiểu cược khác như Malay odd dưới 0.65; Indo odd dưới -1.53; và Dec odd dưới 1.65) sẽ bị hủy giải thưởng dù người chơi đã quay và trúng thưởng.'
        },
        {
            text: 'Hoàn thành 1 vòng cược doanh thu'
        },
        {
            text: 'Giải thưởng chỉ được duyệt rút về các tài khoản ngân hàng đã đăng ký Ngân Hàng Chính Chủ (Tài khoản ngân hàng Nạp và Rút đã được hệ thống xác nhận).'
        },
        {
            text: 'Các quy định chung về khuyến mãi, bảo mật, điều khoản và miễn trách nhiệm của Oxbet sẽ được áp dụng.'
        }
    ],
    // eslint-disable-next-line max-len
    ruleNote: 'Oxbet sẽ tiến hành xem xét quá trình đặt cược của Người chơi và trong trường hợp phát hiện có bất kỳ một hành vi nào lạm dụng tiền thưởng khuyến mãi, chúng tôi có quyền hủy bỏ tất cả số tiền thắng cược và giải thưởng trúng được đã nhận từ các vé cược - Vi phạm như đặt cược hai bên, cược chéo, cược gấp thấp trong cùng một trận/thị trường, đặt cược nhiều cho cùng một kết quả trong cùng một trận/thị trường. Và các cược hủy, cược kiểu HK tỷ lệ dưới 0.65(tương đương cho các kiểu cược khác như Malay odd dưới 0.65; Indo odd dưới -1.53; và Dec odd dưới 1.65) sẽ không được tính vào tổng tiền đặt cược.',
    termList: [
        {
            icon: '/assets/img/event/noel/dialog/rules/1.svg',
            title: 'THÔNG TIN EVENT',
            content: [
                {
                    text: 'Người chơi cược A-thể thao (áp dụng cho Sport và các Games Saba) từ <span class="rules__item--yellow-text">2.000.000 VNĐ</span> trở lên được nhận được 1 lượt quay vòng quay.'
                },
                {
                    text: 'Lượt chơi sẽ được dùng để tham gia chương trình (Vé cược hợp lệ sau 24h sẽ được tự động cập nhât lượt chơi vào lúc 18:00 hàng ngày)'
                },
                {
                    text: 'Với mỗi lượt quay, người chơi tham gia quay\n'
                        + '            Vòng Quay may mắn trên trang chủ Oxbet có cơ hội nhận được các\n'
                        + '            giải thưởng tiền mặt (cộng trực tiếp vào tài khoản) và\n'
                        + '            giải đặc biệt lên đến <span class="rules__item--yellow-text">50.000.000 VNĐ</span>\n'
                        + '            khi quay được giải đặc biệt.'
                }
            ]
        },
        {
            icon: '/assets/img/event/noel/dialog/rules/2.svg',
            title: 'QUY ĐỊNH',
            content: [
                {
                    text: 'Các phiếu cược vi phạm/ vé bị cancel (Huỷ) sẽ bị hủy giải thưởng dù người chơi đã quay và trúng thưởng.'
                },
                {
                    text: 'Giải đặc biệt sẽ được trả thưởng trong vòng 24h.'
                },
                {
                    text: 'Các giải tiền mặt không giới hạn thời gian.'
                }
            ]
        },
        {
            icon: '/assets/img/event/noel/dialog/rules/3.svg',
            title: 'GIẢI THƯỞNG',
            content: [
                {
                    text: 'Giải thưởng vòng quay\n'
                        + '<p>+ <strong>Tiền mặt ( VNĐ )</strong>: 10.000 VNĐ, 20.000 VNĐ, 50.000 VNĐ và 5.000.000 VNĐ.</p>\n'
                        + '<p>+ <strong>Lucky</strong>: Chúc bạn may mắn lần sau.</p>\n'
                        + '<p>+ <strong>Giải đặc biệt</strong>: <span class="rules__item--yellow-text">50.000.000 VNĐ</span></p>\n'
                        + '<p>***Khi quay trúng giải này bạn sẽ chọn 1 hộp trong số 12 hộp để mở quà. Hộp trúng thưởng có giá trị <span class="rules__item--yellow-text">50.000.000 VNĐ</span></p>'
                }
            ]
        }
    ]
};

export const eventWheelNoelInfo = {
    prizes: {
        1: [
            4,
            6,
            8
        ],
        2: [
            7,
            11
        ],
        3: [
            10
        ],
        4: [
            1
        ],
        5: [
            3
        ],
        6: [
            2,
            5,
            9,
            12
        ]
    },
    rewardMapping: {
        1: {
            name: '10.000'
        },
        2: {
            name: '20.000'
        },
        3: {
            name: '50.000'
        },
        5: {
            name: '5.000.000'
        }
    },
    spinMap: {
        1: {
            name: '10.000',
            number: 10
        },
        2: {
            name: '20.000',
            number: 20
        },
        3: {
            name: '50.000',
            number: 50
        },
        4: {
            name: 'Giải đặc biệt',
            number: 0
        },
        5: {
            name: '5.000.000',
            number: 5000
        },
        6: {
            name: 'lucky',
            number: 0
        }
    },
    amountSpecial: '50.000.000',
    textNoteHisory: 'Khi quay trúng giải này bạn sẽ chọn 1 hộp trong số 12 hộp để mở quà.<br/> Hộp trúng thưởng có giá trị <span>50.000.000 VNĐ</span>.',
    textSpecial: 'Mở trúng hộp quà đặc biệt, bạn sẽ nhận được <br> giải thưởng trị giá <span>50.000.000 VNĐ (*)</span>',
    bannerRule: '/assets/img/event/noel/dialog/banner-rule.png',
    bannerRuleHoliday: '/assets/img/event/holiday/dialog/banner-rule.png',
    bannerRuleMB: '/assets/img/event/noel/dialog/banner-rule-mb.png',
    bannerRuleMBHoliday: '/assets/img/event/holiday/dialog/banner-rule-mb.png',
    listRuler: [
        {
            text: 'Giải thưởng tiền mặt sẽ được cộng trực tiếp vào tài khoản tối đa 24h sau khi quay trúng.'
        },
        {
            text: 'Giải thưởng chỉ được duyệt rút về các tài khoản ngân hàng đã đăng ký Ngân Hàng Chính Chủ (Tài khoản ngân hàng Nạp và Rút đã được hệ thống xác nhận).'
        },
        {
            text: 'Các quy định chung về khuyến mãi, bảo mật, điều khoản và miễn trách nhiệm của Oxbet sẽ được áp dụng.'
        }
    ],
    // eslint-disable-next-line max-len
    ruleNote: 'Oxbet sẽ tiến hành xem xét quá trình đặt cược của Người chơi và trong trường hợp phát hiện có bất kỳ một hành vi nào lạm dụng tiền thưởng khuyến mãi, chúng tôi có quyền hủy bỏ tất cả số tiền thắng cược và giải thưởng trúng được đã nhận từ các vé cược - Vi phạm như đặt cược hai bên , cược chéo , cược gấp thấp trong cùng một trận/thị trường, đặt cược nhiều cho cùng một kết quả trong cùng một trận/thị trường.  Và các cược hòa, cược hủy , cược kiểu HK tỷ lệ dưới 0.65 (tương đương cho các kiểu cược khác như Malay odd dưới 0.65; Indo odd dưới -1.53; và Dec odd dưới 1.65) sẽ không được tính vào tổng tiền đặt cược.',
    termList: [
        {
            icon: '/assets/img/event/noel/dialog/rules/1.svg',
            title: 'THÔNG TIN EVENT',
            content: [
                {
                    text: 'Người chơi cược thể thao (Riêng A- thể thao: chỉ áp dụng các vé cược bóng đá) từ <span class="rules__item--yellow-text">1.000.000 VNĐ</span> trở lên được nhận được 1 lượt quay vòng quay.'
                },
                {
                    text: 'Với mỗi lượt quay, người chơi tham gia quay\n'
                        + '            Vòng Quay Năm Mới trên trang chủ Oxbet có cơ hội nhận được các\n'
                        + '            giải thưởng tiền mặt (cộng trực tiếp vào tài khoản) và\n'
                        + '            giải đặc biệt lên đến <span class="rules__item--yellow-text">50.000.000 VNĐ</span>\n'
                        + '            khi quay được giải đặc biệt.'
                }
            ]
        },
        {
            icon: '/assets/img/event/noel/dialog/rules/2.svg',
            title: 'QUY ĐỊNH',
            content: [
                {
                    text: 'Các phiếu cược vi phạm/ vé bị cancel (Huỷ) sẽ bị hủy giải thưởng dù người chơi đã quay và trúng thưởng.'
                },
                {
                    text: 'Giải đặc biệt sẽ được trả thưởng trong vòng 24h.'
                },
                {
                    text: 'Các giải tiền mặt không giới hạn thời gian.'
                }
            ]
        },
        {
            icon: '/assets/img/event/noel/dialog/rules/3.svg',
            title: 'GIẢI THƯỞNG',
            content: [
                {
                    text: 'Giải thưởng vòng quay\n'
                        + '<p>+ <strong>Tiền mặt ( VNĐ )</strong>: 10.000 VNĐ, 20.000 VNĐ, 50.000 VNĐ và 5.000.000 VNĐ.</p>\n'
                        + '<p>+ <strong>Lucky</strong>: Chúc bạn may mắn lần sau.</p>\n'
                        + '<p>+ <strong>Giải đặc biệt</strong>: <span class="rules__item--yellow-text">50.000.000 VNĐ</span></p>\n'
                        + '<p>***Khi quay trúng giải này bạn sẽ chọn 1 hộp trong số 12 hộp để mở quà. Hộp trúng thưởng có giá trị <span class="rules__item--yellow-text">50.000.000 VNĐ</span></p>'
                }
            ]
        }
    ]
};
