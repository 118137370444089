const apiVersion = '/api/v1';

export default ($axios) => ({
    getBySlug(slug) {
        return $axios.$get(`${apiVersion}/post/`, {
            params: {
                alias: slug
            }
        });
    },
    getById(id) {
        return $axios.$get(`${apiVersion}/post/${id}`);
    },
    getCatBySlug(slug, payload) {
        return $axios.$get(`${apiVersion}/category`, {
            params: {
                alias: slug,
                ...payload
            }
        });
    },
    getCategories() {
        return $axios.$get(`${apiVersion}/categories`);
    },
    getFeaturePosts() {
        return $axios.$get(`${apiVersion}/posts/feature`);
    },
    getByCatIds(catIds, payload) {
        return $axios.$get(`${apiVersion}/posts/`, {
            params: {
                catIds,
                ...payload
            }
        });
    }
});
