<template>
  <b-modal
      id="rulesConditionModal"
      dialog-class="modal-dialog-event"
      :content-class="['modal-wheel modal-wheel--rules-condition modal--full-max-height', {'holiday-custom fireworks': $isEnableThemeHoliday()}]"
      hide-footer
      hide-header
      ok-only
      no-stacking
  >
    <div class="modal-wrapper">
      <div class="modal-close" @click="$bvModal.hide('rulesConditionModal')">
        <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/mobile/icon-close.svg`" alt="Close"/>
      </div>
      <b-tabs>
        <b-tab  :title="$device.isMobile ? 'THỂ LỆ' : 'THỂ LỆ CHƯƠNG TRÌNH'" active class="tab-rules">
          <rules @click="moveTo('/the-thao-chau-a')"/>
        </b-tab>
        <b-tab :title="$device.isMobile ? 'NHẬN THƯỞNG' : 'ĐIỀU KIỆN NHẬN THƯỞNG'">
          <condition-info @click="moveTo('/the-thao-chau-a')" />
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>
<script>
import Rules from '@/components/event/wheel/modal/rules-condition/rules.vue';
import ConditionInfo from '@/components/event/wheel/modal/rules-condition/condition-info.vue';

export default {
    components: {
        Rules,
        ConditionInfo
    },
    methods: {
        moveTo(link) {
            this.$bvModal.hide('rulesConditionModal');
            this.$openLink(link, {
                loginRequired: false,
                newTab: false
            });
        }
    }
};
</script>
