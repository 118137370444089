const apiVersion = '/api/v1';

export default ($axios) => ({
    index() {
        return $axios.$get(`${apiVersion}/payment/indexwithdraw`);
    },
    create(type, payload) {
        if (type === 'bank') {
            return $axios.$post(`${apiVersion}/payment/withdrawbank`, payload, { progress: false });
        }
        if (type === 'card') {
            return $axios.$post(`${apiVersion}/payment/withdrawcard`, payload, { progress: false });
        }
    },
    count() {
        return $axios.$get(`${apiVersion}/payments/withdraw/count`, {
            progress: false
        });
    }
});
