export default {
    methods: {
        $_globalMixin_moveTo(item) {
            if (item.type === this.$LINK_TYPE.MATCH) {
                if (this.$store.state.device === 'mobile') {
                    this.$openLink(`/the-thao-chau-a?l=${item.live}&leagueId=${item.leagueId}&matchId=${item.matchId}`, {
                        loginRequired: false,
                        newTab: true
                    });
                } else {
                    this.$openLink(`/the-thao-chau-a/tran-dau?id=${item.matchId}`, {
                        loginRequired: false,
                        newTab: false
                    });
                }
            }
            if (item.type === this.$LINK_TYPE.PAGE) {
                this.$openLink(item.link, {
                    loginRequired: item.loginRequired || false,
                    newTab: item.newTab || false
                });
                return;
            }
            if (item.type === this.$LINK_TYPE.GAME) {
                this.$playGame(item);
                return;
            }
            if (item.type === this.$LINK_TYPE.FUNC) {
                if (item.hasParams) {
                    item.func(item.link, item.options);
                } else {
                    item.func();
                }
            }
        },
        $_globalMixin_PlayGame(item) {
            if (item.data) {
                this.$playGame(item.data);
            } else if (this.$isMobile()) {
                if (item.link === '/tro-choi-so') {
                    this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=/numbergame`, {
                        newTab: item.newTab
                    });
                } else {
                    this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=${item.link}`, {
                        newTab: item.newTab
                    });
                }
            } else {
                this.$router.push(item.link);
            }
        }
    }
};
