import Vue from 'vue';

import {
  BVModalPlugin,
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BModal,
  BPopover,
  BTab,
  BTabs,
  BCard,
  BCardText,
  BCollapse,
  BNav,
  BNavItem,
  BDropdown,
  BDropdownItem,
  BNavItemDropdown,
  BCarousel,
  BCarouselSlide,
  BCardFooter,
  BForm,
  BBreadcrumb,
  BListGroup,
  BListGroupItem,
  BCardHeader,
  BCardBody,
  BTable,
  BSpinner,
  BProgress,
  VBModal,
  VBPopover,
  VBTooltip,
  VBToggle,
  VBScrollspy
} from 'bootstrap-vue';

Vue.use(BVModalPlugin);

Vue.component('BContainer', BContainer);
Vue.component('BRow', BRow);
Vue.component('BCol', BCol);
Vue.component('BFormInput', BFormInput);
Vue.component('BButton', BButton);
Vue.component('BModal', BModal);
Vue.component('BPopover', BPopover);
Vue.component('BTab', BTab);
Vue.component('BTabs', BTabs);
Vue.component('BCard', BCard);
Vue.component('BCardText', BCardText);
Vue.component('BCollapse', BCollapse);
Vue.component('BNav', BNav);
Vue.component('BNavItem', BNavItem);
Vue.component('BDropdown', BDropdown);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('BNavItemDropdown', BNavItemDropdown);
Vue.component('BCarousel', BCarousel);
Vue.component('BCarouselSlide', BCarouselSlide);
Vue.component('BCardFooter', BCardFooter);
Vue.component('BForm', BForm);
Vue.component('BBreadcrumb', BBreadcrumb);
Vue.component('BListGroup', BListGroup);
Vue.component('BListGroupItem', BListGroupItem);
Vue.component('BCardHeader', BCardHeader);
Vue.component('BCardBody', BCardBody);
Vue.component('BTable', BTable);
Vue.component('BSpinner', BSpinner);
Vue.component('BProgress', BProgress);

Vue.directive('BModal', VBModal);
Vue.directive('BPopover', VBPopover);
Vue.directive('BTooltip', VBTooltip);
Vue.directive('BToggle', VBToggle);
Vue.directive('BScrollspy', VBScrollspy);
