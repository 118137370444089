// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import VueTouch from 'vue-touch';

Vue.use(VueLazyload, {
    error: '/assets/img/default.png',
    loading: '/assets/img/loading.gif',
    attempt: 1
});

Vue.use(VueTouch, { name: 'v-touch' });
